import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import { Fetch } from "../../../utilities/Fetch";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { UsuarioService } from "../../servicos/UsuarioService";
import EditarUsuario from "./EditarUsuario";

const title = "Usuários";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Usuario extends React.Component {

	constructor() {
		super();
		this.state = {
			list: [],
			data: {},
			params: {
				desativados: false,
				nivel: undefined
			},
		};
		this.usuarioService = new UsuarioService();
	}

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");
		} else {
			window.location.replace("/");		
		}
	}
	

	handleList = async () => {
		this.setState({selectedUsuario: null});
		await this.usuarioService.listarSimples(this.state.params).then((data) => this.setState({list: data}));
	}

	handleListAlt = () => {
		if (this.state.list != null && this.state.list.length > 0) {
			this.handleList();
		}
	}

	selectUsuario = (event) => {
		this.setState({selectedUsuario: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.usuarioService.completar(event.data).then((usuario) => {
					usuario.senha = null;
					usuario.csenha = null;
					ModalRealm.showDialog(<EditarUsuario key={TZMUserData.getIdGenerator()} usuario={usuario} onClose={this.handleListAlt} onDelete={this.handleListAlt} onSave={this.handleListAlt} />);
				});
			} else {
				ModalRealm.showDialog(<EditarUsuario key={TZMUserData.getIdGenerator()} usuario={event.data} onClose={this.handleListAlt} onDelete={this.handleListAlt} onSave={this.handleListAlt} />);
			}
		}
	}
  
	handleUpdate = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});
	}

	newUsuario = () => {
		this.setState({selectedUsuario: null});
		this.selectUsuario({data: {perfis: [], nivel: TZMUserData.REPRESENTANTE}});
	}

	toggleHidden = () => {
		this.setState({params: {...this.state.params, desativados: !this.state.params.desativados}});
	}

	columns = [
		<TZMColumn sortable field="login" key="login" style={{width: "20em"}} header="Login" />,
		<TZMColumn sortable field="nome" key="nome" style={{width: "*"}} header="Nome" />,
		<TZMColumn sortable field="email" key="email" style={{width: "20em"}} header="E-mail" />,		
		<TZMColumn sortable field="nivel" key="nivel" style={{width: "12em"}} header="Nível" />,
		<TZMColumn key="acoes" header="Ações" style={{width: "5em", textAlign: "center"}} body={(usuario) => {
			return (
				<div key={usuario.login}>
					{TZMUserData.hasRole("____USER_E") ? <span key={0} title="Editar Usuário" className="fa fa-edit ui-tzm-icon" onClick={() => this.selectUsuario({data: usuario})} /> : null}
					{TZMUserData.hasRole("____USER_D") ? <span key={1} title="Excluir Usuário" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirUsuario(usuario)} /> : null}
				</div>
			);
		}} />
	];

	excluirUsuario = (usuario) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.usuarioService.excluir(usuario).then(this.handleList);
		}} question="Tem certeza de que deseja excluir este usuário?" />);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-8">
										<TZMTextField name="usuario" autoFocus onChange={this.handleUpdate} label="Usuário" placeholder="Login, Nome ou E-mail" value={this.state.params.usuario} />
									</div>
									<div className="ui-g-4">
										<TZMCombobox name="nivel" onChange={this.handleUpdate} value={this.state.params.nivel} label="Nível">
											<option value={null}>Selecione</option>
											<option value="ADMIN">Administrador</option>
											<option value="DIRETOR">Diretor</option>
											<option value="GERENTE">Gerente</option>
											<option value="SUPERVISOR">Supervisor</option>
											<option value="REPRESENTANTE">Representante</option>
										</TZMCombobox>
									</div>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton className="ui-button-secondary" title={this.state.params.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.toggleHidden}  icon={this.state.params.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____USER_C") ? <TZMButton className="ui-button-success ui-tzm-button" icon="fas fa-plus" label="Novo Usuário" onClick={this.newUsuario} /> : null}
								<TZMButton className="ui-button-primary ui-tzm-button"  icon="fas fa-search" label="Procurar" onClick={this.handleList} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.list} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>				
				<TZMShortcut active={TZMShortcut.modalCount === 0}
					onEnter={this.handleList}
					onCtrlH={this.toggleHidden}
					onCtrlN={this.newUsuario}
				/>			
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
