import React from "react";
import TZMProgressbar from "../components/menubar/TZMProgressbar";
import { ModalRealm } from "../components/modal/ModalRealm";
import { Fetch } from "./Fetch";
import TZMRecover from "./TZMRecover";
import { Util } from "./Util";

export async function httpPost(url, data) {
	TZMProgressbar.setActive(true);
	return await Fetch.PostJSONAsync(url, data).then(async (response) => {
		TZMProgressbar.setActive(false);
		return await response;
	}).catch(handleError);
}

async function handleError() {
	TZMProgressbar.setActive(false);
	ModalRealm.showDialog(<TZMRecover />);	
}

export async function httpDelete(url, id) {
	TZMProgressbar.setActive(true);
	return await Fetch.DeleteAsync(`${url}/${id}`).then(async (response) => {
		TZMProgressbar.setActive(false);
		return await response;
	}).catch(handleError);
}
export async function httpDeleteAll(url, body) {
	TZMProgressbar.setActive(true);
	return await Fetch.DeleteAsyncAll(`${url}`, body).then(async (response) => {
		TZMProgressbar.setActive(false);
		return await response;
	}).catch(handleError);
}

export function httpGet(url, params = {}, loading = true) {
	let append = "";
	if (params != null) {
		let values = [];
		Object.keys(params).forEach((key) => {
			if (params[key] != null) {
				if (Array.isArray(params[key])) {
					let temp = params[key].filter(element => element);
					if (temp.length > 0) {
						values.push(`${key}=${encodeURIComponent(params[key].join(","))}`);
					}
				} else if (typeof params[key] === "object") {
					values.push(`${key}=${encodeURIComponent(JSON.stringify(params[key]))}`);
				} else if (typeof params[key] === "number") {
					values.push(`${key}=${encodeURIComponent(params[key])}`);
				} else if (typeof params[key] === "boolean") {
					values.push(`${key}=${encodeURIComponent(params[key])}`);
				} else {
					if (params[key].length > 0) {
						values.push(`${key}=${encodeURIComponent(Util.trim(params[key]))}`);
					}
				}
			}
		});
		if (values.length > 0) {
			append = "?" + values.join("&");
		}
	}
	TZMProgressbar.setActive(loading);
	return Fetch.GetJSON(url + append).then((response) => {
		TZMProgressbar.setActive(false);
		return response;
	}).catch((error) => handleError(error));
}

export async function httpGetAsync(url, params = {}, loading = true) {
	let append = "";
	if (params != null) {
		let values = [];
		Object.keys(params).forEach((key) => {
			if (params[key] != null) {
				if (Array.isArray(params[key])) {
					let temp = params[key].filter(element => element);
					if (temp.length > 0) {
						values.push(`${key}=${encodeURIComponent(params[key].join(","))}`);
					}				} else if (typeof params[key] === "object") {
					values.push(`${key}=${encodeURIComponent(JSON.stringify(params[key]))}`);
				} else if (typeof params[key] === "boolean") {
					values.push(`${key}=${encodeURIComponent(params[key])}`);
				} else if (typeof params[key] === "number") {
					values.push(`${key}=${encodeURIComponent(params[key])}`);
				} else {
					if (params[key].length > 0) {
						values.push(`${key}=${encodeURIComponent(Util.trim(params[key]))}`);
					}
				}
			}
		});
		if (values.length > 0) {
			append = "?" + values.join("&");
		}
	}
	TZMProgressbar.setActive(loading);
	return await  Fetch.GetJSONAsync(url + append).then((response) => {
		TZMProgressbar.setActive(false);
		return response;
	}).catch((error) => handleError(error));
	
}
