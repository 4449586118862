import React, {Component} from "react";
import {Fetch} from "../../utilities/Fetch";

export class LazyImage extends Component {

    state = {
        src: null,
        error: false,
        loaded: false
    };

    componentDidMount() {
        Fetch.Get(`/arquivos/${this.props.src}`).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.setState({loaded: true, src: reader.result});
                    };
                    reader.readAsDataURL(blob);
                }).catch(error => {
                    console.error(error);
                    this.setState({error: true});
                });
            }
        }).catch(error => {
            console.error(error);
            this.setState({error: true});
        });
    }

    handleClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.state.src);
        }
    }

    render() {
        if (this.state.error) {
            return <div className="loading-loading" style={this.props.style}><i className="fa fa-times"/></div>;
        } else if (!this.state.loaded) {
            return <div className="loading-loading" style={this.props.style}><i className="fa fa-spinner fa-spin"/></div>;
        }
        return <img {...this.props} src={this.state.src} alt={this.props.alt || ""} onClick={this.handleClick}/>;
    }

}
