import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { Column } from "primereact/components/column/Column";
import TZMCalendar from "../../../components/common/TZMCalendar";
import { DescontoService } from "../../servicos/DescontoService";
import { toCurrency } from "../../../components/common/TZMFormatter";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import { ClienteUtils } from "../cliente/ClienteUtils";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import { JustificativaDesconto } from "../../rre/pedido/JustificativaDesconto";
import { PedidoService } from "../../servicos/PedidoService";
import EditarPedido from "../../rre/pedido/EditarPedido";
import TZMPanel from "../../../components/panel/TZMPanel";
import "./Desconto.css";
import TZMTable from "../../../components/table/TZMTable";
import { DescontoUtils } from "./DescontoUtils";
import { Validator } from "../../../utilities/TZMValidator";
import moment from "moment"

export default class EditarDesconto extends React.Component {

	constructor(props) {
		moment.locale('pt-BR');	
		super(props);
		this.state = {			
            desconto: this.props.desconto,                        
			visible: true
		};		
		this.descontoService = new DescontoService();
		this.pedidoService = new PedidoService();
	}
	onHide = () => this.setState({visible: false});
 
	salvar = () => {
		this.descontoService.salvar(this.state.desconto).then((desconto) => {
			if (this.props.onModalClose) this.props.onModalClose(desconto);
			this.onHide();
		});
	}
Q
	showResponsaveis = (rowData, props) => {		
		return (<div>{rowData.responsaveis.map(responsavel => <div key={responsavel.nome}> {responsavel.nome} </div>)} </div>);
	}

	showUsuario = (rowData, props) => {
		if (rowData.usuario) {
			return (<div>{rowData.usuario.nome}</div>);
		}
		return null;
	}

	showStatus = (rowData, props) => {
		let data = '';
		if (rowData.executadoEm) {
			data = moment(rowData.executadoEm).format("DD/MM/YYYY HH:mm:ss");
		}
		return (<div><div>{rowData.status}</div><div>{data}</div></div>);
	}		
	

	modificarDesconto = (descontoItem, acao) => {		
		const usuario = TZMUserData.getUsuario();
		switch (acao) {
			case "R":
				ModalRealm.showDialog(<JustificativaDesconto  acao={"REPROVADO"} itens={this.state.desconto.itemDTO.itens} key={TZMUserData.getIdGenerator()} onModalClose={(justificativa) => {
					this.reprovarDesconto({...descontoItem, justificativa} , usuario)}} />);		
				break;
			case "E":
				ModalRealm.showDialog(<JustificativaDesconto  itens={this.state.desconto.itemDTO.itens} key={TZMUserData.getIdGenerator()} onModalClose={(justificativa) => {
					this.descontoService.desenvolver({...descontoItem, justificativa}, usuario, "APROVADO").then( () =>						
						this.descontoService.completar(descontoItem).then((desconto) => {this.setState({desconto})}))
					 }} />);
				break;
			default:
				ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} question="Tem certeza de que deseja aprovar está solicitação de desconto?" onYes={() => this.aprovarDesconto(descontoItem, usuario)} />);
				break;
		}
	}

	reprovarDesconto = (desconto, usuario) => {
		this.descontoService.desenvolver(desconto, usuario, "REPROVADO").then(() =>  this.descontoService.completar(desconto).then((desc) => {this.setState({desconto:desc})}))
	}

	aprovarDesconto = (desconto, usuario) => {
		this.descontoService.desenvolver(desconto, usuario, "APROVADO").then(() => this.descontoService.completar(desconto).then((desc) => {this.setState({desconto:desc})}))
	}

	visualizarPedido = (pedidoID) => {
		this.pedidoService.buscar(pedidoID).then((pedido) => {
			ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={pedido} />);
		});
	}

	colorir = (rowData) => {		
		switch (rowData.status) {
			case DescontoUtils.statusSolicitado():				
				return {'solicitado': true};				
			case DescontoUtils.statusEncaminhado():				
				return {'encaminhado': true}				
			case DescontoUtils.statusReprovado():				
				return {'reprovado': true}
			case DescontoUtils.statusAprovado():
				return {'aprovado': true}
			case DescontoUtils.statusEnfileirado():				
				return {'enfileirado': true}				
			default:				
				return false;
		}
	}

    render() {	
		let aprovar = false;
		let encaminhar = false;
		let reprovar = false;
		let item = this.state.desconto.itens.filter(i => i.status === "SOLICITADO");
		if (!Validator.isEmpty(item)) {			
			if (item[0].limite < this.state.desconto.percentual) {
				encaminhar = true;
			} else {
				aprovar = true;
			}
			reprovar = true;
		}

		return (
			<TZMDialog style={{width: "1400px"}} visible={this.state.visible} modal header="Desconto" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-1">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.pedidoId} label="Pedido" />
						</div>
						<div className="ui-g-1">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.itemId} label="Item" />
						</div>
						<div className="ui-g-2">
							<TZMTextField  disabled value={this.state.desconto.status} label="Status" />
						</div>
						<div className="ui-g-2">
							<TZMCalendar  disabled={true} name="inicio" showTime={true} label="Solicitado Em" value={this.state.desconto.registro} />
						</div>										
						<div className="ui-g-6 ui-g-nopad">	
							<div className="ui-g-1 cliente-tipo">
								{ClienteUtils.clienteTipoPedido(this.state.desconto.itemDTO.tipo)}
							</div>
							<div className="ui-g-11">
								<TZMTextField label="Razão Social" disabled name="razaoSocial" value={this.state.desconto.itemDTO.razaoSocial}  />
							</div>											
						</div>
						<div className="ui-g-2">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.produtoSku} label="SKU" />
						</div>
						<div className="ui-g-4">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.produtoDescricao} label="Produto" />
						</div>
						<div className="ui-g-3">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.materiaPrima} label="Matéria Prima" />
						</div>
						<div className="ui-g-2">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.familia} label="Família" />
						</div>
						<div className="ui-g-1">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.unidade} label="Unidade" />
						</div>
						<div className="ui-g-2">
							<TZMTextField  disabled value={toCurrency(this.state.desconto.itemDTO.valorUnitario)} label="Valor Unitário" />
						</div>
						<div className="ui-g-2">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.quantidade} label="Quantidade" />
						</div>
						<div className="ui-g-3">
							<TZMTextField  disabled value={toCurrency(this.state.desconto.itemDTO.valorTotalItem)} label="Valor Total item" />
						</div>
						<div className="ui-g-2">
							<TZMCurrency precision={1} prefix="" disabled suffix="%"  name="percentual"  label="Percentual" value={this.state.desconto.percentual} />
						</div>
						<div className="ui-g-2">
							<TZMTextField  disabled value={toCurrency(this.state.desconto.valorDesconto)} label="Valor Desconto" />
						</div>
					</div>
				</TZMPanel>
				<div/>
				<TZMTable value={this.state.desconto.itens} rowClassName={this.colorir} >
					{this.state.desconto.status === "EM_ANDAMENTO" ?
					<Column key="alcadaAtual" name="alcadaAtual" style={{textAlign:"center" , width: "2em"}} 
					body={(_item) => {
						let botoes = [];
						if (_item.status === 'SOLICITADO') {										
							botoes.push(<span key="reprovar" title="Alçada Atual" style={{ fontSize: "20px"}} className="fas fa-hand-point-right ui-tzm-icon"/>);
						}
						return <div style={{ textAlign: "center" }}>{botoes}</div>;
					}}/> : <Column key="alcadaAtual" style={{width: "0em"}}/>}
					<Column key="status" name="status" style={{textAlign:"center" , width: "11em"}} field="status" header="Status" body={this.showStatus} />
					<Column key="nivel" name="nivel" style={{textAlign:"center" , width: "6em"}} field="nivel" header="Nível" />
					<Column key="limite" name="limite" style={{textAlign:"center" , width: "6em"}} field="limite" header="Limite" body={((rowData) => <div style={{textAlign: "center"}}>{Number(rowData["limite"])}%</div>)}/>									
					<Column key="justificativa"  name="justificativa" style={{textAlign:"center" , width: "14em"}} field="justificativa" header="Justificativa" />
					<Column key="responsavel" body={this.showResponsaveis} style={{ width: "12em", textAlign:"center" }} header="Alçada" />
					<Column key="usuario"  style={{textAlign:"center" , width: "15em"}} header="Executor" body={this.showUsuario} />
				</TZMTable>
				<TZMPanelFooter>
				<TZMButton className="ui-button-info" title="Visualizar Pedido" icon="fa fa-desktop" style={{ float: "left" }} label="Visualizar" onClick={() => this.visualizarPedido(this.state.desconto.itemDTO.pedidoId)}/>					
				{encaminhar ? <TZMButton style={{ float: "center" }}  className="ui-button-warning" label="Encaminhar" icon="fa fa-arrow-right" onClick={() => this.modificarDesconto(this.state.desconto.itemDTO, "E")} /> : null}
				{aprovar ? <TZMButton style={{ float: "center" }} className="ui-button-success" label="Aprovar" icon="fa fa-check" onClick={() => this.modificarDesconto(this.state.desconto.itemDTO, "A")}/> : null} 
				{reprovar ? <TZMButton style={{ float: "center" }} className="ui-button-danger" label="Reprovar" icon="fa fa-exclamation" onClick={() => this.modificarDesconto(this.state.desconto.itemDTO, "R")}/> : null}				
				<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-times" onClick={this.onHide} />									
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}
}

