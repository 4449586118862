import React from "react";

const CONFIRMADO = "CONFIRMADO";
const ERRO = "ERRO";
const RASCUNHO = "RASCUNHO";
const DESCONTO_FINALIZADO = "DESCONTO_FINALIZADO";
const DESCONTO_REPROVADO = "DESCONTO_REPROVADO";
const ALCADA_DESCONTO = "ALCADA_DESCONTO";
const FATOR_DIVISAO = "D";
const FATOR_MULTIPLICAO = "M";
const FATOR_NULL = "N";


export class PedidoUtils { 

	static statusConfirmado() {return CONFIRMADO;}

	static statusErro() {return ERRO;}

	static statusRascunho() {return RASCUNHO;}

	static statusDescontoFinalizado() {return DESCONTO_FINALIZADO;}

	static statusDescontoReprovado() {return DESCONTO_REPROVADO;}

	static isCancelar(status) {
		switch (status) {
		case ERRO:
		case RASCUNHO:
		case DESCONTO_REPROVADO:
		case ALCADA_DESCONTO:				
			return true;
		default:
			return false;
		}
	}  

	static isDividir(fator) { return fator === FATOR_DIVISAO;}

	static isMultiplicar(fator) { return fator === FATOR_MULTIPLICAO; }
	
	static isUnidadeAlternativa(clienteProduto) {
		return clienteProduto && clienteProduto.tipoFator !== FATOR_NULL;
	}

	static columnStatus(rowData) {
		let value, color, title;
		if (rowData.descontos && rowData.descontos.some(d => d.itens.some(di => di.status === "APROVADO"))) {
			value = "thumbs-up";
			color = "#0558e7";
			title = "Desconto Aprovado";
		} else {
			switch (rowData.status) {
			case "BLOQUEADO":
				value = "lock";
				color = "#F44336";
				title = "Esboço";
				break;
			case "CANCELADO":
				value = "ban";
				color = "#F44336";
				title = "Cancelado";
				break;
			case "CARTEIRA":
				value = "wallet";
				color = "#607D8B";
				title = "Em Carteira";
				break;
			case "FATURADO":
				value = "check";
				color = "#4CAF50";
				title = "Faturado";
				break;
			case "PARCIALMENTE_FATURADO":
				value = "wallet";
				color = "#29772b";
				title = "Faturado Parcialmente";
				break;				
			case "ERRO":
				value = "times";
				color = "#F44336";
				title = "Erro";
				break;
			case "RASCUNHO":
				value = "shopping-cart";
				color = "#FF9800";
				title = "Rascunho";
				break;
			case "ALCADA_DESCONTO":
				value = "tag";
				color = "#4CAF50";
				title = "Desconto Pendente";
				break;
			case "DESCONTO_REPROVADO":
				value = "exclamation";
				color = "#FF9800";
				title = "Desconto Reprovado";
				break;
			case "PROCESSADO":
				value = "thumbs-up";
				color = "#0558e7";
				title = "Processado";
				break;
			default:
				break;
			}
		}
		return <span key="columnStatus" className={`fas fa-${value}`} title={title} style={{color}} />;
	}
}