import moment from "moment";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {TZMCurrency} from "../../../components/common/TZMCurrency";
import {toCurrency, toPercent} from "../../../components/common/TZMFormatter";
import TZMIcon from "../../../components/common/TZMIcon";
import TZMTextArea from "../../../components/common/TZMTextArea";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";
import {asEmail} from "../../../utilities/TZMUtil";
import {Validator} from "../../../utilities/TZMValidator";
import {VwVendedorService} from "../../servicos/VwVendedorService";
import {EditarItemPedido} from "./EditarItemPedido";
import {TZMNumber} from "../../../components/common/TZMNumber";
import {ClienteUtils} from "../../comum/cliente/ClienteUtils";
import {Column} from "primereact/components/column/Column";
import {PedidoUtils} from "./PedidoUtils";
import {ComboboxOperacao} from "../../../components/special/ComboboxOperacao";
import Templates from "../../../utilities/Templates";
 
export default class VisualizarPedidoHistorico extends React.Component {

	constructor(props) {
		super();
		moment.locale('pt-BR');						
		this.state = {
			pedido: this.completarNulos(props.pedido),
			visible: true,
			maisInfoCliente: false,
			firstItem: 0
		};		
		this.vwVendedorService = new VwVendedorService();				
	}

	 componentDidMount() {	
		if (this.state.pedido.vendedor.pk.vendedorId) {			
			this.selectVendedores({ value: this.state.pedido.vendedor.pk });
		} 	
	}

	completarNulos(pedido) {		
		pedido = this.preencherEnderecos(pedido);
		return pedido;
	}

	preencherEnderecos(pedido) {
		if (pedido.cliente.enderecos) {
			pedido.cliente.enderecos.forEach((endereco) => {
				if (endereco.pk.tipo === "COBRANCA") {
					pedido.cliente.enderecoCobranca = endereco;
				}
				if (endereco.pk.tipo === "ENTREGA") {
					pedido.cliente.enderecoEntrega = endereco;
				}
			});
		} else {
			pedido.cliente.enderecos = [];
		}
		return pedido;
	}

	percentFormat = (rowData, column) => {
		return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field])}</div>;
	}

	fecharPainel = () => {
		this.setState({ visible: false });
		if (this.props.onClose) {
			this.props.onClose(this.state.pedido);
		}
	}

	selectVendedores = (event) => {
		let pedido = this.state.pedido;
		let pk;
		if (event.value.pk){
			pk = Object.assign({},  event.value.pk);
		} else {
			pk = Object.assign({},  event.value);
		}
		this.vwVendedorService.buscar(pk).then((vendedor) => { 
			pedido.vendedor = vendedor;
			this.setState({ pedido });
		});
	}

	
	mountEnumOptions = (enumList) => {
		if (enumList != null) {
			let enums = [<option key={"vazioEnum"} value={null} />];
			enums.push(...enumList.map((value) => <option key={value.value} value={value.value}>{value.descriptor}</option>));
			return enums;
		}
		return null;
	}

	editorProduto = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<div>{rowData.produto.pk.produtoId}</div>
				<TZMAutoComplete disabled inputClassName="editorProduto" field="descricao" itemTemplate={this.produtoTemplate} value={this.state.pedido.itens[props.rowIndex].produto}/>
			</div>
		);
	}	

	editorQuantidade = (rowData, props) => {
		return (
			<div className='tzm-ui-tablelike'>
				<TZMNumber index={props.rowIndex} name="quantidade" disabled={true} min={0} value={this.state.pedido.itens[props.rowIndex].quantidade.toString()}/>
			</div>
		);
	}

	enderecoLiteral(endereco) {
		return "[" + endereco.pk.tipo + "] " + endereco.logradouro + " " + endereco.bairro + ". CEP " + endereco.cep + " - " + endereco.cidade + "/" + endereco.estado;
	}


	checkNullArray(value) {
		return value ? value : [];
	}


	pagamentosFrete = [
		<option key={50} value={null}></option>,
		<option key={2} value="FOB">F - Frete a Pagar</option>,
		<option key={1} value="CIF">C - Frete Incluso</option>,
		<option key={3} value="T">T - A pagar pelo Cliente à Transportadora</option>,
		<option key={4} value="F">F - A pagar pelo Cliente incide ICMS</option>,
		<option key={5} value="I">I - Pago pela Empresa incide ICMS</option>,
		<option key={6} value="N">N - Pago pela Empresa. Não é Impresso</option>,
	];


	produtoTemplate(produto) {
		return `${produto.produtoId} - ${produto.descricao}`;
	}

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	vendedorTemplate(vendedor) {
		return <div><i className={`fa fa-${vendedor.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {vendedor.pk.vendedorId} - {vendedor.nome}</div>;
	}
	
	transportadoraTemplate(transportadora) {
		return `${transportadora.descricaoAmigavel} (${transportadora.cidade})`;
	}

	condicaoPagamentoTemplate(condicaoPagamento) {
		return `${condicaoPagamento.pk.condicaoPagamentoId} - ${condicaoPagamento.descricao}`;
	}


	paginacaoItens = (event) => {
		this.setState({ firstItem: event.first });
	}

	editarItemPedido = (itemPedido) => {
		let sistema = this.state.pedido.sistema;
		ModalRealm.showDialog(<EditarItemPedido operacoes={this.state.operacoes} key={TZMUserData.getIdGenerator()} itemPedido={itemPedido} sistema={sistema} onSalvar={() => {
			let pedido = this.state.pedido;
			pedido.itens.filter(i => i.itemId === itemPedido.itemId).forEach(item => Object.assign(item, itemPedido));
			this.setState({ pedido });
		}}/>);
	}

	columnStatus = (pedido) => {
		let value;
		switch (pedido.status) {
			case "BLOQUEADO":
				value = "lock";
				break;
			case "CANCELADO":
				value = "ban";
				break;
			case "CARTEIRA":
				value = "wallet";
				break;
			case "FATURADO":
				value = "check";
				break;
			case PedidoUtils.statusErro():
				value = "times";
				break;
			case "RASCUNHO":
				value = "shopping-cart";
				break;
			case "PROCESSADO":
				value = "thumbs-up";
				break;
			default:
				value = "clock";
				break;
		}
		return `fas fa-${value}`
	}

	impostos = (impostos) => {		
		if (impostos && impostos.length > 0) {
			return (
				<div>
					{impostos.filter(e => e.valor > 0).map(e => 
						<div className="campo-impostos">
							<div style={{fontWeight: "bold", textAlign:"left"}} key={Math.random()} > {e.imposto}  </div>
							<div style={{textAlign:"right"}}  key={Math.random()} > {toCurrency(e.valor)} </div>
						</div>
					)}
					<div className="campo-impostos">
						<div  style={{fontWeight: "bold", textAlign:"left"}}> TOTAL  </div>
						<div  style={{fontWeight: "bold", textAlign:"right"}} > {toCurrency(impostos.map((imposto) => imposto.valor).reduce((a, b) => a + b, 0))} </div>
					</div>
				</div>		
			)
		} else {
			return (<div style={{textAlign: "center", fontWeight: "bold"}} > Sem Imposto </div>);
		}
	}

	
	renderColumnsItens = () => {		
		let colums = [];				
		colums.push(<Column key="item" style={{width: "3em", textAlign: "center"}} header="Item" field="itemId" />);		
		colums.push(<Column key="descricao" body={this.editorProduto} style={{width: "*"}} header="Produto" />);
		colums.push(<Column key="quantidade" body={this.editorQuantidade} style={{width: "10em"}} field="quantidade" header="Quantidade" />);
		colums.push(<Column key="unidade" field="unidade" style={{ textAlign: "center", width: "4.5em" }} header="Unidade" />);		
		colums.push(<Column key="valorUnitario" style={{width: "12em"}} name="valorUnitario"header="Valor Unitário" body={ (pi) =>
			<div style={{textAlign : "center"}}>
				{toCurrency(pi.valorUnitario)}
			</div>} />);
		colums.push(<Column key="valores" style={{width: "17em"}} header="Impostos" body={(item) => this.impostos(item.impostos)}/>);
		colums.push(
		<Column key="totalFinalItem" style={{width: "14em"}} header="Valor Final" body={ (pi) =>
			<div style={{textAlign : "center"}}>
				{toCurrency(pi.valorTotalItem)}
			</div>} />		
		);	
		colums.push(							
		<Column style={{width: "6em", textAlign: "center"}} key="acoes" header="Ações" body={(rowData, c) => {
			return (
				<div>											
					<span className="fake-link" onClick={() => this.editarItemPedido(rowData)}>
						<TZMIcon title="CFOP/Operação" className="ui-action-icon ui-action-icon-edit" name="edit" />
					</span>
				</div>
			);
		}} />);
		return colums;
	}


	render() {					
		return (
			<TZMDialog style={{ width: "1480px" }} visible={this.state.visible} modal header={
				<span className="fake-link">Pedido {this.state.pedido.numeroPedido.id}
					<span style={{ position: "absolute", right: "40px", top: "8px" }}>
						<i style={{ marginRight: "8px" }} className={this.columnStatus(this.state.pedido)} />{this.state.pedido.status}
					</span>
				</span>
			} {...this.props}  >
				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<div className="ui-g">							
							<div className="ui-g-2"><TZMTextField  disabled label="Pedido" value={this.state.pedido.numeroPedido.id} /></div>
							<div className="ui-g-2"><TZMCalendar disabled appendTo={document.body}  label="Data do Pedido" value={moment(this.state.pedido.dataPedido).format("YYYY-MM-DD")} /></div>							
							<div className="ui-g-2"><TZMCalendar disabled appendTo={document.body}  label="Data Prevista para Entrega" value={this.state.pedido.dataEntrega} /></div>							
							<div className="ui-g-2"/>
							<div className="ui-g-2"/>
							<div className="ui-g-2"><TZMTextField disabled label="Pedido Cliente" value={this.state.pedido.pedidoCliente} /></div>
							<div className="ui-g-1 ui-g-nopad">
								<div className="ui-g-9 ">
									<TZMTextField disabled forac="true" value={this.state.pedido.cliente.posicao} label="Situação" />
								</div>
								<div className="ui-g-3 cliente-tipo">
									{ClienteUtils.clienteTipoPedido(this.state.pedido.cliente.tipo)}
								</div>
							</div>
							<div className="ui-g-4" style={{ position: "relative" }}>
								{Validator.isEntidade(this.state.pedido.cliente) && this.state.pedido.cliente.pk.clienteId ? <div className="tzm-overlink" onClick={() => this.setState({ maisInfoCliente: !this.state.maisInfoCliente })}>{this.state.maisInfoCliente ? "Menos" : "Mais"} informações</div> : null}
								<TZMAutoComplete  itemTemplate={ClienteUtils.clienteTemplateOmega} 
												label="Cliente"
												name="cliente" field="descricaoAmigavel" value={this.state.pedido.cliente} disabled={true}/>
							</div>
							<div className="ui-g-4"><TZMTextField disabled forac="true" label="Nome Fantasia" value={this.state.pedido.cliente.nomeFantasia} /></div>
							<div className="ui-g-3"><TZMTextField disabled forac="true" label="E-mail" value={this.state.pedido.cliente.email} /></div>
							{this.state.maisInfoCliente ? (
								<div>
									<div className="ui-g-3"><TZMTextField disabled forac="true" label="CPF/CNPJ" value={this.state.pedido.cliente.cpfCnpj} /></div>
									<div className="ui-g-3"><TZMTextField disabled forac="true" label="Inscrição Estadual" value={this.state.pedido.cliente.inscricaoEstadual} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 1" value={this.state.pedido.cliente.telefone1} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 2" value={this.state.pedido.cliente.telefone2} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 3" value={this.state.pedido.cliente.telefone3} /></div>
									<div className="ui-g-12">
										<TZMTable value={this.state.pedido.cliente.contatos} emptyMessage="Nenhum contato encontrado">
											<Column key="nome" style={{ textAlign: "left", width: "15em" }} field="nome" header="Contato" />
											<Column key="telefone1" style={{ textAlign: "center", width: "10em" }} field="telefone1" header="Telefone 1" />
											<Column key="telefone2" style={{ textAlign: "center", width: "10em" }} field="telefone2" header="Telefone 2" />
											<Column key="email" style={{ width: "*" }} field="email" header="E-mail" body={(rowData) => rowData != null ? asEmail(rowData.email) : null} />
											<Column key="observacao" style={{ width: "6em" }} field="observacao" header="Observação" body={(rowData) => {
												let id = rowData.nome + "_" + Math.round(Math.random() * 1000000) + "_id";
												return (
													<div id={id} style={{ textAlign: "center" }}>
														<span className="fa fa-info" />
														<Tooltip for={`#${id}`} title={rowData.observacao} tooltipPosition="top" />
													</div>
												);
											}} />
										</TZMTable>
									</div>
								</div>
							) : null}
							<div className="ui-g-6" style={{position: "relative"}}>
								<TZMCombobox label="Endereço de Cobrança" value={JSON.stringify(this.state.pedido.cliente.enderecoCobranca)} onChange={(event) => this.handleCliente("enderecoCobranca", event.target.value)}>
									{[<option key="vazioaEndCobranca" value={null}>Nenhum endereço selecionado</option>, ...this.checkNullArray(this.state.pedido.cliente.enderecos).map((endereco) => <option key={Math.random()} value={JSON.stringify(endereco)}>{this.enderecoLiteral(endereco)}</option>)]}
								</TZMCombobox>
								{this.state.pedido.cliente.enderecoCobranca ? this.state.pedido.cliente.enderecoCobranca.pk ? <div className="tzm-tag-combobox">{this.state.pedido.cliente.enderecoCobranca.pk.tipo}</div> : null : null}
								
							</div>
							<div className="ui-g-6" style={{ position: "relative" }}>
								<TZMCombobox label="Endereço de Entrega" value={JSON.stringify(this.state.pedido.cliente.enderecoEntrega)} onChange={(event) => this.handleCliente("enderecoEntrega", event.target.value)}>
									{[<option key="vazioEndEntrega" value={null}>Nenhum endereço selecionado</option>, ...this.checkNullArray(this.state.pedido.cliente.enderecos).map((endereco) => <option key={Math.random()} value={JSON.stringify(endereco)}>{this.enderecoLiteral(endereco)}</option>)]}
								</TZMCombobox>
								{this.state.pedido.cliente.enderecoEntrega ? this.state.pedido.cliente.enderecoEntrega.pk ? <div className="tzm-tag-combobox">{this.state.pedido.cliente.enderecoEntrega.pk.tipo}</div> : null : null}
							</div>
							<div className="ui-g-4">
								<TZMAutoComplete  field="descricao" disabled={true} name="formaPagamento"  placeholder="Descrição" label="Forma de Pagamento" value={this.state.pedido.formaPagamento} />
							</div>
							<div className="ui-g-4">
								<TZMAutoComplete disabled={true} itemTemplate={this.condicaoPagamentoTemplate} field="descricaoAmigavel"  
												  name="condicaoPagamento" label="Condição de Pagamento" value={this.state.pedido.condicaoPagamento} />
							</div>
							<div className="ui-g-4">
								<ComboboxOperacao label="Operação" name="operacao" value={this.state.pedido.operacao} />								 
							</div>							
							<div className="ui-g-3"><TZMAutoComplete itemTemplate={this.transportadoraTemplate} disabled
													 name="transportadora" label="Transportadora" value={this.state.pedido.transportadora} field="descricaoAmigavel" /></div>
							<div className="ui-g-3"><TZMTextField disabled forac="true" label="Nome Fantasia" value={this.state.pedido.transportadora ? this.state.pedido.transportadora.nomeFantasia : null} /></div>
							<div className="ui-g-2"><TZMTextField disabled forac="true" label="CPF/CNPJ" value={this.state.pedido.transportadora ? this.state.pedido.transportadora.cpfCnpj : null} /></div>
							<div className="ui-g-2">
								<TZMCombobox  disabled name="frete" label="Pagamento do Frete"  value={this.state.pedido.frete}>{this.pagamentosFrete}</TZMCombobox>
							</div>
							<div className="ui-g-2">
								<TZMCurrency label="Valor do Frete" disabled className="ui-textfield-group ui-textfield" name="valorFrete" prefix="R$" value={this.state.pedido.valorFrete} />
							</div>														
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Itens do Pedido</span>}>
						<div className="ui-g">
							<div className="ui-g-12">
								<TZMTable selection={this.state.selectedItemPedido} onPage={this.paginacaoItens} first={this.state.firstItem} value={this.state.pedido.itens} paginator rows={8}
									emptyMessage="Nenhum item adicionado" footer={<div>Valor total: {toCurrency(this.state.pedido.itens.map(pi => pi.valorTotalItem).reduce((a, b) => a + b, 0))}</div>}>
									{this.renderColumnsItens()}
								</TZMTable>
							</div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Textos</span>}>
						<div className="ui-g">
							<div className="ui-g-6">
								<TZMTextArea name="observacao" field="observacao" onChange={(event) => {
								let pedido = this.state.pedido;								
								pedido.observacao = event.target.value.replace(/(^\s+|\s+$)/g, ' ');
								this.setState({ pedido });
							}} maxLength="254" style={{ height: "110px" }} label="Observação" value={this.state.pedido.observacao} /></div>
							<div className="ui-g-6"><TZMTextArea name="observacaoFiscal" field="observacaoFiscal" onChange={(event) => {
								let pedido = this.state.pedido;								
								pedido.observacaoFiscal = event.target.value.replace(/(^\s+|\s+$)/g, ' ');
								this.setState({ pedido });
							}} maxLength="4000" style={{ height: "110px" }} label="Dados Fiscal" value={this.state.pedido.observacaoFiscal} /></div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="4" />Equipe de Vendas</span>}>
						<div className="ui-g">
							<div className="ui-g-1"><TZMTextField disabled forac="true" value={this.state.pedido.vendedor.posicao} label="Situação" /></div>
							<div className="ui-g-5">
								<span className="ui-fluid ui-g-nopad">
									<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmegaPK} name="vendedor" field="descricaoAmigavel" disabled
									                label="Vendedor" placeholder="Código, E-mail ou Nome"  value={this.state.pedido.vendedor}/>
								</span>
							</div>
						</div>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>															
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fecharPainel} />
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}
}
