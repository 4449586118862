import React, {Component} from "react";
import TZMCombobox from "../common/TZMCombobox";

export class ComboboxOperacao extends Component {

    onChange = (event) => {
        this.props.onChange({...event, target: {...event.target, value: event.target.value === 0 ? null : JSON.parse(event.target.value)}});
    }

    disabled = () => {
        return this.props.value && !this.props.operacoes?.some(o => o.usage === this.props.value?.usage);
    }

    render() {
        if (this.disabled()) {
            return (
                <TZMCombobox disabled {...this.props}>
                    <option value={JSON.stringify(this.props.value)}>{this.props.value?.usage}</option>
                </TZMCombobox>
            );
        } else {
            const operacoes = this.props.operacoes?.map(o => <option key={o.id} value={JSON.stringify(o)}>{o.usage}</option>);
            operacoes.unshift(<option key={0} value={0}>Selecione</option>);
            return <TZMCombobox {...this.props} value={JSON.stringify(this.props.value)} onChange={this.onChange} children={operacoes}/>;
        }
    }

}
