import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMIcon from "../../../components/common/TZMIcon";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";
import { Validator } from "../../../utilities/TZMValidator";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { Column } from "primereact/components/column/Column";
import { NegociacaoService } from "../../servicos/NegociacaoService";
import { ProdutoUtils } from "../produto/ProdutoUtils";
import { ClienteUtils } from "../cliente/ClienteUtils";
import { Button } from "primereact/components/button/Button";
import { EstadoService } from "../../servicos/EstadoService";
import TZMCombobox from "../../../components/common/TZMCombobox";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import { VwClienteService } from "../../servicos/VwClienteService";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMPanel from "../../../components/panel/TZMPanel";
import { TZMNumber } from "../../../components/common/TZMNumber";

export class EditarNegociacao extends React.Component {

	constructor(props) {
        super(props);        
		this.state = {
            negociacao: this.completarNulos(this.props.negociacao),
            params: {
                produto: { pk: {}, descricao: "" }
            },
            alterado: false,
			visible: true
		};
        this.negociacaoService = new NegociacaoService();
		this.vwProdutoService = new VwProdutoService();
		this.clienteService = new VwClienteService();
		this.estadoService = new EstadoService();
	}
	
	componentDidMount(){
		this.listarEstados();
	}

    completarNulos(negociacao) {
		if (!negociacao.itens) {
			negociacao.itens = [];
		}
		return negociacao;
	}

	listarEstados = () => {		
		this.estadoService.listarSimples().then((estados) => this.setState({estados}));
	}

	onHide = () => this.setState({visible: false});
 
	salvar = () => {
		if (this.validar()) {		
			if(this.props.renovando){
				this.negociacaoService.renovar(this.state.negociacao).then((negociacao) => {
					if (this.props.onModalClose) this.props.onModalClose(negociacao);
					this.onHide();
				});

			}else {
				this.negociacaoService.salvar(this.state.negociacao).then((negociacao) => {
					if (this.props.onModalClose) this.props.onModalClose(negociacao);
					this.onHide();
				});
			}	
		}
	}

	encerrar = () => {
		let salvar = Object.assign({}, this.state.negociacao);
		salvar.status = 'ENCERRADO';				
		this.negociacaoService.salvar(salvar).then((negociacao) => {
			if (this.props.onModalClose) this.props.onModalClose(negociacao);
			this.onHide();
		});
	}

	confirmarEncerrar = () => {
		ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={this.encerrar} question={'Deseja encerrar a negociação?'} />);	  
	}
	

	confirmar = () => {
		if (this.validar()) {
			let salvar = Object.assign({}, this.state.negociacao);
			salvar.status = 'ATIVO';
			salvar.publicar = true;			
			this.negociacaoService.salvar(salvar).then((negociacao) => {
				if (this.props.onModalClose) this.props.onModalClose(negociacao);
				this.onHide();
			});
		}
	}

	validar = () => {

		let messages = [];
		let negociacao = this.state.negociacao;
		let datas = true;

		if (Validator.isEmpty(negociacao.inicio)) {
			messages.push("A data de início da negociação é obrigatória.");
			datas = false;
		}
		if (Validator.isEmpty(negociacao.fim)) {
			messages.push("A data de término da negociação é obrigatória.");
			datas = false;
		}
		if (!Validator.isEntidade(this.state.negociacao.cliente)) {
			messages.push("O cliente é obrigatório.");
		}
		
		if (datas) {
			let inicio = new Date(negociacao.inicio);
			let termino = new Date(negociacao.fim);
			if (inicio > termino) {
				messages.push("A data de término deve ser maior que a data de início.");
			}
		}

		if (!negociacao.descricao || !negociacao.descricao.length) {
			messages.push("A descrição é obrigatória");
		}
		if (negociacao.itens == null || negociacao.itens.length === 0) {
			messages.push("Nenhum produto adicionado.");
		} else {
			let comEstado = true;
			negociacao.itens.forEach((item, index) => {
				if (!item.preco) {
					messages.push(`O preço do item ${item.produto.descricao} não pode ser vazio ou igual a 0(zero).`);
				}
				// if (!item.multiplo) {
				// 	messages.push(`O multiplo do item ${item.produto.descricao} não pode ser vazio ou igual a 0(zero).`);
				// }
				if (!Validator.isEntidade(item.estado)) {
					messages.push(`O estado do item ${item.produto.descricao}  não pode ser vazio.`);		
					comEstado = false;			
				}
			});

			if (comEstado) {
				negociacao.itens.forEach((item, index) => {
					if (negociacao.itens.findIndex(i => i.produto.pk.produtoId === item.produto.pk.produtoId && (i.estado.uf === item.estado.uf) ) !== index) {
						messages.push(`O item ${item.produto.descricao} duplicado para o mesmo estado ${item.estado.nome}.`);
					}
				});
			}
		}

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} />);
			return false;
		}

		return true;
	}

	listarClientes = async (event) => {		
		let params = Object.assign({});	
		params.cliente = event.query;
		params.negociacao = true;
		params.posicao = 'ATIVO';
		params.sort = 'razaoSocial';
        await this.clienteService.autoComplete(params).then((clientes) => this.setState({clientes}));
    }

	handleChange = (event) => {		
		let negociacao = this.state.negociacao;
		negociacao[event.name] = event.value;
		this.setState({negociacao,alterado: true });
	}	
	
	handleChangeCalendar = (event) => {		
		let negociacao = this.state.negociacao;
		negociacao[event.name] = event.target.value;
		this.setState({negociacao,alterado: true });
	}	

    blurOnEnter = (event) => {
		if (event.keyCode === 9 || event.keyCode === 13) document.activeElement.blur();
	}
    
    listarProdutos = (event) => {
		this.vwProdutoService.autoCompletar(event.query).then((produtos) => {
			this.setState({ produtos });
		});
	}	

    editorProduto = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
                <TZMAutoComplete inputClassName="editorProduto" field="descricao" itemTemplate={ProdutoUtils.produtoTemplate} onKeyDown={this.blurOnEnter}
                    value={this.state.negociacao.itens[props.rowIndex].produto} onChange={(event) => {
					let negociacao = this.state.negociacao;
					negociacao.itens[props.rowIndex].produto = event.value;
					this.setState({ negociacao });
				}} suggestions={this.state.produtos} completeMethod={this.listarProdutos} onSelect={(event) => {
					if (event.value && event.value.pk) {						
						this.handleUpdateItem("produto", props.rowIndex, event.value);
					}
				}} />
			</div>
		);
	}

	
	bodyProduto = (rowData) => {		
		return (rowData.produto ? <div className="tzm-ui-tablelike">{rowData.produto.descricao}</div> : null);
    } 

    handleUpdateItem = (key, index, value) => {
		let negociacao = this.state.negociacao;
		negociacao.itens[index][key] = value;
		this.setState({ negociacao, alterado: true });
	}

	handleUnidadeChange = (event) => {
		let negociacao = this.state.negociacao;
		negociacao.itens[event.index].unidade = event.value;
		this.setState({negociacao});
	}
	
	handleChangePreco = (name,index, event) => {	
		let negociacao = this.state.negociacao;
		negociacao.itens[index][name] = event.value;
		this.setState({negociacao, alterado: true});		
	}

	mountEnumOptions = (enumList) => {		
		if (enumList != null) {
			let enums = [<option key={"vazioEnum"} value={null} />];
			enums.push(...enumList.map((value) => <option key={value.uf} value={JSON.stringify(value)}>{value.nome}</option>));
			return enums;
		}
		return null;
	}

	editorMultiplo = (rowData, props) => {
		return (
			<div className='tzm-ui-tablelike'>
				<TZMNumber index={props.rowIndex} value={this.state.negociacao.itens[props.rowIndex].multiplo} onChange={(event) => this.handleChangePreco('multiplo',props.rowIndex, event)} />								
					 
			</div>
		);
	}
    
	editorPreco = (rowData, props) => {		
		return (			
			<div style={{ textAlign: "right"}}>
				<TZMCurrency min={0} value={this.state.negociacao.itens[props.rowIndex].preco} onChange={(event) => this.handleChangePreco('preco',props.rowIndex, event)} />								
			</div>
		);
	}
	editorEstado = (rowData, props) =>{		
		return (
			<div style={{ textAlign: "right"}}>
				<TZMCombobox field="nome" name="estado"  onChange={(event) => this.handleUpdateItem("estado", props.rowIndex, JSON.parse(event.target.value))}
							value={JSON.stringify(this.state.negociacao.itens[props.rowIndex].estado)}>{this.mountEnumOptions(this.state.estados)}
				</TZMCombobox>
			</div>
		);
	}
	
    
    insertItem = () => {
		const negociacao = this.state.negociacao;
		if (!negociacao.itens) {
			negociacao.itens = [];
		}
		negociacao.itens.unshift({ produto: { pk: {}, descricao: " ",  }, preco: 0, estado: null, multiplo: 0 });
		let firstItem = negociacao.itens.length % 10 === 1 ? negociacao.itens.length - 1 : this.state.firstItem;
		this.setState({ negociacao, alterado: true, firstItem });
	}
	

	deleteItem = (event, rowData) => {
		event.preventDefault();
		event.stopPropagation();
		if (Validator.isEntidade(rowData.produto)) {
			ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={() => {
				let negociacao = this.state.negociacao;
				negociacao.itens.splice(negociacao.itens.findIndex((item) => item === rowData), 1);
				let firstItem = negociacao.itens.length % 10 === 0 ? negociacao.itens.length >= 10 ? negociacao.itens.length - 10 : 0 : this.state.firstItem;
				this.setState({ negociacao, alterado: true, firstItem });
			}} question="Tem certeza de que deseja remover este item?" />);
		} else {
			let negociacao = this.state.negociacao;
			negociacao.itens.splice(negociacao.itens.findIndex((item) => item === rowData), 1);
			let firstItem = negociacao.itens.length % 10 === 0 ? negociacao.itens.length >= 10 ? negociacao.itens.length - 10 : 0 : this.state.firstItem;
			this.setState({ negociacao, alterado: true, firstItem });
		}
	}
		
	handleClear = (event) => {
		let negociacao = this.state.negociacao;
		negociacao[event.name] = null;
		this.setState({negociacao,alterado: true});
	}

    render() {		
		let disabled = this.state.negociacao.status !== 'PENDENTE' && !this.props.renovando;
		return (
			<TZMDialog style={{width: "1300px"}} visible={this.state.visible} modal header="Negociação" onHide={this.onHide} {...this.props}>
				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<div className="ui-g">
							<div className="ui-g-1">
								<TZMTextField  disabled value={this.state.negociacao.status} label="Status" />
							</div>
							<div className="ui-g-4">
								<TZMTextField  name="descricao" value={this.state.negociacao.descricao} label="Descrição" onChange={this.handleChange}/>
							</div>														
							<div className="ui-g-2">
								<TZMCalendar appendTo={document.body} disabled={disabled} onChange={this.handleChangeCalendar} name="inicio" label="Início" value={this.state.negociacao.inicio} />
							</div>
							<div className="ui-g-2">
								<TZMCalendar appendTo={document.body} disabled={disabled} onChange={this.handleChangeCalendar} name="fim" label="Término" value={this.state.negociacao.fim} />
							</div>			
							{
								!!this.state.negociacao?.negociacaoAnterior?.id &&
								<div className="ui-g-3">
									<TZMTextField appendTo={document.body} disabled name="negociacaoAnterior" label="ID Negociação Inicial" value={this.state.negociacao?.negociacaoAnterior?.id || ""} />
								</div>																	
							}
														
							<div className="ui-g-10">
								<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ClienteUtils.templateTipo} onSelect={this.handleChange} onChange={this.handleChange}
									suggestions={this.state.clientes} completeMethod={this.listarClientes} name="cliente" field="descricaoAmigavel" disabled={disabled}
										label="Cliente"  placeholder="Razão social, fantasia ou CNPJ"  value={this.state.negociacao.cliente} />
							</div>							
						</div>
						{this.state.negociacao.cliente && this.state.negociacao.cliente.pk ?
								<TZMPanel>
									<div className="ui-g">
										<div className="ui-g-2 ui-g-nopad">
											<div className="ui-g-9 ">
												<TZMTextField disabled forac="true" value={this.state.negociacao.cliente.posicao} label="Situação" />
											</div>
											<div className="ui-g-3 cliente-tipo">
												{ClienteUtils.clienteTipoPedido(this.state.negociacao.cliente.tipo)}
											</div>										
										</div>								
										<div className="ui-g-2">
											<TZMTextField label="Cliente" disabled name="clienteId" value={this.state.negociacao.cliente.pk.clienteId}  />
										</div>								
										<div className="ui-g-6">
											<TZMTextField label="Razão Social" disabled name="razaoSocial" value={this.state.negociacao.cliente.razaoSocial}  />
										</div>
										<div className="ui-g-5">
											<TZMTextField label="Nome Fantasia" disabled name="nomeFantasia" value={this.state.negociacao.cliente.nomeFantasia}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="CNPJ" disabled name="cpfCnpjFormatado" value={this.state.negociacao.cliente.cpfCnpjFormatado}  />
										</div>
										<div className="ui-g-3">
											<TZMTextField label="Inscrição Estadual" disabled name="inscricaoEstadual" value={this.state.negociacao.cliente.inscricaoEstadual}  />
										</div>
										<div className="ui-g-4">
											<TZMTextField label="Cidade" disabled name="cidade" value={this.state.negociacao.cliente.cidade}  />									
										</div>
										<div className="ui-g-2">
											<TZMTextField label="Estado" disabled name="estado" value={this.state.negociacao.cliente.estado}  />									
										</div>
									</div>
								</TZMPanel>
							: null
							}						
					</TZMTabPanel>

					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Produtos</span>}>
						<div className="ui-g">
							<div className="ui-g-12">
								<TZMTable selection={this.state.selectedItemPedido} value={this.state.negociacao.itens}
											paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15} emptyMessage="Nenhum item adicionado"
											paginatorLeft={
												<div style={{ textAlign: "left" }}>
													<Button icon="fas fa-plus" title="Adicionar novo item" className="tzm-paginator-button" onClick={this.insertItem} />
												</div> 
											}> 
									<Column  key="sku" name="sku" style={{textAlign:"center" , width: "11em"}} field="produto.pk.produtoId" header="SKU" 
											body={(rowData) => <div>{rowData.produto.pk ? rowData.produto.pk.produtoId : null}</div>}/> 
									<Column key="descricao" body={this.editorProduto} style={{width: "*"}} header="Produto" />														
									<Column key="unidade" field="unidade" style={{textAlign: "center", width: "6em"}} header="Unidade" body={(pi, i) => (
										<TZMCombobox  disabled={ !pi?.produto?.pk?.produtoId} index={i.rowIndex} value={pi.unidade} onChange={this.handleUnidadeChange} 
										children={pi.produto?.unidades?.map(u => <option key={Math.random()} value={u.unidade}>{u.unidadeDescricao}</option>)}/>
									)}/>
									{/* <Column key="quantidade" body={this.editorMultiplo} style={{width: "5em"}} field="multiplo" header="Multiplo" /> */}
									{/* <Column key="unidade" name="unidade" style={{textAlign:"center", width: "6em"}} field="produto.unidade" header="Unidade"/> */}
									<Column key="preco"  style={{ center: "right", width: "15em" }}  header="Preço" name="preco" field="preco" body={this.editorPreco} />
									<Column key="estado"  style={{ center: "right", width: "15em" }}  header="Estado" field="estado.nome" name="estado"  
											body={this.editorEstado} />
									<Column style={{ width: "6em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
										return (
											<div>
												{this.state.negociacao.status !== 'ENCERRADO' ?
												<span className="fake-link" onClick={(event) => this.deleteItem(event, rowData)}>
													<TZMIcon title="Excluir Item" className="ui-action-icon" name="trash-alt" />
												</span> : null}  	
											</div>
										);
									}} />
								</TZMTable>
							</div>
						</div>                                
					</TZMTabPanel>    
				</TZMTabView>
				<TZMPanelFooter>
					{this.state.negociacao.status === 'PENDENTE' ? <TZMButton className="ui-button-primary" style={{ float: "left" }} label="Confirmar" icon="fas fa-check" onClick={this.confirmar} /> : null}
					{this.state.negociacao.status !== 'ENCERRADO' || this.props.renovando ?  <TZMButton className="ui-button-success" label="Salvar" icon="fas fa-save" onClick={this.salvar}/> : null }					{this.state.negociacao.status === 'ATIVO' ? <TZMButton className="ui-button-warning" style={{ float: "left" }} label="Encerrar" icon="fas fa-unlink" onClick={this.confirmarEncerrar} /> : null}
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-times" onClick={this.onHide} />									
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}

}
