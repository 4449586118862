import React from "react";
import moment from 'moment';
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMTable from "../../../components/table/TZMTable";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import { NegociacaoService } from "../../servicos/NegociacaoService";
import { Column } from "primereact/components/column/Column";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import { ClienteService } from "../../servicos/ClienteService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import { ProdutoUtils } from "../produto/ProdutoUtils";
import  {EditarNegociacao}  from "./EditarNegociacao";
import { ClienteUtils } from "../cliente/ClienteUtils";
import TZMCombobox from "../../../components/common/TZMCombobox";

const title = "Negociação";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Negociacao extends React.Component {

	constructor(props) {
		super(props);
		moment.locale('pt-BR');		
		this.state = {	
			negociacoes:[],			
			params: {
				desativados: false,	
				produto: null,
				cliente: null,			
				descricao: '',
				status: null
			}
		};

		this.negociacaoService = new NegociacaoService();
		this.clienteService = new ClienteService();
		this.vwProdutoService = new VwProdutoService();
	}

	criar = () => {
		this.setState({selectedNegociacao: null});
		this.editar({data: {descricao: null, status: 'PENDENTE'}});
	}

	editar = (event) => {
		this.setState({selectedNegociacao: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.negociacaoService.completar(event.data).then((negociacao) => {
					ModalRealm.showDialog(<EditarNegociacao key={TZMUserData.getIdGenerator()} negociacao={negociacao}  onModalClose={this.listar}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarNegociacao key={TZMUserData.getIdGenerator()} negociacao={event.data} onModalClose={this.listar}  />);
			}
		}
	}

	renovar = (event) => {
		this.setState({selectedNegociacao: event.data});
		if (event.data != null) {
			this.negociacaoService.completar(event.data).then((negociacao) => {
				ModalRealm.showDialog(<EditarNegociacao key={TZMUserData.getIdGenerator()} negociacao={{...negociacao, status: "PENDENTE", inicio: moment(negociacao.fim).add(1, "days"), fim: null}} renovando onModalClose={this.listar}/>);
			});
		}else {
			ModalRealm.showDialog(<EditarNegociacao key={TZMUserData.getIdGenerator()} renovando negociacao={event.data} onModalClose={this.listar}  />);
		}
	}



	columns = [				
		<Column header="Descrição" key="descricao" field="descricao" style={{textAlign: "left",width: "*", }} />,		
		<Column header="Término" key="fim" style={{textAlign: "left",width: "8em", }} body={(event) => moment(event.fim).format("DD/MM/YYYY")}/>,
		<Column header="Cliente" key="cliente" field="cliente.pk.clienteId" style={{textAlign: "left",width: "*", }} />,		
		<Column sortable header="Razão Social" key="razaoSocial" field="cliente.razaoSocial" style={{width: "*"}} body={(rowData) => ClienteUtils.clienteTipoNome(rowData.cliente)}/>,
		<Column sortable header="Nome Fantasia" key="nomeFantasia" field="cliente.nomeFantasia" style={{width: "*em"}} />,
		<Column header="CNPJ" key="cpfCnpjFormatado" field="cliente.cpfCnpjFormatado" style={{textAlign: "center",width: "12em"}} />,
		<Column header="Cidade" key="cidade" field="cliente.cidade" style={{textAlign: "center", width: "11em"}} />,
		<Column header="Estado" key="estado" field="cliente.estado" style={{textAlign: "center", width: "5em"}} />,
		<Column header="Status" key="status" field="status" style={{textAlign: "center",width: "8em", }} />,
		
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(negociacao) => { 
			let botoes = [];			
			if (TZMUserData.hasRole("GER_NEGO_E")) {
				botoes.push(<span key={0} title="Editar Negociação" className="fa fa-edit ui-tzm-icon" onClick={() => this.editar({data: negociacao})} />);
				botoes.push(<span key={0} title="Renovar Negociação" className="fa fa-redo ui-tzm-icon" onClick={() => this.renovar({data: negociacao})} />);
				// botoes.push(<span key={1} title="Copiar" style={{ margin: "0 3px 0 2px" }} className="fa fa-copy ui-tzm-icon" onClick={() => this.clonarListaPreco(listaPrecoVersao)} />);
			}
			return <div style={{ textAlign: "center" }}>{botoes}</div>;	
		}} />
	];
   

	listar = async () => {
		let params = Object.assign({}, this.state.params);		
		if (params.cliente) {
			params.cliente = params.cliente.grupoId;
		}
		if (params.produto) {
			params.produto = params.produto.pk.produtoId;
		}
		await this.negociacaoService.listarSimples(params).then((negociacoes) => this.setState({negociacoes}));
	}

	listarProdutos = (event) => {
		this.vwProdutoService.autoCompletar(event.query).then((produtos) => {this.setState({ produtos });});
	}

	listarClientes = async (event) => {
        await this.clienteService.autoComplete({cliente: event.query, desativados: false, sort: 'razaoSocial'}).then((clientes) => this.setState({clientes}));
    }

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
	
	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({ params });
	}

	optionsStatus = [
		<option key={0} value={null}></option>,
		<option key={1} value="PENDENTE">Pendente</option>,
		<option key={2} value="ATIVO">Ativo</option>,
		<option key={3} value="ENCERRADO">Encerrado</option>,		
		<option key={4} value="RENOVADO">Renovado</option>		
	];

	render() {
        return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}> 
							<TZMForm>
								<div className="ui-g">										
									<div className="ui-g-4">
										<TZMTextField  name="descricao" value={this.state.params.descricao} label="Descrição" onChange={(event) => this.handleUpdate("descricao", event)} />		
									</div>
									<div className="ui-g-3">
										<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ClienteUtils.templateTipo} onSelect={this.selectAutoComplete} onChange={this.selectAutoComplete}
											suggestions={this.state.clientes} completeMethod={this.listarClientes} name="cliente" field="descricaoAmigavel" 
												label="Cliente"  placeholder="Razão social, fantasia ou CNPJ"  value={this.state.params.cliente} />
									</div>
									<div className="ui-g-3">
										<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ProdutoUtils.produtoTemplate} onSelect={this.selectAutoComplete} onChange={this.selectAutoComplete}
												suggestions={this.state.produtos}  completeMethod={this.listarProdutos} name="produto" field="descricao" 
												label="Produto" placeholder="Descrição ou SKU"  value={this.state.params.produto} />
									</div>
									<div className="ui-g-2">
										<TZMCombobox value={this.state.params.status} label="Status" onChange={(event) => this.handleUpdate("status", event)}>
											{this.optionsStatus}
										</TZMCombobox>
									</div>
								</div>
							</TZMForm>	
							<TZMPanelFooter >
								<TZMButton className="ui-button-secondary" title={this.state.params.desativados ? "Ocultar Desativados" : "Exibir Desativados"} 
										style={{float: "left"}} onClick={this.toggleHidden}  icon={this.state.params.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("GER_NEGO_C") ? <TZMButton className="ui-button-success ui-tzm-button" icon="fas fa-plus" label="Nova Negociação" onClick={this.criar} /> : null}
								<TZMButton className="ui-button-primary ui-tzm-button"  icon="fas fa-search" label="Procurar" onClick={this.listar} />
							</TZMPanelFooter>
							
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.negociacoes} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
}

