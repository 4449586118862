import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";

export class VwOperacaoService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/operacoes?operacao=${query}`);
	}

	listar(params) {
		return httpGet("/operacoes", params);
	}

	async listarCombobox() {
		return  httpGetAsync("/operacoes/combobox");
	}

}
