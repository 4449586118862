import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import Templates from "../../../utilities/Templates";
import TZMUserData from "../../../utilities/TZMUserData";
import { UsuarioService } from "../../servicos/UsuarioService";
import { VendedorService } from "../../servicos/VendedorService";
import { EditarVendedor } from "./EditarVendedor";

const title = "Vendedores";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Vendedor extends React.Component {

	state = {
		params: {
				desativados: false,
				vendedor: ""
			}
    };

	vendedorService = new VendedorService();
	usuarioService = new UsuarioService();		

	listarVendedores = (event) => {		
		let params = this.state.params;
		params.sort = "nome";
		this.vendedorService.listarSimples(params).then((vendedores) => this.setState({vendedores}));
	}
    
    alternarOcultos = () => {
		this.setState({desativados: !this.state.desativados});
	}

	editarVendedor = (event) => {
		this.setState({selectedVendedor: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.vendedorService.completar(event.data).then((vendedor) => {
					ModalRealm.showDialog(<EditarVendedor key={TZMUserData.getIdGenerator()} vendedor={vendedor}  onModalClose={this.listarVendedores}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarVendedor key={TZMUserData.getIdGenerator()} vendedor={event.data} onModalClose={this.listarVendedores}  />);
			}
		}
	}

	handleRefresh = () => {
		this.vendedorService.atualizar();
	}

	criarVendedor = () => {
		this.setState({selectedVendedor: null});
		this.editarVendedor({data: {nome: ""}});
	}

	excluirVendedor = (vendedor) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => { this.vendedorService.excluir(vendedor).then(this.listarVendedores); }} question="Tem certeza de que deseja excluir este vendedor?" />);
	}

	handleUpdate = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});
	}

	acoesVendedor = (vendedor) =>{		
		return (
			<div>
				{TZMUserData.hasRole("____VEND_E") ? <span title="Editar Vendedor" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarVendedor({data: vendedor})} /> : null}
				{TZMUserData.hasRole("____VEND_D") ? <span title="Remover Vendedor" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirVendedor(vendedor)} /> : null}
			</div>
		);
	}

    columns = [
		<TZMColumn header="Código" key="codigoOmega" body={Templates.codigoOmega}  style={{width: "10em", textAlign: "center"}} />,
		<TZMColumn header="Nome" key="nome" field="nome" style={{width: "*"}} />,
		<TZMColumn header="E-mail" key="email"  style={{width: "30em"}} body={Templates.formataEmail} />,
		<TZMColumn header="Celular" key="celular" field="celular" style={{width: "20em"}} />,
		<TZMColumn header="Telefone" key="telefone" field="telefone" style={{width: "20em"}} />,
		<TZMColumn header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={this.acoesVendedor} />
	];

    render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-12">									
										<TZMTextField name="vendedor" autoFocus onChange={this.handleUpdate} label="Vendedor" 
													placeholder="Código, E-mail, Nome" value={this.state.params.vendedor} />
									</div>									
								</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton title={this.state.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.alternarOcultos} secondary icon={this.state.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.isAdministrador() ? 
								<TZMButton icon="fas fa-sync" label="Atualizar" className="ui-button-secondary"  onClick={this.handleRefresh}  />
								: null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarVendedores} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.vendedores} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
			
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
}
