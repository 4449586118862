
import React from "react";
import moment from "moment"
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import { Column } from "primereact/components/column/Column";
import { DescontoService } from "../../servicos/DescontoService";
import { toCurrency } from "../../../components/common/TZMFormatter";
import TZMUserData from "../../../utilities/TZMUserData";
import { SimuladorPopup } from "../../rre/dashboard/SimuladorPopup";
import EditarDesconto  from "./EditarDesconto";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMTextField from "../../../components/common/TZMTextField";
import Templates from "../../../utilities/Templates";
import { UF } from "../../../utilities/UF";
import UsuarioTemplate from "../usuario/UsuarioTemplate";
import { Fetch } from "../../../utilities/Fetch";
import { UsuarioService } from "../../servicos/UsuarioService";
import { VwClienteService } from "../../servicos/VwClienteService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import { VendedorService } from "../../servicos/VendedorService";
import { RegionalService } from "../../servicos/RegionalService";
import { FamiliaService } from "../../servicos/FamiliaService";
import { ClienteUtils } from "../cliente/ClienteUtils";
import EditarPedido from "../../rre/pedido/EditarPedido";
import { PedidoService } from "../../servicos/PedidoService";
import { Panel } from "primereact/components/panel/Panel";
import { DescontoUtils } from "./DescontoUtils";
import {VwMateriaPrimaService} from "../../servicos/VwMateriaPrimaService";

const title = "Descontos";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Desconto extends React.Component {

	constructor() {
		super();
		moment.locale('pt-BR');		
		this.state = {	
			descontos:[],			
			params: this.defaultParams,			
			fixedSeller: false,
			fixedGerente: false,
			fixedSupervisor: false,			
			filtrarGrupo: false,
			regional: {regional: null,vendedor: null, gerente: null,supervisor: null,familia: null
			}
		};
		this.descontoService = new DescontoService();
		this.usuarioService = new UsuarioService();
		this.vwClienteService = new VwClienteService();
		this.vwProdutoService = new VwProdutoService();
		this.vendedorService = new VendedorService();		
		this.regionalService = new RegionalService();		
		this.familiaService = new FamiliaService();
		this.pedidoService = new PedidoService();
		this.vwMateriaPrimaService = new VwMateriaPrimaService();
	}
	
	defaultParams = {
		pedido: "",
		dataIni: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
		dataFim: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
		produto: { pk: {}, descricao: "" },
		cliente: { pk: {}, descricaoAmigavel: "" },
		uf: "",
		material: null
	};

	componentDidMount() {
		let usuario = TZMUserData.getPrincipal();
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");			
			let regional = this.state.regional;
			switch (usuario.nivel) {
				case TZMUserData.REPRESENTANTE:
					if (usuario.vendedor) {
						regional.vendedor = usuario.vendedor;
						this.setState({regional, fixedSeller: true});
					}
					break;
				case TZMUserData.GERENTE:										
					regional.gerente = usuario;										
					this.setState({regional, fixedGerente: true});
				break;
				case TZMUserData.SUPERVISOR:
					regional.supervisor = usuario;
					this.setState({regional, fixedSupervisor: true});
				break;			
			default:
				break;
			}	
		}
		this.vwMateriaPrimaService.listar().then((materiais) =>{this.setState({  materiais });})
	}

	listar = () => {		
		let params = Object.assign({}, this.state.params);
		let regional = this.state.regional;
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);		
		params.filtrarGrupo = this.state.filtrarGrupo ? "true" : "false";		
		if (params.produto) {
			if (params.produto.pk.produtoId) {
				params.produto = params.produto.pk;
			} else {
				params.produto = null;
			}
		}
		if (params.cliente) {
			if (params.cliente.pk.clienteId) {
				params.cliente = params.cliente.pk;
			} else {
				params.cliente = null;
			}
		}
		if (regional.familia) {
			params.familia = regional.familia.id;
		}
		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}
		if (regional.regional) {
			params.regional = regional.regional.id; 
		}
		if (regional.gerente) {
			params.gerente = regional.gerente.id;
		}
		if (regional.supervisor) {
			params.supervisor = regional.supervisor.id;			
		}
		this.descontoService.listarSimples(params).then((descontos) => this.setState({descontos}));
	}

	listProdutos = (event) => {this.vwProdutoService.autoCompletar(event.query).then((list) => {this.setState({ listProdutos: list });});}

	listarGerentes = (event) => {
		this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.GERENTE,sort:'nome'}).then((gerentes) => this.setState({gerentes}));
	}

	listarSupervisores = (event) => {		
		let supervisores = [];
		let usuario = TZMUserData.getPrincipal();		
		switch (usuario.nivel) {
			case TZMUserData.GERENTE:
				supervisores = usuario.regionais.filter((regional) => regional.supervisor && regional.supervisor.nome.toLowerCase().includes(event.query.toLowerCase()));
				supervisores = supervisores.map(r => r.supervisor);
				supervisores = supervisores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);				
				this.setState({supervisores})	
			break;			
		default:			
			this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.SUPERVISOR, sort:'nome'}).then((supervisores) => this.setState({supervisores}));
			break;
		}
	}

	listarVendedores = async (event) => {
		let usuario = TZMUserData.getPrincipal();
		let vendedores = [];
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
					this.setState({vendedores});				
				break;
			case TZMUserData.GERENTE:
				usuario.regionais.forEach((regional) => {
					vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
					vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
					vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
				});
				vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
				this.setState({vendedores})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					usuario.regionais.forEach((regional) => {
						vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
						vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
						vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
					});
					vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
					this.setState({vendedores})				
				} else {
					this.setState({vendedores});
				}	
			break;			
		default:
			await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((vendedores) => this.setState({vendedores}));
			break;
		}
	}
	
	listarRegionais = async (event) => {
		let regionais = [];
		let usuario = TZMUserData.getPrincipal();	
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {}
				break;
			case TZMUserData.GERENTE:	
				regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));				
				this.setState({regionais})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));
					this.setState({regionais})				
				} else {
					this.setState({regionais});
				}	
			break;			
		default:
			await this.regionalService.autoCompletar({descricao: event.query, sort: "descricao"}).then((regionais) =>{				
				this.setState({regionais})
			});
			break;
		}
	}

    listClientes = async (event) => {await this.vwClienteService.autoComplete({cliente: event.query, sort: 'razaoSocial'}).then((listClientes) => this.setState({listClientes}));}

	produtoTemplate = (value) => {
		return (<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>);
	}

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }

	queryAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name][event.field] = event.value;
		this.setState({ params });
	}

	clearAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = { pk: {}, [event.field]: "" };
		this.setState({ params });
	}

	handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {
		let regional = this.state.regional;
		regional[event.name] = event.value;
		this.setState({regional});
	}

	handleUnselect = (event) => {
		let regional = this.state.regional;
		if (!regional[event.name]) {
			regional[event.name] = [];
		}
		regional[event.name] = regional[event.name].filter((e) => e.id !== event.value.id);
		this.setState({regional});
	}

	handleCombobox = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({ params });
	}
	
	checkNullArray(value) {
		return value ? value : [];
	}

	toggleGrupo = () => {
		this.setState({filtrarGrupo: !this.state.filtrarGrupo});
    }

	renderSuggestion = () => {	
		let usuario = TZMUserData.getPrincipal();								
		switch (usuario.nivel) {			
			case TZMUserData.REPRESENTANTE:
				break;
			case TZMUserData.GERENTE:
				return (<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>
						<div className="ui-g-4 ui-fluid">							
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
								disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
							
						</div>
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>
					</div>
				);				
			case TZMUserData.SUPERVISOR:				
				return (
					<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-6 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>

						<div className="ui-g-6 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>	
					</div>
				);				
		default:			
			return (
				<div className="ui-g-12 ui-g-nopad">
					<div className="ui-g-4 ui-fluid">
						<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
							label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
					</div>
					<div className="ui-g-4 ui-fluid">							
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
							disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
					</div>
					<div className="ui-g-4 ui-fluid">
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
							disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
					</div>
				</div>
			);			
		}		
	}

	listarFamilia = async (event) => {		
		await this.familiaService.autoCompletarFamiliaDummy({descricao: event.query, sort:'descricao'}).then((listFamilias) => this.setState({listFamilias}));
	}

    openSimulacao = (simulacao) => {
		ModalRealm.showDialog(<SimuladorPopup key={TZMUserData.getIdGenerator()} simulador={this.state.simulador} simulacao={simulacao} />);
	}
	
	editar = (event) => {
		this.setState({selectedDesconto: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.descontoService.completar(event.data).then((desconto) => {
					ModalRealm.showDialog(<EditarDesconto key={TZMUserData.getIdGenerator()} desconto={desconto}  onModalClose={this.listar}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarDesconto key={TZMUserData.getIdGenerator()} desconto={event.data} onModalClose={this.listar}  />);
			}
		}
	}
    
    columns = [
		<Column key={0} style={{textAlign: "center", width: "2em" }} header=""  body={(rowData) => DescontoUtils.statusDesconto(rowData.status)} />,		
		<Column key={1} sortable style={{width: "10em", textAlign: "center"}} header="Solicitado em" field="registro" body={(rowData) =>  <div>{moment(rowData.registro).format("DD/MM/YYYY HH:mm:ss")}</div>} />,
		<Column key={2} sortable style={{width: "8em", textAlign: "center"}} header="Pedido" field="pedidoId" />,
		<Column key={3} style={{width: "3em", textAlign: "center"}} header="Item" field="itemId"  />,
		<Column key={4} sortable header="Razão Social"  field="razaoSocial" style={{width: "*"}} body={(rowData) => ClienteUtils.clienteTipoNome(rowData)}/>,
		<Column key={5} style={{width: "18em"}} header="Produto" field="produtoDescricao" />,
		<Column key={6} style={{width: "12em"}} header="Matéria Prima" field="materiaPrima" />,
		<Column key={7} style={{width: "8em", textAlign: "center"}} header="Família" field="familia" />,
		<Column key={8} style={{width: "5em", textAlign: "center"}} header="Unidade" field="unidade" />,
		<Column key={9} style={{width: "12em", textAlign: "right"}} header="Valor Unitário" body={(item) => toCurrency(item.valorUnitario)}/>,
		<Column key={10} style={{width: "7em", textAlign: "center"}} header="Quantidade" field="quantidade" />,
		<Column key={11} style={{width: "12em", textAlign: "right"}} header="Valor Total" body={(item) => toCurrency(item.valorTotalItem)}/>,
		<Column key={12} style={{width: "12em", textAlign: "right"}} header="Desconto" body={(pid) => `${ (pid.percentual)}% (${(pid.valorDesconto).toCurrency()})`} />,
		<Column style={{ width: "5em" }} key="acoes" header="Ações" body={(di) => {
			let botoes = [];
			botoes.push(<span key={0} title="Visualizar Pedido" className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizarPedido(di.pedidoId)} />);
			botoes.push(<span key={1} title="Editar Desconto" className="fa fa-edit ui-tzm-icon" onClick={() => this.editar({data: di})} />);
			if (TZMUserData.hasRole("GER_RRE_SIMU_M") && di.logSimulacao){
				botoes.push(<span key="log" title="Dados da Simulação" className="fa fa-calculator ui-tzm-icon" onClick={() => this.openSimulacao(di.logSimulacao)} />)
			}
			return <div style={{ textAlign: "center" }}>{botoes}</div>;
		}} />
	];

	visualizarPedido = (pedidoID) => {
		this.pedidoService.buscar(pedidoID).then((pedido) => {
			ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={pedido} />);
		});
	}
			
	optionsStatus = [
		<option key={0} value={null}></option>,
		<option key={1} value="EM_ANDAMENTO">Em Andamento</option>,
		<option key={2} value="APROVADO">Aprovado</option>,
		<option key={3} value="REPROVADO">Reprovado</option>
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-11">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-2">
										<TZMCalendar label="Período de Solicitação" placeholder="Inicial" onChange={(event) => this.handleUpdate("dataIni", event)} value={this.state.params.dataIni} />
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdate("dataFim", event)} value={this.state.params.dataFim} />
									</div>
									<div className="ui-g-1">
										<TZMTextField type="number" autoFocus placeholder="Número" label="Pedido" onChange={(event) => this.handleUpdate("pedido", event)} value={this.state.params.pedido} />
									</div>
									<div className="ui-g-6 ui-g-nopad" >
										<div className="ui-g-11">
											<TZMAutoComplete itemTemplate={this.clienteTemplate} onClear={this.clearAutoComplete} name="cliente"
												field="descricaoAmigavel" onSelect={this.selectAutoComplete} suggestions={this.state.listClientes}
												completeMethod={this.listClientes} placeholder="Razão social, fantasia ou CNPJ" onChange={this.queryAutoComplete}
												value={this.state.params.cliente.descricaoAmigavel} label="Cliente" />                                        
										</div>
										<div className="ui-g-1 center-button">
											<TZMButton id="warehouse1" title={this.state.filtrarGrupo ? "Filtrar apenas o cliente selecionado" : "Filtrar todos os clientes do mesmo Grupo Empresarial"}
													style={{ float: "left", color: this.state.filtrarGrupo ? '#4CAF50' : '#607D8B' }} onClick={this.toggleGrupo} secondary="true"
													icon={this.state.filtrarGrupo ?  "fas fa-users " : "fas fa-user-tie"} />								
										</div>    
									</div>
									<div className="ui-g-1">
										<TZMCombobox value={this.state.params.uf} label="UF" onChange={(event) => this.handleUpdate("uf", event)}>{UF.list}</TZMCombobox>
									</div>
									<div className="ui-g-4">
										<span className="ui-fluid ui-g-nopad">
											<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
												suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
												disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
										</span>	
									</div>
									<div className="ui-g-2">
										<TZMTextField disabled forac="true" value={this.state.params.produto.pk.produtoId} label="Produto" />
									</div>
									<div className="ui-g-5">
										<TZMAutoComplete onClear={this.clearAutoComplete} itemTemplate={this.produtoTemplate} onSelect={this.selectAutoComplete} suggestions={this.state.listProdutos} completeMethod={this.listProdutos} name="produto" field="descricao" placeholder="Código ou descrição" onChange={this.queryAutoComplete} value={this.state.params.produto.descricao} label="&nbsp;" />
									</div>
									<div className="ui-g-1">
										<TZMCombobox value={this.state.params.status} label="Status" onChange={(event) => this.handleUpdate("status", event)}>
											{this.optionsStatus}
										</TZMCombobox>
									</div>
									{this.renderSuggestion()}
									<div className="ui-g-4">
										<TZMCombobox name="material" label="Material" onChange={this.handleCombobox} value={this.state.params.material}>
											{[<option key={"semMaterial"} value={null}>{null}</option>, ...this.checkNullArray(this.state.materiais).map((material) =>
												<option key={material.produtoId} value={material.descricao}>{material.descricao}</option>)
											]}
										</TZMCombobox>								
									</div>									
									<div className="ui-g-4 ui-fluid">									
										<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
											suggestions={this.state.listFamilias} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
											label="Família" placeholder="Descrição" onUnselect={this.handleUnselect} value={this.state.regional.familia} />
									</div>
								</div>
								<TZMPanelFooter>
									<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listar} />
								</TZMPanelFooter>
							</TZMForm>
						</TZMPanel>
						</div>
						<div className="ui-g-1">
							<Panel header="Legenda">
								<div className="ui-g">
									<div style={{ color: '#4cae4c', textAlign: "center" }} className="ui-g-2"><i className="fa fa-check"/></div><div className="ui-g-10">Aprovado</div>
									<div style={{ color: '#d43f3a', textAlign: "center" }} className="ui-g-2"><i className="fa fa-exclamation" /></div><div className="ui-g-10">Reprovado</div>
									<div style={{ color: '#2196F3', textAlign: "center" }} className="ui-g-2"><i className="fa fa-clock" /></div><div className="ui-g-10">Em Andamento</div>
								</div>
							</Panel>
						</div>
						<div className="ui-g">
							<div style={{height: "8px"}} />
							<TZMPanel header="Resultado da Pesquisa">
								<TZMTable value={this.state.descontos} paginator rows={50} children={this.columns} />
							</TZMPanel>
						</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}	
}
