import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/components/datatable/DataTable";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { toDecimal } from "../../../components/common/TZMFormatter";
import { VwPedidoService } from "../../servicos/VwPedidoService";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMConfirm from "../../../components/dialog/TZMConfirm";

const title = "Pedidos Atrasados";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class PedidoAtrasado extends React.Component {
	constructor() {
		super(); 
		this.state = {
			list: [],
			rows: 15,
			params: this.defaultParams,
			producao: false,
			filtrarGrupo: false,
			regional: {
				regional: null,
				vendedor: null, 
				gerente: null,
				supervisor: null,
			},
			checarNf: false	,
            selectedPedidos: null,		
		};

        this.vwPedidoService = new VwPedidoService();
	}

    defaultParams = {
        diasDeAtraso:'',
		pedido: "",
		pedidoCliente: "",
		notaFiscal: "",
		//inclusao: this.rangeDiario(),
		entrega: [null, null],
		faturamento: [null, null],
		status: "",
		painel: "true",
		pronto: "false",
		produto: { pk: {}, descricao: "" },
		cliente: { pk: {}, descricaoAmigavel: "" },
		uf: "",
		page: 0,
		size: 10000,
		sort: undefined,
		cancelado: false
	};

    onSort = (event) => {
		if (this.state.checarNf) {
			let list = this.state.list;
			let proprs = event.sortField.split(".");
			let ordenados = list.sort((a, b) => {
				let val1 = a;
				let val2 = b;
				proprs.forEach(p => {
					val1 = val1[p];
					val2 = val2[p];
				});
				if (val1 === Number(val1) && val2 === Number(val2)) {
					return (val1 - val2) * event.sortOrder;
				} else {
					return String(val1).localeCompare(String(val2)) * event.sortOrder;
				}
			});		 		
			this.setState({list: ordenados});
			this.updatePage({first: 0});
		}
	}

    handleList = () => {
		let params = Object.assign({}, this.state.params);
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);
	
		if (params.produto) {
			if (params.produto.pk.produtoId) {
				params.produto = params.produto.pk;
			} else {
				params.produto = null;
			}
		}
		if (params.cliente) {
			if (params.cliente.pk.clienteId) {
				params.cliente = params.cliente.pk;
			} else {
				params.cliente = null;
			}
		}
		

		this.vwPedidoService.listarPedidosAtrasados(params).then( async (vwPedidos) => {
			this.setState({list: vwPedidos});
		});		
	}   

    handleUpdate = (key, event) => {
        this.setState({ params: { ...this.state.params, [key]: event.target.value } });
    }

    checkPositive = (key) => {
        const nun = this.state.params.diasDeAtraso;
        if(nun < 0) this.setState({ params: { ...this.state.params, [key]: 0 } })
    }

    handleSelection = (e) => {
        this.setState({pedidosSelecionados: e.value})
    }

    cancelarPedido = (id) => {
		ModalRealm.showDialog(
            <TZMConfirm 
                question="Tem certeza de que deseja cancelar este pedido? Após cancela-lo não será mais possível desfazer o cancelamento." 
                onYes={() => {
			        this.vwPedidoService.cancelar(id);
			        setTimeout(() => {
				        this.handleList();
						
			        }, 50);
		        }} 

            />
        );
	}

	cancelarPedidoEmLote = () => {
		const pedidos =this.state.selectedPedidos
		const  pedidosIds = pedidos.map((pedido) => pedido.pk.pedidoId)
		ModalRealm.showDialog(
            <TZMConfirm 
                question="Tem certeza de que deseja cancelar todos pedidos selecionados? Após cancela-los não será mais possível desfazer o cancelamento." 
                onYes={() => {
			        this.vwPedidoService.cancelarEmLote(pedidosIds);
			        setTimeout(() => {
				        this.handleList();
			        }, 50);
		        }} 
            />
        );
	}


    columns = [
        <Column selectionMode="multiple" headerStyle={{width: '2.5em'}}></Column>,
		<Column sortable field="numeroPedido" key="numeroPedido" style={{ width: "9em" }} header="Pedido" />,
		<Column  sortable field="pedidoCliente" key="pedidoCliente" style={{ width: "9em" }} header="Pedido Cliente" />,
		<Column  sortable field="cliente.razaoSocial" key="cliente.razaoSocial" style={{ width: "*" }} header="Cliente" />,
		<Column  sortable field="dataPedido" key="dataPedido" style={{ width: "9em" }} body={this.dateFormat} header="Data Pedido" />,
		<Column  sortable field="dataEntrega" key="dataEntrega" style={{ width: "9em" }} body={this.dateFormat} header="Entrega" />,
		<Column  sortable field="totalM2" key="totalM2" style={{ width: "10em", textAlign: "right" }} header="Total em m²" body={(rowData) => toDecimal(Math.round(rowData.totalM2))} />,
		<Column  sortable field="valorTotal" style={{ width: "12em" }} key="valorTotal" header="Valor" body={this.currencyFormat} />,
		<Column  sortable field="diasAtraso" style={{ width: "12em" }} key="diasAtrasado" header="Dias de Atraso" body={this.currencyFormat} />,
		<Column  style={{ width: "7.5em" }} key="acoes" header="Ações" body={(pedido) => {
			let botoes = [];
			botoes.push(<span key={5} title="Cancelar" className="fa fa-ban ui-tzm-icon" onClick={(event) => this.cancelarPedido(pedido.pk.pedidoId)} />);
			return <div style={{ textAlign: "center" }}>{botoes}</div>;
		}} />
	];

	selecionarProduto = (e) => {
		this.setState({ selectedPedidos: e.data })
	}


	render() {
		let notFill = !Array.isArray(this.state.selectedPedidos) ? true : (!this.state.selectedPedidos[0] ? true : false)
		return(
			<TZMMainContent>
                
				<TZMPanel header={title} >
					<TZMForm>
                        <div className="ui-g">
                            <div className="ui-g-2">
                                <TZMTextField 
                                    type="number" 
                                    autoFocus 
                                    placeholder="dias de atraso" 
                                    label="Dias de atraso" 
                                    onChange={(event) => this.handleUpdate("diasDeAtraso", event)} 
                                    value={this.state.params.diasDeAtraso}
                                    onBlur={() => this.checkPositive("diasDeAtraso")}
                                />
                            </div>
                        </div>
					</TZMForm>
                    <TZMPanelFooter>
                        <TZMButton  
                            className='ui-button-danger' 
                            icon="fas fa-times"  onClick={this.cancelarPedidoEmLote} 
                            style={{ float: "left" }} 
                            title='Cancelar pedidos'
							disabled={notFill}
                        />
                        <TZMButton  
                            icon="fas fa-search" 
                            label="Procurar" 
                            onClick={this.handleList} 
                        />
                    </TZMPanelFooter>
				</TZMPanel>
                <div style={{ height: "15px" }} />
                <TZMPanel header='Resultado da Pesquisa' >
                    <DataTable 
						
						selection={this.state.selectedPedidos} 
						onSelectionChange={e => this.selecionarProduto(e)}
                        emptyMessage="Nenhum registro encontrado" 
                        onSort={this.onSort} 
                        value={this.state.list} 
                        paginator 
                        rows={30}  
                        rowsPerPageOptions={[30, 45, 60]}							
                        pageLinkSize={30}  
                    >
                        {this.columns}
                    </DataTable>
                </TZMPanel>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
			</TZMMainContent>
		);
	}

}





