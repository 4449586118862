import React from "react";
import { SimuladorService } from "../../pages/rre/servicos/SimuladorService";
import { TZMNumber } from "./TZMNumber";
import { UF } from "../../utilities/UF";
import TZMCombobox from "./TZMCombobox";
import TZMPanel from "../panel/TZMPanel"; 
import TZMPanelFooter from "../panel/TZMPanelFooter";
import TZMButton from "./TZMButton";
import { ModalRealm } from "../modal/ModalRealm";
import TZMPopup from "../dialog/TZMPopup";
import TZMUserData from "../../utilities/TZMUserData";
import { toDecimal, toCurrency, toDecimalFixed } from "./TZMFormatter";
import { TZMCurrency } from "./TZMCurrency";
import { FamiliaService } from "../../pages/servicos/FamiliaService";
import TZMAutoComplete from "./TZMAutoComplete";
import TZMTextField from "./TZMTextField";

export class TZMSimulador extends React.Component {

	state = {			
		esconderMensagem: this.props.esconderMensagem,				
		simulacao: Object.assign({
			ggf: "0",
			perdas: "0",
			comissao: "0",
			frete: "0",
			largura: 0,
			comprimento: 0,
			altura: 0,
			custoMedio: "0",
			mc: "0",
			icms: "0",
			fatorConversao:"0",
			fatorConversaoMI: "0",
			clicheNovo: false,
			quantidadeCores:"4",
			quantidadeRolos:"0",
			custoPapel: "0",
			custoM2: "0",
			custoRolo: "0",
			pisCofins:"0",
			precoNet:"0",
			precoBruto: "0",
			precoNetMI:"0",			
			precoBrutoMI: "0",
			precoNetM2:"0",			
			precoBrutoM2: "0",
			pagamentoFrete: 'CIF',
			precoBrutoDesconto: '0',
			descontoPercentual: '0',
			descontoValor: "0",
			mcDesconto: "0",
			volume:"0",
			quantidadeM2:"0",
			familia: null		
		}, this.props.simulacao)
	};



	simuladorService = new SimuladorService();
	familiaService = new FamiliaService();

	handleChange = (event) => {
		const {simulacao} = this.state;		
		simulacao[event.name] = event.value;
		this.setState({simulacao});
	}

	premissasAsOptions(premissa) {		
		return (
			<option key={premissa.materiaPrima.id} value={premissa.materiaPrima.id}>
				{premissa.materiaPrima.id} - {premissa.materiaPrima.descricao}
			</option>
		);
	}
 
	materiaPrima = (materiaPrima) => {
		return(<option key={materiaPrima.id} value={materiaPrima.id}>{materiaPrima.descricao}</option>);
	}

	simular = () => {
		if (this.validar()) {
			let s1 = Object.assign({}, this.state.simulacao);			
			let premissa = this.props.simulador.premissasProduto.filter(p => p.materiaPrima.id === s1.premissaProduto)[0];
			let estado = this.props.simulador.estados.filter(e => e.uf.trim() === s1.estado)[0];
			const {espacamentoLateral, espacamentoVertical,embalagem, pisCofins, precoCliche} = this.props.simulador;
			s1.mc = premissa.mc;
			s1.custoMedio = premissa.custoMedio;
			s1.frete = estado.frete;
			s1.icms = estado.percentualIcms;		
			s1 = {...s1, espacamentoLateral, embalagem, pisCofins,espacamentoVertical, precoCliche};
			this.simuladorService.simular(s1).then((s2) => {				
				const simulacao = Object.assign({}, s1, s2);
				this.setState({simulacao});
				if (this.props.onSimular) {
					this.props.onSimular(simulacao);
				}
			});
		}
	}

	validar = () => {

		let simulacao = Object.assign({}, this.state.simulacao);
		let messages = [];
		
		if (simulacao.comprimento === 0) {
			messages.push("Comprimento deve ser maior que zero.");
		}

		if (simulacao.largura === 0) {
			messages.push("Largura deve ser maior que zero.");
		}

		if (simulacao.altura === 0) {
			messages.push("Altura deve ser maior que zero.");
		}

		if (!simulacao.estado) {			
			messages.push("Selecione o estado.");
		}		
		
		if (simulacao.premissaProduto == null) {			
			messages.push("Selecione uma matéria prima.");
		}		
		
		if (simulacao.familia == null) {			
			messages.push("Selecione uma família.");		
		} else {			
			let espacamentoLateral =  this.props.simulador.espacamentoLateral;
			let fatorConversao = (Number(simulacao.comprimento) * (Number(simulacao.largura) + Number(espacamentoLateral)) / 1000) ;			
			let quantidadeM2 = fatorConversao * Number(simulacao.quantidadeRolos);
			if (Number(quantidadeM2) < Number(simulacao.familia.volumeMinimo)) {
				messages.push("Volume mínimo permitido para essa família é de " + simulacao.familia.volumeMinimo + "m². Volume simulado: " + quantidadeM2 + "m².");
			}
		}
		if (Number(simulacao.quantidadeRolos) === 0) {
			messages.push("Quantidade de rolos deve ser maior que zero.");
		}
		
		if (!simulacao.quantidadeCores) {			
			messages.push("Informe a quantidade de cores.");
		} else if (Number(simulacao.quantidadeCores) === 0) {
			messages.push("Quantidade de cores deve ser maior que zero.");
		} else if (Number(simulacao.quantidadeCores) > 8) {
			messages.push("Quantidade de cores deve ser de 1 até 8.");
		}
		if (simulacao.familia !== null && Number(simulacao.familia.quantidadeCores) < Number(simulacao.quantidadeCores)) {			
			messages.push( "A quantidade de cores permitida para essa família é de até " + simulacao.familia.quantidadeCores + " cores, favor selecione outra família.");
		}

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	static pagamentosFrete = [		
		<option key={'P'} value="FOB">FOB - Frete a Pagar</option>,
		<option key={'C'} value="CIF">CIF - Frete Incluso</option>
	];

	static clicheNovo = [		
		<option key='sim' value="true">SIM</option>,
		<option key='nao' value="false">NÃO</option>
	];

	handleClear = (event) => {
		let simulacao = this.state.simulacao;
		simulacao[event.name] = null;
		this.setState({simulacao});
	}

	handleSelect = (event) => {
		let simulacao = this.state.simulacao;
		simulacao[event.name] = event.value;		
		this.setState({simulacao});
	}

	handleUpdate = (key, event) => {
		let simulacao = this.state.simulacao;
		simulacao[key] = event.target.value;
		this.setState({ simulacao });
	}	
	
	handleUnselect = (event) => {
		let simulacao = this.state.simulacao;
		if (!simulacao[event.name]) {
			simulacao[event.name] = [];
		}
		simulacao[event.name] = simulacao[event.name].filter((e) => e.id !== event.value.id);
		this.setState({simulacao});
	}

	listarFamilia = async (event) => {
		await this.familiaService.autoCompletarFamilia({descricao: event.query, sort:'descricao', simulador: true}).then((familias) => this.setState({familias}));
	}
	
	selectFamilia = async (event) => {
		if (event.value != null) {
			const {simulacao}  = this.state;
			this.familiaService.completar(event.value).then(familia => {
				simulacao.familia = familia;
				this.setState({ simulacao });
			})
		}
	}

	aviso = () =>{		
		if (!this.state.esconderMensagem) {
			return (<>
					<div className="ui-g-12 ui-header-simulador">
						<div style={{textAlign: "center"}}>
							<div style={{textAlign: "left", display: "flex", justifyContent: "center", alignItems: "center"}}>
								<i className="fas fa-exclamation ui-alerta-premissa-icone" style={{color: "#F44336"}}/>						
								<ul style={{listStyleType: "none"}}>
									<li className="ui-alerta-premissa">Para utilização do simulador, três premissas devem ser respeitadas:</li>
									<li className="ui-alerta-premissa">1. Lote mínimo POR SKU: 200 m² para etiquetas e 300 m² para rótulos. Lotes menores devem ser orçados na digital.</li>
									<li className="ui-alerta-premissa">2. Pedido mínimo para frete CIF: 3.000 reais. Para pedidos menores é necessário cobrar o adicional de frete.</li>
									<li className="ui-alerta-premissa">3. Faca já existente. Para novas facas é necessário fazer orçamento com o departamento de artes.</li>
								</ul>
							</div>
						</div>
					</div>
				</>
			)
		}
	}

	renderPainel(disabled){
		if (disabled) {
			return (
			<div className="ui-g">
				<div className="ui-g-6">
					<TZMNumber disabled label="Largura Etiqueta (mm)" name="largura" value={this.state.simulacao.largura} />
				</div>				
				<div className="ui-g-6">
					<TZMNumber disabled name="comprimento" label="Comprimento Rolo (m)" value={this.state.simulacao.comprimento} />
				</div>					
				<div className="ui-g-6">
					<TZMCombobox disabled value={this.state.simulacao.estado} name="estado" label="Estado" onChange={this.handleChange}>{UF.list}</TZMCombobox>
				</div>
				<div className="ui-g-6">
					<TZMCombobox disabled name="pagamentoFrete" label="Frete" onChange={this.handleChange} value={this.state.simulacao.pagamentoFrete}>
						{TZMSimulador.pagamentosFrete}
					</TZMCombobox>								
				</div>
				<div className="ui-g-12">
					<TZMCombobox disabled label="Matéria Prima" name="materiaPrima" value={this.state.simulacao.materiaPrima}>								
						{this.materiaPrima(this.state.simulacao.materiaPrima)}
					</TZMCombobox>				
				</div>						
				<div className="ui-g-6 ui-fluid">									
					<TZMAutoComplete  disabled onClear={this.handleClear} onSelect={this.selectFamilia}  onChange={this.handleSelect}
						suggestions={this.state.familias} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
						label="Família" placeholder="Descrição" onUnselect={this.handleUnselect} value={this.state.simulacao.familia} />
				</div>
				<div className="ui-g-6">						
					<TZMTextField  type="number" disabled name="comissao" label="% Comissão" value={this.state.simulacao.comissao} />
				</div>
				<div className="ui-g-6">
					<TZMCurrency label="Preço Bruto Com Desconto por RL" name="precoBrutoDesconto" disabled={disabled} min={0} required value={this.state.simulacao.precoBrutoDesconto} onChange={this.handleChange} />
				</div>

			</div>);
		} else {
			return (
			<>
				<div className="ui-g">
					{this.aviso()}
					<div className="ui-g-4">
						<TZMNumber  onChange={this.handleChange} label="Largura Etiqueta (mm)" name="largura" value={this.state.simulacao.largura} />
					</div>
					<div className="ui-g-4">
						<TZMNumber  onChange={this.handleChange} label="Altura Etiqueta (mm)" name="altura" value={this.state.simulacao.altura} />
					</div>
					<div className="ui-g-4">
						<TZMNumber  onChange={this.handleChange} name="comprimento" label="Comprimento Rolo (m)" value={this.state.simulacao.comprimento} />
					</div>	
					<div className="ui-g-4">
						<TZMCombobox name="clicheNovo" label="Clichê novo?" onChange={this.handleChange} value={this.state.simulacao.clicheNovo}>
							{TZMSimulador.clicheNovo}
						</TZMCombobox>								
					</div>  
					<div className="ui-g-4">
						<TZMNumber  onChange={this.handleChange}  label="Quantidade de Cores (de 1 a 8)" name="quantidadeCores" value={this.state.simulacao.quantidadeCores} />
					</div>
					<div className="ui-g-4">
						<TZMNumber  onChange={this.handleChange} label="Quantidade de Rolos" name="quantidadeRolos" value={this.state.simulacao.quantidadeRolos} />
					</div>
					<div className="ui-g-6">
						<TZMCombobox  value={this.state.simulacao.estado} name="estado" label="Estado" onChange={this.handleChange}>{UF.list}</TZMCombobox>
					</div>
					<div className="ui-g-6">
						<TZMCombobox  name="pagamentoFrete" label="Frete" onChange={this.handleChange} value={this.state.simulacao.pagamentoFrete}>
							{TZMSimulador.pagamentosFrete}
						</TZMCombobox>								
					</div>
					<div className="ui-g-12">
						<TZMCombobox  label="Matéria Prima" name="premissaProduto"  onChange={this.handleChange} value={this.state.simulacao.premissaProduto}>
							{[<option key={0} value={null}>Nenhum registro selecionado...</option>, ...(this.props.simulador.premissasProduto || []).filter(p => p.materiaPrima).map(this.premissasAsOptions)]}
						</TZMCombobox>
					</div>						
					<div className="ui-g-6 ui-fluid">									
						<TZMAutoComplete   onClear={this.handleClear} onSelect={this.selectFamilia} onChange={this.handleSelect}
							suggestions={this.state.familias} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
							label="Família" placeholder="Descrição" onUnselect={this.handleUnselect} value={this.state.simulacao.familia} />
					</div>
					<div className="ui-g-6">						
						<TZMTextField  type="number" name="comissao" onChange={this.handleChange} label="% Comissão" value={this.state.simulacao.comissao} />
					</div>
					<div className="ui-g-6">
						<TZMCurrency label="Preço Bruto Com Desconto por RL" name="precoBrutoDesconto" min={0} required value={this.state.simulacao.precoBrutoDesconto} onChange={this.handleChange} />
					</div>
				</div>

			</>	
			);
		}
	}

	renderFatorConversaoMI = () =>{
		return (<>
				<div className="ui-g-6"> Fator Conversão MI/RL </div>
				<div className="ui-g-6">{toDecimal(this.state.simulacao.fatorConversaoMI)}</div>
			</>
		)
	}

	renderPrecoMI = () =>{
		return (<>
			<div className="ui-g-6"> Preço NET por MI</div>
			<div className="ui-g-6">{toCurrency(this.state.simulacao.precoNetMI)}</div>
			<div className="ui-g-6 destacarPrecoBrutoMI"> Preço Bruto Simulado por MI</div>
			<div className="ui-g-6 destacarPrecoBrutoMI"> {toCurrency(this.state.simulacao.precoBrutoMI)}</div>
			<div className="ui-g-12"/>
			<div className="ui-g-12"/>
			</>
		)
	}

	renderPrecoFinal = (disabled) => {
		if (disabled) {
			return (<>
					{/* <div className="ui-g" style={{borderWidth: '2px', borderStyle: 'solid', borderColor: '#cfd1d4'}}> 
							<div className="ui-g-6 destacarPrecoBrutoRolo">{`Preço NET por ${this.state.simulacao.unidade}`} </div>
							<div className="ui-g-6 destacarPrecoBrutoRolo">{toCurrency(this.state.simulacao.precoNetUnidade)}</div>
							
							<div className="ui-g-6 destacarPrecoBrutoRolo"> {`Preço Bruto Simulado por ${this.state.simulacao.unidade}`} </div>
							<div className="ui-g-6 destacarPrecoBrutoRolo"> {toCurrency(this.state.simulacao.precoBrutoUnidade)}</div>

							<div className="ui-g-6 destacarPrecoBrutoRolo"> {`Preço Bruto Com Desconto (R$/${this.state.simulacao.unidade})`}</div>
							<div className="ui-g-6 destacarPrecoBrutoRolo"> {toCurrency(this.state.simulacao.precoBrutoDesconto)}</div>
					</div> */}
					<div className="ui-g-6"> Preço NET por RL </div>
					<div className="ui-g-6">{toCurrency(this.state.simulacao.precoNet)}</div>
					<div className="ui-g-6 destacarPrecoBrutoRolo"> Preço Bruto Simulado por RL </div>
					<div className="ui-g-6 destacarPrecoBrutoRolo"> {toCurrency(this.state.simulacao.precoBruto)}</div>
					<div className="ui-g-6"> Preço Bruto Com Desconto (R$/RL)</div>
					<div className="ui-g-6"> {toCurrency(this.state.simulacao.precoBrutoDesconto)}</div>			
				</>
			);
		} else {
			return (<>
					<div className="ui-g-6"> Preço NET por RL </div>
					<div className="ui-g-6">{toCurrency(this.state.simulacao.precoNet)}</div>
					<div className="ui-g-6 destacarPrecoBrutoRolo"> Preço Bruto Simulado por RL </div>
					<div className="ui-g-6 destacarPrecoBrutoRolo"> {toCurrency(this.state.simulacao.precoBruto)}</div>
					<div className="ui-g-6"> Preço Bruto Com Desconto (R$/RL)</div>
					<div className="ui-g-6"> {toCurrency(this.state.simulacao.precoBrutoDesconto)}</div>
				</>
			);
		}
	}
					
	render() {		
		const disabled = this.props.disabled || this.state.simulacao.id;
		return (
			<TZMPanel >
				{this.renderPainel(disabled)}
				<div className="ui-g">
					<div className="ui-g-6">Patamar de Volume</div>
					<div className="ui-g-6">{this.state.simulacao.patamarVolume}</div>

					<div className="ui-g-6">Quantidade m² </div>
					<div className="ui-g-6">{toDecimal(this.state.simulacao.quantidadeM2)}</div>

					<div className="ui-g-6"> Fator Conversão m²/RL </div>
					<div className="ui-g-6">{toDecimal(this.state.simulacao.fatorConversao)}</div>
					{disabled ? null : this.renderFatorConversaoMI()}
					<div className="ui-g-6">Custo Papel R$/m² </div>
					<div className="ui-g-6">{toCurrency(this.state.simulacao.custoPapel)}</div>
					<div className="ui-g-6">Perdas</div>
					<div className="ui-g-6"> {toDecimal(this.state.simulacao.perdas)}%</div>
					<div className="ui-g-6">GGF R$/m²</div>
					<div className="ui-g-6"> {toCurrency(this.state.simulacao.ggf)}</div>
					<div className="ui-g-6">Custo M2 R$/m²</div>
					<div className="ui-g-6"> {toCurrency(this.state.simulacao.custoM2)}</div>
					<div className="ui-g-6">Custo Rolo R$/RL</div>
					<div className="ui-g-6"> {toCurrency(this.state.simulacao.custoRolo)}</div>
					<div className="ui-g-6">MC </div>
					<div className="ui-g-6"> {toDecimal(this.state.simulacao.mc * 100)}%</div>
					<div className="ui-g-6">Frete</div>
					<div className="ui-g-6"> {toDecimal(this.state.simulacao.frete * 100 )}%</div>
					<div className="ui-g-6">Comissão </div>
					<div className="ui-g-6"> {toDecimal(this.state.simulacao.comissao)}%</div>
					<div className="ui-g-6"> PIS/COFINS </div>
					<div className="ui-g-6"> {toDecimal(this.state.simulacao.pisCofins * 100)}%</div>
					<div className="ui-g-6"> IPI </div>
					<div className="ui-g-6"> {toDecimal(0)}%</div>
					<div className="ui-g-6"> ICMS </div>
					<div className="ui-g-6"> {toDecimal(this.state.simulacao.icms * 100)}%</div>	
					{this.renderPrecoFinal(disabled)}				
					
							

					<div className="ui-g-12"/>	
					<div className="ui-g-12"/>
					{disabled ? null : this.renderPrecoMI()}
					<div className="ui-g-6"> Preço NET por m²</div>
					<div className="ui-g-6">{toCurrency(this.state.simulacao.precoNetM2)}</div>
					<div className="ui-g-6 destacarPrecoBrutoMI">Preço Bruto Simulado por m²</div>
					<div className="ui-g-6 destacarPrecoBrutoMI"> {toCurrency(this.state.simulacao.precoBrutoM2)}</div>
					<div className="ui-g-12"/>
					<div className="ui-g-12"/>					


					<div className="ui-g-6 destacarMCDesconto">MC com Desconto</div>
					<div className="ui-g-6 destacarMCDesconto"> {toDecimalFixed(this.state.simulacao.mcDesconto * 100,1)}%</div>									


				</div>							
				{!(disabled) ? (
					<TZMPanelFooter>
						<TZMButton className="ui-button-secondary" label="Calcular" icon="fas fa-calculator" onClick={this.simular} />
					</TZMPanelFooter>
				) : null}
			</TZMPanel>
		);

	}

}
