import { Fetch } from "../../utilities/Fetch";
import { httpGet } from "../../utilities/TZMRequest";

export class VwFormaPagamentoService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/formas-pagamento?formapagamento=${query}`);
	}

	listar(params) {
		return httpGet("/formas-pagamento", params);
	}

}
