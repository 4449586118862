import moment from 'moment';
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from '../../../components/common/TZMCalendar';
import { TZMCurrency } from '../../../components/common/TZMCurrency';
import { toCurrency, toDecimal } from '../../../components/common/TZMFormatter';
import TZMTextField from '../../../components/common/TZMTextField';
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";

export class InadimplenciaHistorico extends React.Component {

    constructor(props) {
        super(props);
        
        moment.locale('pt-BR');
        this.state = {
            visible: true,
            complemento : props.complemento,            
            header: props.header
        };
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    mesFormat(rowData, column) {
        if (rowData[column.field]){
            return moment(rowData[column.field]).format("DD/MM/YYYY");
        } else {
            return null;
        }
    }

    currencyFormat(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toCurrency(rowData[column.field])}</div>;
    }

    toDecimal(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(Math.round(rowData[column.field]))}</div>
    }

    toDecimal1(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(rowData[column.field])}</div>
    }

    render() {
        return (
            <TZMDialog style={{ width: "95%" }} visible={this.state.visible} modal header="Dados Histórico Omega" {...this.props}>
                <div className="ui-g">                    
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.clienteId} label="Código Cliente" />
                    </div>
                    <div className="ui-g-4">
                        <TZMTextField disabled  value={this.state.complemento.nomeFantasia} label="Nome Fantasia" />
                    </div>
                    <div className="ui-g-4">
                        <TZMTextField disabled  value={this.state.complemento.razaoSocial} label="Razão Social" />
                    </div>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.cpfCnpjFormatado} label="CPF/CNPJ" />
                    </div>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.email} label="Email" />
                    </div>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.telefone} label="Telefone" />
                    </div>
                    <div className="ui-g-1">
                        <TZMTextField disabled  value={this.state.complemento.estado} label="Estado" />
                    </div>
                    <div className="ui-g-7"/>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.grupoId} label="Código Grupo" />
                    </div>
                    <div className="ui-g-5">
                        <TZMTextField disabled  value={this.state.complemento.grupoNomeFantasia} label="Grupo Nome Fantasia" />
                    </div>
                    <div className="ui-g-5">
                        <TZMTextField disabled  value={this.state.complemento.grupoRazaoSocial} label="Grupo Razão Social" />
                    </div>
                    <div className="ui-g-2">
                        <TZMCalendar  disabled value={this.state.complemento.dataEmissao} label="Data Emissão" />                        
                    </div>                    
                    <div className="ui-g-2">                        
                        <TZMCalendar disabled value={this.state.complemento.dataVencimento} label="Data Vencimento" />
                    </div>                    
                    <div className="ui-g-2">
                        <TZMCurrency  disabled className="ui-textfield-group ui-textfield" prefix="R$"  value={this.state.complemento.valor} label="Valor Original" />                        
                    </div>
                    <div className="ui-g-2">
                        <TZMCurrency  disabled className="ui-textfield-group ui-textfield" prefix="R$"  value={this.state.complemento.valorAtualizado} label="Valor Atualizado" />
                    </div>
                    <div className="ui-g-2">
                        <TZMCurrency  disabled className="ui-textfield-group ui-textfield" prefix="R$"  value={this.state.complemento.valorPago} label="Valor Pago" />
                    </div>
                    <div className="ui-g-2"/>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.pedidoId} label="Pedido " />
                    </div>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.numero} label="Nota Fiscal " />
                    </div>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.desdobramento} label="Desdobramento" />
                    </div>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.sapId} label="Sap ID" title="OJDT.Number  - (JDT1.Line_ID + 1)"/>
                    </div>                    
                    <div className="ui-g-4"/>
                    <div className="ui-g-2">
                        <TZMTextField disabled  value={this.state.complemento.vendedorId} label="Código Vendedor" />
                    </div>
                    <div className="ui-g-4">
                        <TZMTextField disabled  value={this.state.complemento.vendedorNome} label="Vendedor" />
                    </div>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary"  label="Fechar" icon="fas fa-close" onClick={this.fechar} />                    
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar} />
            </TZMDialog>
        );
    }
}