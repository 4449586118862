import React, {Component} from "react";
import {Fetch} from "../../../utilities/Fetch";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMCheckbox from "../../../components/common/TZMCheckbox";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMUserData from "../../../utilities/TZMUserData";
import { Growl } from "primereact/components/growl/Growl";
import { httpPost } from "../../../utilities/TZMRequest";

export class Configuracao extends Component {

	state = {
		configuracao: {
			localPathNotaFiscalHistorico: "",
			localPathNotaFiscalSap: "",
			localPathFichaTecnica: "",
			localPathComprovanteAdiatamento: "",
			flags: []
		}
	};

	componentDidMount() {
		Fetch.GetJSON("/configuracoes/get").then(configuracao => this.setState({configuracao}));
	}

	handleChange = event => this.setState({configuracao: {...this.state.configuracao, [event.name]: event.value}});

	toggleFlag = event => {
		const {configuracao} = this.state;
		if (configuracao.flags.includes(event.name)) {
			configuracao.flags = configuracao.flags.filter(f => f !== event.name);
		} else {
			configuracao.flags.push(event.name);
		}
		this.setState({configuracao});
	}

	salvar = () => {
		httpPost("/configuracoes", this.state.configuracao).then(configuracao => {
			this.setState({configuracao}, () => {
				this.growl.show({ closable: true, severity: 'success', sticky: true, summary: 'Alteração de registro', detail: 'Configuração salva com sucesso!' });
			});
		});
	}

	validar = () => {

		let messages = [];
	
		let configuracao = this.state.configuracao;
		
		if (!configuracao.localPathNotaFiscalHistorico) {
			messages.push("O path de nota fiscal é obrigatório.");
		}
		if (!configuracao.localPathNotaFiscalSap) {
			messages.push("O path de nota fiscal é obrigatório.");
		}
		if (!configuracao.localPathFichaTecnica) {
			messages.push("O path da ficha técnica é obrigatório.");
		}
		if (!configuracao.localPathComprovanteAdiatamento) {
			messages.push("O path do comprovante de adiamentamento é obrigatório.");
		}
		if (!configuracao.emailParametrizacaoProduto) {
			messages.push("O e-mail do responsável pela parametrização do produto não está preenchido.");
		}
		if (!configuracao.emailParametrizacaoCliente) {
			messages.push("O e-mail do responsável pela parametrização do cliente não está preenchido.");
		}
		if (messages.length > 0) {			
			ModalRealm.showDialog(<TZMPopup  key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);			
		} else {
			this.salvar();
		}
	}

	render() {
		return (
			<TZMMainContent>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-8">
							<TZMPanel header="Caminhos de Arquivos">
								<TZMForm>
									<div className="ui-g">
										<TZMTextField col={12} label="Notas Fiscais (Histórico)" name="localPathNotaFiscalHistorico" value={this.state.configuracao.localPathNotaFiscalHistorico} onChange={this.handleChange}/>
										<TZMTextField col={12} label="Notas Fiscais (SAP)" name="localPathNotaFiscalSap" value={this.state.configuracao.localPathNotaFiscalSap} onChange={this.handleChange}/>
										<TZMTextField col={12} label="Fichas Técnicas" name="localPathFichaTecnica" value={this.state.configuracao.localPathFichaTecnica} onChange={this.handleChange}/>
										<TZMTextField col={12} label="Comprovantes de Adiantamento" name="localPathComprovanteAdiatamento" value={this.state.configuracao.localPathComprovanteAdiatamento} onChange={this.handleChange}/>
									</div>
								</TZMForm>
							</TZMPanel>
						</div>
						<div className="ui-g-4">
							<TZMPanel >
								<TZMForm>
									<div className="ui-g">
										<TZMCheckbox col={12} checked={this.state.configuracao.flags?.includes("VALIDAR_PRECO")} name="VALIDAR_PRECO" label="Validar preços" onChange={this.toggleFlag}/>
										<TZMTextField col={12} label="E-mail responsável pelos dados do Cliente" name="emailParametrizacaoProduto" value={this.state.configuracao.emailParametrizacaoProduto} onChange={this.handleChange}/>
										<TZMTextField col={12} label="E-mail responsável pelos dados do Produto" name="emailParametrizacaoCliente" value={this.state.configuracao.emailParametrizacaoCliente} onChange={this.handleChange}/>
									</div>
								</TZMForm>
							</TZMPanel>
						</div>
					</div>
					<TZMPanelFooter>
						<div className="ui-g">
							<div className="ui-g-12" style={{textAlign: "right"}}>
								<TZMButton onClick={this.validar} label="Salvar"/>
							</div>	
						</div>
					</TZMPanelFooter>
				</TZMPanel>	
				<div className="content-section implementation p-fluid">
					<Growl ref={(el) => this.growl = el} baseZIndex={10001002}></Growl>
				</div>
			</TZMMainContent>
		);
	}

}
