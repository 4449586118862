import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";

export class VwVendedorService {

	buscar(id) {
		return httpGet(`/vw-vendedores/${JSON.stringify(id)}`);
	}

	buscarAsync = async(id) => {
		return  httpGetAsync(`/vw-vendedores/${JSON.stringify(id)}`);
	}

	autoCompletar = async (query) =>{		
		return  Fetch.GetJSONAsync(`/vw-vendedores?vendedor=${query}&size=1000&page=0&sort=nome`);
	}

	listar(params) {
		return httpGet("/vw-vendedores", params);
	}

	completarVendedor = async (pk) => {		
		return this.buscarAsync(pk);		
	}

}
  