import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {TZMCurrency} from "../../../components/common/TZMCurrency";
import {toCurrency, toCurrency4Casas, toPercent} from "../../../components/common/TZMFormatter";
import TZMIcon from "../../../components/common/TZMIcon";
import TZMTextArea from "../../../components/common/TZMTextArea";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import {asEmail} from "../../../utilities/TZMUtil";
import {Validator} from "../../../utilities/TZMValidator";
import {UF} from "../../../utilities/UF";
import {MotivoBloqueio} from "../../comum/pedido/MotivoBloqueio";
import {AlcadaService} from "../../servicos/AlcadaService";
import {CondicaoPagamentoService} from "../../servicos/CondicaoPagamentoService";
import {ListaPrecoService} from "../../servicos/ListaPrecoService";
import {LogEmailService} from "../../servicos/LogEmailService";
import {PedidoService} from "../../servicos/PedidoService";
import {VwOperacaoService} from "../../servicos/VwOperacaoService";
import {TransportadoraService} from "../../servicos/TransportadoraService";
import {UsuarioService} from "../../servicos/UsuarioService";
import {VwClienteService} from "../../servicos/VwClienteService";
import {VwProdutoService} from "../../servicos/VwProdutoService";
import {VwVendedorService} from "../../servicos/VwVendedorService";
import {EditarItemPedido} from "./EditarItemPedido";
import {JustificativaDesconto} from "./JustificativaDesconto";
import {TZMNumber} from "../../../components/common/TZMNumber";
import TZMMensagens from "../../../components/dialog/TZMMensagens";
import {ClienteUtils} from "../../comum/cliente/ClienteUtils";
import {Column} from "primereact/components/column/Column";
import {PedidoUtils} from "./PedidoUtils";
import {SimuladorService} from "../servicos/SimuladorService";
import {VwFormaPagamentoService} from "../../servicos/VwFormaPagamentoService";
import {ComboboxOperacao} from "../../../components/special/ComboboxOperacao";
import Templates from "../../../utilities/Templates";
import {VendedorService} from "../../servicos/VendedorService";

const basePath = process.env.REACT_APP_API_HOST;

export default class EditarPedido extends React.Component {

	constructor(props) {
		super();
		moment.locale('pt-BR');
		this.pedidoService = new PedidoService();
		this.usuarioService = new UsuarioService();
		this.condicaoPagamentoService = new CondicaoPagamentoService();
		this.state = {
			val:0.1,
			editavel: props.pedido.sistema !== TZMUserData.SAP() && (EditarPedido.statusEditaveis.includes(props.pedido.status) || (props.pedido.itens && props.pedido.itens.some(i => i.status === PedidoUtils.statusErro()))),
			pedido: this.completarNulos(props.pedido),
			visible: true,
			alterado: false,
			selectedIndex: -1,
			maisInfoCliente: false,
			firstItem: 0,
			operacoes:[],
			formasPagamento:[],
			condicoesPagamento:[],
			validarPreco: false
		};
		this.vwClienteService = new VwClienteService();
		this.transportadoraService = new TransportadoraService();
		this.vwVendedorService = new VwVendedorService();
		this.vwOperacaoService = new VwOperacaoService();
		this.vwFormaPagamentoService = new VwFormaPagamentoService();
		this.vwProdutoService = new VwProdutoService();
		this.listaPrecoService = new ListaPrecoService();				
		// this.indiceService = new IndiceService();
		this.logEmailService = new LogEmailService();
		this.alcadaService = new AlcadaService();		
		this.simuladorService = new SimuladorService();
		this.operacaoService = new VwOperacaoService();
		this.vendedorService = new VendedorService();
	}

	static statusEditaveis = [
		PedidoUtils.statusRascunho(),
		PedidoUtils.statusDescontoFinalizado(),
		PedidoUtils.statusErro(),
		PedidoUtils.statusDescontoReprovado(),
	];

	componentWillMount() {
		if (this.state.editavel) {						
			// this.indiceService.listar().then((indices) => {
			// 	indices = indices.map(indice => <option key={indice.pk.indiceId} value={JSON.stringify(indice)}>{`${indice.pk.indiceId} - ${indice.descricao}`}</option>);
			// 	indices.unshift(<option key={0} value={null} />);
			// 	this.setState({ indices });
			// });			
			this.simuladorService.validarPreco().then(response => this.setState({validarPreco: response.validarPreco}));
			this.operacaoService.listarCombobox().then(operacoes => this.setState({operacoes}));
		} else {			
			// if (this.state.pedido.indice != null) {
			// 	this.setState({ indices: [<option key={this.state.pedido.indice.pk.indiceId} value={JSON.stringify(this.state.pedido.indice)}>{`${this.state.pedido.indice.pk.indiceId} - ${this.state.pedido.indice.descricao}`}</option>] })			
			// }			
		}		
	}	

	 componentDidMount() {		
		if (this.state.pedido.id == null && this.state.pedido.sistema === TZMUserData.TZM()) {			
			this.usuarioService.completar(TZMUserData.getUsuario()).then((usuario => {
				let pedido = this.state.pedido;
				pedido.criadoPor = usuario;				
				pedido.emailAdiantamento = usuario.ultimoEmail;
				pedido.gestor = usuario.gestor;					
				this.setState({ pedido });
			}));			
		} else if (this.state.pedido.sistema === TZMUserData.SAP()) {
			this.pedidoService.criadoPor(this.state.pedido.pk.pedidoId).then((usuario => {	
				let pedido = this.state.pedido;
				pedido.criadoPor = usuario;	
				pedido.registro = usuario.criadoEm;		
				this.setState({ pedido });
			}));
		}

		if (this.state.pedido.vendedor.pk.vendedorId) {
			if (this.state.pedido.status !== 'HISTORICO') {
				this.selectVendedores({ value: this.state.pedido.vendedor.pk });		
			}
		} 
	
		if (this.state.pedido.status === PedidoUtils.statusConfirmado() || this.state.pedido.status === PedidoUtils.statusErro()) {
			this.state.pedido.itens.forEach((itemPedido) => {
				this.integracaoService.statusItemPedido(this.state.pedido.pedidoId, itemPedido.itemId).then((status) => {
					itemPedido.status = status;
					this.forceUpdate();
				});
			});
		}
	}

	completarNulos(pedido) {
		if (!pedido.cliente) {
			pedido.cliente = { pk: {}, razaoSocial: "", descricaoAmigavel: "" };
		}
		if (!pedido.transportadora) {
			pedido.transportadora = { pk: {}, razaoSocial: "", descricaoAmigavel: "" };
		}
		if (!pedido.vendedor) {
			pedido.vendedor = { pk: {}, nome: " ", descricaoAmigavel: " " };
		}
		if (!pedido.condicaoPagamento) {
			pedido.condicaoPagamento = { pk: {}, descricao: "", descricaoAmigavel: "" };
		}
		if (!pedido.itens) {
			pedido.itens = [];
		} else {
			pedido.itens.forEach((item) => {
				if (!item.desconto) item.desconto = "0";
				if (!item.descontoPercentual) item.descontoPercentual = "0";
				if (!item.quantidade) item.quantidade = "0";
				if (!item.valorUnitario) item.valorUnitario = "0";				
				if (!item.valorTotal) item.valorTotalItem = (item.valorUnitario * item.quantidade);
				if (!item.valorTotalItemTabela) item.valorTotalItemTabela = (item.valorUnitarioTabela * item.quantidade);
				if (!item.valorUnitarioTabela) item.valorUnitarioTabela = "0";				
				if (pedido.sistema === TZMUserData.TZM() && pedido.status !== 'HISTORICO') {
					if (!item.percentualIpi) item.percentualIpi = Number(item.produto.percentualIpi);
					if (!item.valorIpi)  item.valorIpi = (Number(item.valorUnitario) * Number(item.quantidade) * (Number(item.percentualIpi)));
				}
				if (!item.valorTotal) item.valorTotal = (item.valorUnitario * item.quantidade) + item.valorIpi;
			});
			pedido.itens = pedido.itens.sort((a, b) => a.itemId - b.itemId);
		}	
		if (pedido.valorFrete == null) {
			pedido.valorFrete = 0;
		}
		if (pedido.adiantamento == null) {
			pedido.adiantamento = 0;
		}
		if (pedido.arquivo == null) {
			pedido.arquivo = {};
		}
		pedido = this.preencherEnderecos(pedido);
		return pedido;
	}

	preencherEnderecos(pedido) {
		if (pedido.cliente.enderecos) {
			pedido.cliente.enderecos.forEach((endereco) => {
				if (endereco.pk.tipo === "COBRANCA") {
					pedido.cliente.enderecoCobranca = endereco;
				}
				if (endereco.pk.tipo === "ENTREGA") {
					pedido.cliente.enderecoEntrega = endereco;
				}
			});
		} else {
			pedido.cliente.enderecos = [];
		}
		return pedido;
	}

	salvarPedido = () => {
		if (this.validarPedido()) {
			let pedido = this.state.pedido;
			if (pedido.itens) {
				let maxItem = pedido.itens.map(i => Number(i.itemId)).filter(v => !isNaN(v)).reduce((a, b) => Math.max(a, b), 0);
				pedido.itens.forEach((item) => {
					if (item.itemId == null) item.itemId = ++maxItem;
					if (item.operacao == null) item.operacao = pedido.operacao;
				});
			}
			if (pedido.arquivo != null && pedido.arquivo.conteudo != null) {
				pedido.criadoPor.ultimoEmail = pedido.emailAdiantamento;
				pedido.arquivo.cascadeable = true;
				pedido.arquivo.conteudo = pedido.arquivo.conteudo.split(",").pop();
			}
			this.pedidoService.salvar(pedido).then((pedido) => {
				if (pedido.logIntegracaoSap && pedido.logIntegracaoSap.mensagem?.length) {
					this.setState({pedido}, () => {
						ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={[pedido.logIntegracaoSap.mensagem]} />);
					});
				} else {
					this.setState({visible: false});
					if (this.props.onSave) {
						this.props.onSave(pedido);
					}
				}
			});
		}
	}

	percentFormat = (rowData, column) => {
		return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field])}</div>;
	}

	fecharPainel = () => {
		this.setState({ visible: false });
		if (this.props.onClose) {
			this.props.onClose(this.state.pedido);
		}
	}

	fecharPedido = () => {
		if (this.state.alterado && this.state.pedido.sistema === TZMUserData.TZM()) {
			ModalRealm.showDialog(<TZMConfirm onYes={this.fecharPainel} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel();
		}
	}

	 validarPedido = () => {
		let messages = [];
		let enviarEmail = false;
		let globalMensagens = {};
		let pedido = this.state.pedido;
		let itensPreenchidos = true;
		if (!Validator.isEntidade(pedido.cliente)) {
			messages.push("O cliente é obrigatório.");
		} else {
			let cliente = pedido.cliente;
			if (!cliente.email) {
				messages.push("E-mail para envio de XML não está configurado para o cliente.");
			}
			if (!cliente.emailFinanceiro) {
				messages.push("E-mail financeiro não está configurado para o cliente.");
			}
			if (!Validator.isEntidade(cliente.condicaoPagamento)) {
				messages.push("A condição de pagamento não está configurada para o cliente.");
			}
			if (!Validator.isEntidade(cliente.vendedor)) {
				messages.push("O vendedor não está configurado para o cliente.");
			}
			if (!Validator.isEntidade(cliente.formaPagamento)) {
				messages.push("A forma de pagamento não está configurada para o cliente.");
			}
		}
		
		if (pedido.observacaoFiscal && pedido.observacaoFiscal.length > 4000) {
			messages.push("Tamanho máximo permitido para o campo Observação da Nota Fiscal é de 4000 caracteres.");
		}

		if (pedido.observacao && pedido.observacao.length > 254) {
			messages.push("Tamanho máximo permitido para o campo Observação (Uso Interno) é de 254 caracteres.");
		}

		if (pedido.frete !== 'RET' && !Validator.isEntidade(pedido.transportadora) ) {
			messages.push("A transportadora é obrigatória.");
		}
		if (!Validator.isEntidade(pedido.vendedor)) {
			messages.push("O vendedor é obrigatório.");
		}
		if (!Validator.isEntidade(pedido.operacao)) {
		 	messages.push("A operação é obrigatória.");
		}
		if (!Validator.isEntidade(pedido.condicaoPagamento)) {
			messages.push("A condição de pagamento é obrigatória.");
		}
		if (Validator.isEmpty(pedido.dataPedido)) {
			messages.push("A data do pedido é obrigatória.");
		}
		if (!Validator.isEntidade(pedido.formaPagamento)) {		
			messages.push("A forma de pagamento é obrigatória.");
		} else if (pedido.formaPagamento.dinheiro) {
			messages.push("A forma de pagamento não pode ser em Dinheiro.");
		}
		if (Validator.isEmpty(pedido.dataEntrega)) {
			messages.push("A data de entrega é obrigatória.");
		}
		if (Validator.isEmpty(pedido.frete)) {
			messages.push("O tipo do frete é obrigatório.");
		}
		if (pedido.dataPedido != null && pedido.dataEntrega != null) {
			let dataPedido = new Date(pedido.dataPedido);
			let dataEntrega = new Date(pedido.dataEntrega);
			moment(pedido.dataEntrega).weekday()
			if (moment(pedido.dataEntrega).weekday() === 0) {
				messages.push("A data de entrega não pode ser no Domingo.");
			}
			if (moment(pedido.dataEntrega).weekday() === 6) {
				messages.push("A data de entrega não pode ser no Sábado.");
			}
			dataEntrega.setDate(dataEntrega.getDate() - 2);//Data de entrega deve ser sempre maior que 3 dias que a data do pedido.
			if (dataPedido > dataEntrega) {
				messages.push("A data de entrega deve ser 2 dias maior que a data do pedido.");
			}
		}
		if (pedido.itens == null || pedido.itens.length === 0) {
			messages.push("O pedido deve ter ao menos um item.");
		} else {
			pedido.itens.forEach((item) => {				
				if (!Validator.isEntidade(item.produto)) {
					messages.push(`O item ${item.itemId} está sem produto preenchido.`);
					itensPreenchidos = false;
				} else {
					let sku = item.produto.pk.produtoId;				
					if (this.state.validarPreco && item.mensagens && item.mensagens.length > 0) {
						globalMensagens[sku] = item.mensagens;
						enviarEmail = true;
					}					
					if (!item.valorUnitario || item.valorUnitario === 0) {
						messages.push(`O valor unitário do produto ${sku} deve ser maior que zero.`);
					}	
					if (this.state.validarPreco) {
						if (!item.valorUnitarioTabela || Number(item.valorUnitarioTabela) === 0 ||  item.valorUnitarioTabela === 0) {
							messages.push(`Não foi possível calcular o valor unitário de tabela do produto ${sku}.`);		
						}
					}	
					if (Number(item.quantidade) === 0) {
						messages.push(`A quantidade do produto ${sku} deve ser maior que zero.`);
					}
					if (!item.produto.codigoNcm) {
						messages.push(`O código NCM do item ${item.itemId} - ${item.produto.descricao} não está preenchido.`);
					}
					if (!item.produto.tipoMercadoria) {
						messages.push(`O tipo de mercadoria do item ${item.itemId} - ${item.produto.descricao} não está preenchido.`);
					}
					if (!item.produto.fonteProduto) {
						messages.push(`A fonte do produto do item ${item.itemId} - ${item.produto.descricao} não está preenchida.`);
					}
				}
			});
		}
		if (pedido.cliente.exportacao) {
			if (Validator.isEmpty(pedido.localidadeEmbarque)) {
				messages.push("A localidade de embarque para exportações é obrigatória.");
			}
			if (Validator.isEmpty(pedido.ufEmbarque)) {
				messages.push("A UF de embarque para exportações é obrigatória.");
			}
			if (Validator.isEmpty(pedido.ordemVenda)) {
				messages.push("O número da ordem de venda para exportações é obrigatória.");
			}
		}

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			if (enviarEmail && itensPreenchidos) {				
				this.simuladorService.enviarEmail(globalMensagens).then( () => {
					let message = [];
					let texto = "Foi enviado um e-mail para a gerência solitando a parametrização dos valores de tabela zerado. ";
					texto += "Aguarde o retorno da gerência e tente incluir novamente esses itens. "					
					message.push(texto);
					ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={message} />);
				});
			}
			return false;
		}
		return true;
	}

	listClientes = async (event) => {
        await this.vwClienteService.autoComplete({cliente: event.query,posicao: 'ATIVO',vendedor: 'true', sort: 'razaoSocial'}).then((listClientes) => this.setState({listClientes}));
    }

	selectCliente = async (event) => {
		if (event.value != null) {
			const {pedido}  = this.state;
			await this.vwClienteService.buscarAsync(event.value).then(cliente => {
				pedido.cliente = cliente;				
				if (cliente.condicaoPagamento) {
					pedido.condicaoPagamento = pedido.cliente.condicaoPagamento;
				}				
				
				if (cliente.transportadora) {
					pedido.transportadora =  cliente.transportadora;
				}
				pedido.frete = pedido.cliente.pagamentoFrete;
				pedido.formaPagamento = pedido.cliente.formaPagamento;
				if (!TZMUserData.isRepresentante()) {
					if (pedido.cliente.vendedor != null && pedido.cliente.vendedor.nome != null) {						
						this.vwVendedorService.completarVendedor(pedido.cliente.vendedor.pk).then( (vendedor) =>{
							pedido.vendedor = vendedor;							
						});
					}
				}
				this.preencherEnderecos(pedido);
				this.setState({ pedido, maisInfoCliente: this.state.maisInfoCliente && pedido.cliente.pk.clienteId, alterado: true });
			})
		}
	}

	listTransportadoras = (event) => {
		this.transportadoraService.autoCompletar(event.query).then((response) => {
			this.setState({ listTransportadoras: response });
		});
	}

	listVendedores = (event) => {
		this.vwVendedorService.autoCompletar(event.query).then((response) => {
			this.setState({ listVendedores: response });
		});
	}

	selectVendedores = (event) => {
		let pedido = this.state.pedido;
		let pk;
		if (event.value.pk){
			pk = Object.assign({},  event.value.pk);
		} else {
			pk = Object.assign({},  event.value);
		}
		this.vwVendedorService.buscar(pk).then((vendedor) => { 
			pedido.vendedor = vendedor;
			this.setState({ pedido });
		});
	}

	deleteItemPedido = (event, rowData) => {		
		if (Validator.isEntidade(rowData.produto)) {
			ModalRealm.showDialog(<TZMConfirm onYes={() => {
				let pedido = this.state.pedido;
				pedido.itens.splice(pedido.itens.findIndex((item) => item.itemId === rowData.itemId), 1);
				let firstItem = pedido.itens.length % 10 === 0 ? pedido.itens.length >= 10 ? pedido.itens.length - 10 : 0 : this.state.firstItem;
				this.setState({ pedido, alterado: true, firstItem });
			}} question="Tem certeza de que deseja remover este item?" />);
		} else {
			let pedido = this.state.pedido;
			pedido.itens.splice(pedido.itens.findIndex((item) => item.itemId === rowData.itemId), 1);
			let firstItem = pedido.itens.length % 10 === 0 ? pedido.itens.length >= 10 ? pedido.itens.length - 10 : 0 : this.state.firstItem;
			this.setState({ pedido, alterado: true, firstItem });
		}
	}

	handleUpdate = (key, event) => {
		let pedido = this.state.pedido;
		pedido[key] = event.target.value;
		this.setState({ pedido, alterado: true });
	}

	listOperacoes = (event) => {
		this.vwOperacaoService.autoCompletar(event.query).then((operacoes) => this.setState({ operacoes }));
	}

	listFormasPagamento = (event) => {
		this.vwFormaPagamentoService.autoCompletar(event.query).then((formasPagamento) => this.setState({ formasPagamento }));
	}

	listCondicoesPagamento = (event) => {
		this.condicaoPagamentoService.autoCompletar(event.query).then((condicoesPagamento) => this.setState({ condicoesPagamento }));
	}
	selectTabIndex = (index) => {
		this.setState({ selectedTab: index });
	}

	handleUpdateItem = (key, index, value) => {
		let pedido = this.state.pedido;
		pedido.itens[index][key] = value;
		if (pedido.sistema === TZMUserData.TZM()) {
			if (pedido.itens[index].produto && pedido.cliente.estado !== 'SP' && pedido.itens[index].produto.cst !== 0 && pedido.itens[index].produto.percentualIcmsImportado) {
				pedido.itens[index].percentualIcms =  pedido.itens[index].produto.percentualIcmsImportado;
			} else if (pedido.itens[index].produto){
				pedido.itens[index].percentualIcms = pedido.cliente.percentualIcms || 0;				
			}
			pedido.itens[index].valorIpi = (Number(pedido.itens[index].valorUnitario) * Number(pedido.itens[index].quantidade) * (Number(pedido.itens[index].percentualIpi)));
			pedido.itens[index].valorIcms = (Number(pedido.itens[index].valorUnitario) * Number(pedido.itens[index].quantidade) * (Number(pedido.itens[index].percentualIcms)));
		}
		pedido.itens[index].valorTotal = (Number(pedido.itens[index].valorUnitario) * Number(pedido.itens[index].quantidade));
		pedido.itens[index].valorTotalItem = Number(pedido.itens[index].valorUnitario) * Number(pedido.itens[index].quantidade);
		pedido.itens[index].valorTotalItemTabela = Number(pedido.itens[index].valorUnitarioTabela) * Number(pedido.itens[index].quantidade);
		this.setState({ pedido, alterado: true });
	}

	mountEnumOptions = (enumList) => {
		if (enumList != null) {
			let enums = [<option key={"vazioEnum"} value={null} />];
			enums.push(...enumList.map((value) => <option key={value.value} value={value.value}>{value.descriptor}</option>));
			return enums;
		}
		return null;
	}

	editorProduto = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<div className="ui-g">
					<div className="ui-g-12">
						{Validator.isEntidade(rowData.produto) ? rowData.produto.pk.produtoId : null}
					</div>					
					<div className="ui-g-12">					
						<TZMAutoComplete disabled={this.lockItem(props.rowIndex)} inputClassName="editorProduto" field="descricao" itemTemplate={this.produtoTemplate} onKeyDown={this.blurOnEnter} value={this.state.pedido.itens[props.rowIndex].produto}
						onChange={(event) => {
							let pedido = this.state.pedido;
							pedido.itens[props.rowIndex].produto = event.value;
							this.setState({pedido});
						}} suggestions={this.state.listProdutos} completeMethod={this.listProdutos} onSelect={ (event) => {
							if (event.value && event.value.produtoId) {
								let pedido = this.state.pedido;						
								let cl = this.state.pedido.cliente;
								let cliente = { tipo: cl.tipo, estado: cl.estado, grupoId: cl.grupoId, 
												pk:{clienteId:  cl.pk.clienteId},
												frete: cl.frete, percentualIcms: cl.percentualIcms};
								this.vwProdutoService.produtoPreco({produto: event.value.produtoId,
																	cliente: cliente,
																	vendedorId: pedido.vendedor.pk.vendedorId,
																	login: TZMUserData.getUsuario().login,
																	operacao: pedido.operacao.usage,
																	frete: pedido.frete}).then((produtoPreco) => {
									pedido.itens[props.rowIndex] = this.atualizarItem(pedido.itens[props.rowIndex],produtoPreco, cliente);
									this.setState({ pedido, alterado: true });
								});
							}
						}}
						onClear={ () =>  this.onClearItemProduto(props.rowIndex)} />
					</div>
				</div>
			</div>
		);
	}



	atualizarItem =  (item, produtoPreco,cliente) => {

		let produto = produtoPreco.produto;		
		item.formaCalculo = produtoPreco.formaCalculo;
		item.produto = produto;
		item.unidade = produto.unidade
		item.logSimulacao = produtoPreco.logSimulacao;
		item.logListaPrecoItem =  produtoPreco.logListaPrecoItem;
		item.mensagens = produtoPreco.mensagens;		
		item.materiaPrima = produtoPreco.materiaPrima;
		item.descontoPercentual = 0;
		item.desconto = 0;
		item.quantidade = 0;
		item.valorTotalItem = 0;
		item.valorUnitario = produtoPreco.valorUnitario;
		item.valorUnitarioTabela = produtoPreco.valorUnitario;
		item.larguraEtiqueta = produtoPreco.larguraEtiqueta;
		item.alturaEtiqueta = produtoPreco.alturaEtiqueta;
		item.comprimentoRolo = produtoPreco.comprimentoRolo;		
		item.numeroRolosCaixa = produtoPreco.numeroRolosCaixa;	
		item.unidadeId = produtoPreco.unidadeId;	
		
		if (cliente.estado !== 'SP' && produto.cst !== 0 && produto.percentualIcmsImportado) {
			item.percentualIcms =  produto.percentualIcmsImportado;
		} else {
			item.percentualIcms = cliente.percentualIcms || 0;
		}		
		return item;
	}
	

	conferirAlcada = async (pedido) => {
		const alcadaPerfil = this.state.pedido.vendedor.alcada;
		if (alcadaPerfil && pedido.itens.some(pi => pi.descontoPercentual > alcadaPerfil.limite && (!pi.descontos || !pi.descontos.some(d => d.itens && d.itens.some(i => i.status === "APROVADO"))))) {
			const alcada = await this.alcadaService.buscar(alcadaPerfil.id);
			pedido.itens.filter(i => i.descontoPercentual > alcadaPerfil.limite && (!i.descontos || !i.descontos.some(d => d.itens && d.itens.some(item => item.status === "APROVADO")))).forEach(i => {
				const itens = alcada.perfis.map(p => ({
					perfil: p.perfil,
					status: "ENFILEIRADO",
					limite: p.limite,
					nivel: p.nivel
				})).sort((a, b) => a.nivel - b.nivel);
				let solicitado = false;
				itens.forEach((descontoItem) => {
					if (alcadaPerfil.perfil.id === descontoItem.perfil.id)  {
						descontoItem.status = "ENCAMINHADO";
						descontoItem.usuario = {id: TZMUserData.getUsuario().id};
					} else if (!solicitado) {
						descontoItem.status = "SOLICITADO";
						solicitado = true;				

					}
				});
				pedido.status = "ALCADA_DESCONTO";
				i.descontos = [{percentual: i.descontoPercentual,valorTotalItem: i.valorTotalItem, valorDesconto: i.desconto ,solicitante: {id: TZMUserData.getUsuario().id}, itens}];
			});
		}
	}

	showSaldoDisponivel = (rowData, props) => {
		if (this.state.pedido.status !== 'HISTORICO') {
			if (rowData.produto && rowData.produto.pk && rowData.produto.saldoDisponivel > 0) {			
				return rowData.produto.saldoDisponivel;
			}  
			return rowData.produto.saldoDisponivel;
		}
		return null;
	}		

	

	openMensagens = (index) => {
		ModalRealm.showDialog(<TZMMensagens header="Mensagens" text="Falha ao obter a tabela de preço:" mensagens={this.state.pedido.itens[index].mensagens} />);
	}

	editorValorUnitario = (rowData, props) => {		
		if (this.state.pedido.sistema === TZMUserData.TZM()) {			
			const hasMensagens = Validator.isEntidade(this.state.pedido.itens[props.rowIndex].produto) && this.state.pedido.itens[props.rowIndex].mensagens 
			&& this.state.pedido.itens[props.rowIndex].mensagens.length ;
			return (
				<div className="campo-descontos">
					<div title="Valor unitário de tabela." className="campo-valunit">
						{hasMensagens ? <i className="fa fa-exclamation-triangle" onClick={() => this.openMensagens(props.rowIndex)} /> : null}
						<TZMCurrency value={this.state.pedido.itens[props.rowIndex].valorUnitarioTabela} disabled precision={4} />
					</div>
					<div>
						<TZMCurrency name="valorUnitario" precision={4} index={props.rowIndex} // disabled={!this.state.pedido.vendedor.alcada || this.lockItem(props.rowIndex)}
						min={0}
						required value={this.state.pedido.itens[props.rowIndex].valorUnitario} onChange={this.handleChangeItem} />
					</div>
				</div>
			);				 
		} else {
			return (
				<div style={{textAlign : "center"}}>
					{toCurrency4Casas(this.state.pedido.itens[props.rowIndex].valorUnitario)}
				</div>
			);
		}
		
	}

	editorCfo = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMCombobox field="cfo" name="cfo" onChange={this.handleEntityCombobox} value={JSON.stringify(this.state.pedido.itens[props.rowIndex].cfo)}>
					{this.mountEnumOptions(this.state.listCfops)}
				</TZMCombobox>
			</div>
		);
	}

	listProdutos = (event) => {
		this.vwProdutoService.listarSimples(event.query).then((listProdutos) => this.setState({ listProdutos }));
	}

	insertItem = () => {
		switch (this.state.selectedTab) {
			case 1:
				let pedido = this.state.pedido;
				if (!pedido.itens) {
					pedido.itens = [];
				}
				pedido.itens.push({
					itemId: this.state.pedido.itens.map(i => i.itemId).reduce((a, b) => Math.max(a, b), 0) + 1,
					produto: {
						pk: {},
						descricao: " "
					},
				valorUnitario: "0",
					valorUnitarioTabela: 0,
					valorIpi: 0,
					percentualIpi: 0,
					percentualIcms: 0,
					valorIcms: 0,
					valorTotal: 0,
					valorTotalItem: 0,
					valorTotalItemTabela: 0,
					quantidade: "0",					
					desconto: "0",
					descontoPercentual: "0",
					descontos:[]

				});
				let firstItem = pedido.itens.length % 10 === 1 ? pedido.itens.length - 1 : this.state.firstItem;
				this.setState({ pedido, alterado: true, firstItem });
				if (firstItem === pedido.itens.length - 1) {
					setTimeout(() => {
						document.getElementsByClassName("editorProduto")[0].focus();
					}, 200);
				}
				break;
			default:
		}
	}

	handleCliente = (key, value) => {
		let pedido = this.state.pedido;
		if (value) {
			pedido.cliente[key] = JSON.parse(value);
		} else {
			pedido.cliente[key] = {};
		}
		this.setState({ pedido, alterado: true });
	}

	onClearItemProduto = (index) => {
		let pedido = this.state.pedido;
		pedido.itens[index].produto =  { pk: {},descricao: " "};
		pedido.itens[index].unidade = null;		
		pedido.itens[index].valorTotalItemTabela = 0;
		pedido.itens[index].valorTotalItem = 0;
		pedido.itens[index].quantidade = 0;
		pedido.itens[index].desconto = 0;
		pedido.itens[index].descontoPercentual = 0;
		pedido.itens[index].valorUnitarioTabela = 0;
		pedido.itens[index].valorUnitario = 0;				
		pedido.itens[index].percentualIcms = 0;
		pedido.itens[index].logSimulacao = null;
		pedido.itens[index].logListaPrecoItem = null;
		pedido.itens[index].mensagens = null;
		pedido.itens[index].materiaPrima = null;
		pedido.itens[index].larguraEtiqueta = 0;
		pedido.itens[index].alturaEtiqueta = 0;
		pedido.itens[index].comprimentoRolo = 0;		
		pedido.itens[index].numeroRolosCaixa = 0;
		pedido.itens[index].unidadeId = null;
		this.setState({ pedido, alterado: true })
	}

	enderecoLiteral(endereco) {
		return "[" + endereco.pk.tipo + "] " + endereco.logradouro + " " + endereco.bairro + ". CEP " + endereco.cep + " - " + endereco.cidade + "/" + endereco.estado;
	}

	confirmarPedido = async () => {
		if (this.validarPedido()) {
			let messages = [];
			if (this.state.pedido.cliente.posicao === "INATIVO") {
				messages.push("Não é possível confirmar pedidos para clientes inativos.");
			}
			if (this.state.pedido.vendedor.posicao === "INATIVO") {
				messages.push("Não é possível confirmar pedidos para vendedores inativos.");
			}
			if (this.state.pedido.condicaoPagamento.especial && this.state.pedido.adiantamento === 0) {
				messages.push("Valor do adiantamento não informado.");
			}
			if (this.state.pedido.adiantamento > 0) {
				if (Validator.isEmpty(this.state.pedido.arquivo.id) && Validator.isEmpty(this.state.pedido.arquivo.conteudo)) {
					messages.push("A imagem do comprovante de adiantamento é obrigatória");
				}
				if (!Validator.isEmail(this.state.pedido.emailAdiantamento)) {
					messages.push("O email para envio do comprovante de adiantamento é obrigatório");
				} else if (!this.state.pedido.emailAdiantamento.toLowerCase().endsWith("@beontag.com")) {
					messages.push("O email para envio do comprovante de adiantamento deve pertencer ao domínio da BeOnTag");
				}
				if (Validator.isEmpty(this.state.pedido.textoAdiantamento)) {
					messages.push("O texto para envio do comprovante de adiantamento é obrigatório");
				}
			}
			if (messages.length > 0) {
				ModalRealm.showDialog(<TZMPopup  key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			} else {
				ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()}
						question="Tem certeza de que deseja confirmar este pedido?" onYes={async () => {
					let pedido = this.state.pedido;
					pedido.novo = true;
					pedido.status = PedidoUtils.statusConfirmado();
					if (this.state.validarPreco) {
						await this.conferirAlcada(pedido);
						if (pedido.itens && pedido.itens.some(i => i.descontos && i.descontos.some(d => d.itens.some(di => di.status === "SOLICITADO")))) {
							ModalRealm.showDialog(<JustificativaDesconto key={TZMUserData.getIdGenerator()} itens={pedido.itens} onModalClose={(justificativa) => {
								pedido.itens.filter(i => i.descontos.filter(d => d.itens.filter(di => di.status === "ENCAMINHADO").forEach((di) => {
									di.justificativa = justificativa;
								})));
								this.setState({pedido});
								this.salvarPedido();
							}} />);
						} else {
							this.setState({pedido});
							this.salvarPedido();
						}
					} else {
						this.setState({pedido});
						this.salvarPedido();
					}
					
				}} />);
			}

		}
	}

	motivoBloqueio = (rowData) => {
		ModalRealm.showDialog(<MotivoBloqueio key={TZMUserData.getIdGenerator()} logs={rowData.motivosBloqueio} />);
	}

	queryAutoComplete = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.value;
		this.setState({ pedido });
	}

	selectAutoComplete = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.value;
		this.setState({ pedido, alterado: true });
	}

	clearAutoComplete = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = { pk: {}, [event.field]: " " };
		this.setState({ pedido, alterado: true });
	}

	clearAutoCompleteCliente = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = { pk: {}, [event.field]: " " };
		if ((pedido.tipoMovimento === null) || (pedido.tipoMovimento != null && !pedido.tipoMovimento.bonificacao)) {
			pedido.condicaoPagamento = { pk: {}, descricao: " ", descricaoAmigavel: " " };
		}
		this.setState({ pedido, alterado: true });
	}

	checkNullArray(value) {
		return value ? value : [];
	}

	handleChange = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name][event.field] = event.target.value;
		this.setState({ pedido, alterado: true });
	}

	handleCChange = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.value;
		this.setState({ pedido });
	}

	handleEntityCombobox = (event) => {
		let pedido = this.state.pedido;
		if (event.target.value != null && event.target.value !== "") {
			pedido[event.name] = JSON.parse(event.target.value);
		} else {
			pedido[event.name] = null;
		}
		this.setState({ pedido, alterado: true });
	}

	handleEntityComboboxTipoMovimento = (event) => {
		let pedido = this.state.pedido;
		if (event.target.value != null && event.target.value !== "") {
			pedido[event.name] = JSON.parse(event.target.value);
			if (pedido[event.name].bonificacao) {
				this.condicaoPagamentoService.bonificacao('3187').then((bonificacao => {
					pedido.condicaoPagamento = bonificacao;
					this.setState({ pedido });
				}));
			} else {
				pedido.condicaoPagamento = pedido.cliente.condicaoPagamento;
			}
		} else {
			pedido.condicaoPagamento = pedido.cliente.condicaoPagamento
			pedido[event.name] = null;
		}
		this.setState({ pedido, alterado: true });

	}

	handleCombobox = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.target.value;
		this.setState({ pedido, alterado: true });
	}

	blurOnEnter = (event) => {
		if (event.keyCode === 9 || event.keyCode === 13) document.activeElement.blur();
	}

	pagamentosFrete = [
		<option key={-1} value={null}></option>,
		<option key={2} value="FOB">F - Frete a Pagar</option>,
		<option key={1} value="CIF">C - Frete Incluso</option>,
		<option key={3} value="RET">R - Cliente Retira</option>		
	];


	produtoTemplate(produto) {
		return `${produto.produtoId} - ${produto.descricao}`;
	}

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	vendedorTemplate(vendedor) {
		return <div><i className={`fa fa-${vendedor.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {vendedor.pk.vendedorId} - {vendedor.nome}</div>;
	}
	
	transportadoraTemplate(transportadora) {
		if (transportadora.cidade) {
			return `${transportadora.descricaoAmigavel} (${transportadora.cidade})`;
		} else {
			return `${transportadora.descricaoAmigavel}`;
		}
	}

	condicaoPagamentoTemplate(condicaoPagamento) {
		return `${condicaoPagamento.pk.condicaoPagamentoId} - ${condicaoPagamento.descricao}`;
	}

	loadImage = (event) => {
		let reader = new FileReader();
		if (this.input.files != null && this.input.files.length > 0) {
			reader.readAsDataURL(this.input.files[0]);
			const name = this.input.files[0].name;
			reader.onload = (event) => {
				let pedido = this.state.pedido;
				pedido.arquivo = {
					conteudo: event.target.result,
					extensao: name.substring(name.lastIndexOf(".") + 1).toUpperCase()
				};
				this.setState({pedido});
			};
		}
	}

	fileInputRef = (input) => {
		this.input = input;
	}

	paginacaoItens = (event) => {
		this.setState({ firstItem: event.first });
	}

	editarItemPedido = (itemPedido) => {		
		ModalRealm.showDialog(<EditarItemPedido operacoes={this.state.operacoes} key={TZMUserData.getIdGenerator()} itemPedido={itemPedido} 
												operacao={ this.state.pedido.operacao} sistema={ this.state.pedido.sistema} onSalvar={() => {
			let pedido = this.state.pedido;
			pedido.itens.filter(i => i.itemId === itemPedido.itemId).forEach(item => Object.assign(item, itemPedido));
			this.setState({ pedido });
		}}/>);
	}

	statusEnvioEmail = () => {
		if (!this.state.logEmail) return null;
		let mensagem;
		switch (this.state.logEmail.status) {
			case "SUCESSO": mensagem = "Email enviado com sucesso!"; break;
			default: mensagem = "Falha ao enviar email"; break;
		}
		return <div className={`ui-tzm-email-chip ui-tzm-email-chip-${this.state.logEmail.status === "SUCESSO" ? "success" : "failure"}`}>{mensagem}</div>;
	}

	columnStatus = (pedido) => {
		let value;
		switch (pedido.status) {
			case "BLOQUEADO":
				value = "lock";
				break;
			case "CANCELADO":
				value = "ban";
				break;
			case "CARTEIRA":
				value = "wallet";
				break;
			case "FATURADO":
				value = "check";
				break;
			case PedidoUtils.statusErro():
				value = "times";
				break;
			case "RASCUNHO":
				value = "shopping-cart";
				break;
			case "PROCESSADO":
				value = "thumbs-up";
				break;
			default:
				value = "clock";
				break;
		}
		return `fas fa-${value}`
	}

	editoresDesconto = (pi, c) => {
		return (
			<div className="campo-descontos">
				<div>{this.editorDescontoCash(pi, c)}</div>
				<div>{this.editorDescontoPercent(pi, c)}</div>
			</div>
		);
	}

	nextItem = (index, event) => {
		if (!event.shiftKey) {
			switch (event.keyCode) {
			case 9:
				if (index === this.state.pedido.itens.length - 1) {
					this.insertItem();
				}
				break;
			default:
				break;
			}
		}
	}

	editorDescontoPercent = (pi, c) =>
		<TZMCurrency precision={1} prefix="" suffix="%" disabled={!this.state.pedido.vendedor.alcada || this.lockItem(c.rowIndex)} index={c.rowIndex} col={12} min={0}
		max={this.state.limiteDesconto} onChange={this.handleChangeItem} name="descontoPercentual" value={pi.descontoPercentual} />;

	editorDescontoCash = (pi, c) =>
		<TZMCurrency disabled={!this.state.pedido.vendedor.alcada || this.lockItem(c.rowIndex)} min={0} max={this.maxDiscountItem(c.rowIndex)}
		index={c.rowIndex} col={12} onChange={this.handleChangeItem} name="desconto" value={pi.desconto} />;

	maxDiscountItem = (index) => {
		const item = this.state.pedido.itens[index];
		return (item.valorTotalItemTabela || 0) * (this.state.limiteDesconto / 100);
	}

	lockItem = (index) => {
		return (
			((this.state.pedido.itens[index].descontos && this.state.pedido.itens[index].descontos.some(d => d.itens.some(di => di.status === "APROVADO"))) 
		|| (this.state.pedido.sistema === TZMUserData.SAP() ))
		);
	}

	handleChangeItem = (event) => {
		let value = Number(event.value);
		if (!isNaN(value)) {
			const { pedido } = this.state;
			pedido.itens[event.index][event.name] = event.value;			
			switch (event.name) {
				case "quantidade":
					pedido.itens[event.index].valorTotalItem = pedido.itens[event.index].valorUnitario * value;
					pedido.itens[event.index].valorTotalItemTabela = pedido.itens[event.index].valorUnitarioTabela * value;
					if (pedido.itens[event.index].valorUnitario <= pedido.itens[event.index].valorUnitarioTabela) {
						pedido.itens[event.index].descontoPercentual = 100 - (pedido.itens[event.index].valorTotalItem / pedido.itens[event.index].valorTotalItemTabela * 100);
						pedido.itens[event.index].desconto = pedido.itens[event.index].valorTotalItemTabela * pedido.itens[event.index].descontoPercentual / 100;
					}
					break;
				case "desconto":
					if (Number(pedido.itens[event.index].quantidade) === 0 && value === 0)  {
						pedido.itens[event.index].valorUnitario = pedido.itens[event.index].valorUnitarioTabela;
					} else {
						pedido.itens[event.index].descontoPercentual = value / pedido.itens[event.index].valorTotalItemTabela * 100;
						pedido.itens[event.index].valorUnitario = pedido.itens[event.index].valorUnitarioTabela - (pedido.itens[event.index].valorUnitarioTabela * pedido.itens[event.index].descontoPercentual / 100);
						pedido.itens[event.index].valorTotalItem = pedido.itens[event.index].valorUnitario * pedido.itens[event.index].quantidade;
					}
					break;
				case "descontoPercentual":
					pedido.itens[event.index].desconto = pedido.itens[event.index].valorTotalItemTabela * value / 100;
					pedido.itens[event.index].valorUnitario = pedido.itens[event.index].valorUnitarioTabela - pedido.itens[event.index].valorUnitarioTabela * value / 100;
					pedido.itens[event.index].valorTotalItem = pedido.itens[event.index].valorUnitario * pedido.itens[event.index].quantidade;
					break;
				case "valorUnitarioTabela":
					pedido.itens[event.index].valorTotalItemTabela = pedido.itens[event.index].valorUnitarioTabela * pedido.itens[event.index].quantidade;
					if (value <= pedido.itens[event.index].valorUnitarioTabela) {
						pedido.itens[event.index].descontoPercentual = 100 - (pedido.itens[event.index].valorTotalItem / pedido.itens[event.index].valorTotalItemTabela * 100);
						pedido.itens[event.index].desconto = pedido.itens[event.index].valorTotalItemTabela * pedido.itens[event.index].descontoPercentual / 100;
					} else {
						pedido.itens[event.index].descontoPercentual = 0;
						pedido.itens[event.index].desconto = 0;
					}
					break;
				case "valorUnitario":
					pedido.itens[event.index].valorTotalItem = pedido.itens[event.index].valorUnitario * pedido.itens[event.index].quantidade;
					if (value <= pedido.itens[event.index].valorUnitarioTabela) {
						pedido.itens[event.index].descontoPercentual = 100 - (pedido.itens[event.index].valorTotalItem / pedido.itens[event.index].valorTotalItemTabela * 100);
						pedido.itens[event.index].desconto = pedido.itens[event.index].valorTotalItemTabela * pedido.itens[event.index].descontoPercentual / 100;
					} else {
						pedido.itens[event.index].descontoPercentual = 0;
						pedido.itens[event.index].desconto = 0;
					}
					break;
				default:
					break;

			}			
			pedido.itens[event.index].valorTotal = pedido.itens[event.index].valorTotalItem;
			if (isNaN(pedido.itens[event.index].valorUnitario)) pedido.itens[event.index].valorUnitario = "0";
			if (isNaN(pedido.itens[event.index].desconto)) pedido.itens[event.index].desconto = "0";
			if (isNaN(pedido.itens[event.index].descontoPercentual)) pedido.itens[event.index].descontoPercentual = "0";

			if (pedido.itens[event.index].logSimulacao) {
				pedido.itens[event.index].logSimulacao.descontoPercentual = pedido.itens[event.index].descontoPercentual  / 100;
				pedido.itens[event.index].logSimulacao.precoBrutoDesconto = pedido.itens[event.index].valorUnitario;
			}
			this.setState({pedido});
		}
	}

	valorTotal = (pi) => {
		return (
			<div className="campo-impostos">
				<div>Total S/ Desconto:</div><div style={{textAlign: "right"}}>{pi.valorTotalItemTabela.toCurrency()}</div>
				<div>Total C/ Desconto:</div><div style={{textAlign: "right"}}>{pi.valorTotalItem.toCurrency()}</div>
			</div>
		);
	}

	criadoPor = () => {
		let pedido = this.state.pedido;		
		if ((pedido.sistema === TZMUserData.SAP() && pedido.criadoPor && pedido.criadoPor.nome) || (pedido.sistema === TZMUserData.TZM() && pedido.id && pedido.status !== 'HISTORICO')) {			
			return `Criado Por: ${pedido.criadoPor.nome}  -  ${moment(pedido.registro).format("DD/MM/YYYY HH:mm:ss")}`;
		}
		return null;
	}

	impostos = (item) => {		
		if (item.status !== 'BLOQUEADO') {
			let impostos = item.impostos;
			if (impostos && impostos.length > 0) {
				return (
					<div>
						{impostos.filter(e => e.valor > 0).map(e => 
							<div className="campo-impostos">
								<div style={{fontWeight: "bold", textAlign:"left"}} key={Math.random()} > {e.imposto}  </div>
								<div style={{textAlign:"right"}}  key={Math.random()} > {toCurrency(e.valor)} </div>
							</div>
						)}
						<div className="campo-impostos">
							<div  style={{fontWeight: "bold", textAlign:"left"}}> TOTAL  </div>
							<div  style={{fontWeight: "bold", textAlign:"right"}} > {toCurrency(impostos.map((imposto) => imposto.valor).reduce((a, b) => a + b, 0))} </div>
						</div>
					</div>		
				)
			} else {
				return (<div style={{textAlign: "center", fontWeight: "bold"}} > Sem Imposto </div>);
			}
		}
		return (<div style={{textAlign: "center", fontWeight: "bold"}} > </div>);
	}

	handleChangePercentual = (event) => {
		let pedido = this.state.pedido;
		if (!isNaN(event.value)) {
			pedido.percentualDesconto = event.target.value;
		}
		this.setState({ pedido, alterado: true });
    }

	renderOperacao = () => {		
		let tzm  = this.state.pedido.sistema === TZMUserData.TZM();
		let desativado = this.state.pedido.itens.length > 0;
		if (tzm) {
			let zonaFranca = this.state.pedido.operacao?.zonaFranca;
			if (zonaFranca) {
				return (
					<div>
						<div className="ui-g-6">
							<ComboboxOperacao operacoes={this.state.operacoes} label="Operação" disabled={desativado} name="operacao" value={this.state.pedido.operacao} onChange={this.handleCombobox}/>								 
						</div>
						<div className="ui-g-6">							
							<TZMTextField label="Desconto Zona Franca" name="percentualDesconto" value={this.state.pedido.percentualDesconto} onChange={this.handleChangePercentual} />
						</div>
					</div>
				);
			} else {
				return(
				<div className="ui-g-2">
					<ComboboxOperacao operacoes={this.state.operacoes} disabled={desativado} label="Operação" name="operacao" value={this.state.pedido.operacao} onChange={this.handleCombobox}/>								 
				</div>);
			}
		} else {
			let perncentualDesconto = this.state.pedido.percentualDesconto;
			if (perncentualDesconto && perncentualDesconto > 0) {
				return (
					<div className="ui-g-2">
						<TZMTextField label="Desconto Zona Franca" disabled name="percentualDesconto" value={toPercent(this.state.pedido.percentualDesconto / 100)} />
					</div>);
			} else {
				return (<div className="ui-g-2"/>);
			}
		}
		
	}

	simular = (itemPedido, props) => {
		if (itemPedido.formaCalculo === 'SIMULACAO') {
			let s1 = Object.assign({},itemPedido.logSimulacao);
			s1.unidade = itemPedido.unidade;
			s1.quantidadeRolos = itemPedido.quantidade;		
			s1.dividir = false;
			this.simuladorService.calcular(s1).then((s2) => {			
				itemPedido = this.atualizarValor(itemPedido, s2);
				this.handleChangeItem({name: "valorUnitarioTabela", index: props.rowIndex, value: itemPedido.valorUnitarioTabela});
				this.handleChangeItem({name: "quantidade", index: props.rowIndex, value: itemPedido.quantidade});				
			});
		}
	}

	handleUnidadeChange = (event,index) => {
		const {pedido} = this.state;
		pedido.itens[event.index].unidade = event.value;
		let s1 = Object.assign({},pedido.itens[event.index].logSimulacao);
		s1.unidade = pedido.itens[event.index].unidade;
		s1.quantidadeRolos = pedido.itens[event.index].quantidade;		
		s1.dividir = false;
		this.simuladorService.calcular(s1).then((s2) => {			
			pedido.itens[event.index] = this.atualizarValor(pedido.itens[event.index], s2);
			event.name = "quantidade";
			event.value = s1.quantidadeRolos;
			this.setState({pedido});
			this.recalcularItem(event);
		});
	}

	editorQuantidade = (rowData, props) => {
		return (
			<div className='tzm-ui-tablelike'>
				<TZMNumber index={props.rowIndex} name="quantidade" disabled={this.lockItem(props.rowIndex)} min={0} required
					value={this.state.pedido.itens[props.rowIndex].quantidade.toString()} onChange={this.handleChangeItem} 
					onBlur={ () => this.simular(rowData,props)} />
			</div>
		);
	}

	recalcularItem = event => {
		this.handleChangeItem(event);
	}

	atualizarValor(item, logSimulacao) {
		item.logSimulacao = logSimulacao;
		let preco = logSimulacao.precos.find(preco => preco.unidade  === item.unidade);	
		
		if (preco) {			
			item.valorUnitario = preco.valorUnitario;
			item.valorUnitarioTabela = preco.valorUnitario;
			item.unidadeId = preco.unidadeId;
			item.logSimulacao.unidadeId = preco.unidadeId;
			item.logSimulacao.precoBrutoUnidade = logSimulacao.precoBrutoUnidade;
			item.logSimulacao.precoNetUnidade = logSimulacao.precoNetUnidade;
		} else {
			item.valorUnitario = logSimulacao.precoBruto;
			item.valorUnitarioTabela = logSimulacao.precoBruto;
			item.unidadeId = logSimulacao.unidadeId;
			item.logSimulacao.precoBrutoUnidade = logSimulacao.precoBrutoUnidade;
			item.logSimulacao.precoNetUnidade = logSimulacao.precoNetUnidade;
		}
		return item;
	}
	
	renderColumnsItens = () => {		
		let tzm  = this.state.pedido.sistema === TZMUserData.TZM();
		let colums = [];				
		colums.push(<Column key="status" style={{width: "3em", textAlign: "center"}}  body={(rowData) => PedidoUtils.columnStatus(rowData)} />);
		if (tzm) {
			colums.push(<Column key="item" style={{width: "3em", textAlign: "center"}} header="Item" field="itemId" />);
		} else {
			colums.push(<Column key="item" style={{width: "3em", textAlign: "center"}} header="Item" field="pk.itemId" />);
		}
		colums.push(<Column key="descricao" body={this.editorProduto} style={{width: "*"}} header="Produto" />);
		if (tzm) {
			colums.push(<Column header="Saldo Disponível" key="saldo" style={{width: "8em", textAlign: "center"}} body={this.showSaldoDisponivel}/>);
		}
		colums.push(<Column key="quantidade" body={this.editorQuantidade} style={{width: "10em"}} field="quantidade" header="Quantidade" />);
		if (tzm) {
			colums.push(
				<Column key="unidade" field="unidade" style={{textAlign: "center", width: "4.5em"}} header="Unidade" body={(pi, i) => (
					<TZMCombobox  disabled={pi.formaCalculo !== 'SIMULACAO' || !pi?.produto?.pk?.produtoId} index={i.rowIndex} 
					value={pi.unidade} onChange={this.handleUnidadeChange} 
					children={pi.produto?.unidades?.map(u => <option key={Math.random()} value={u.unidade}>{u.unidadeDescricao}</option>)}/>
				)}/>
			);
		} else {
			colums.push(<Column key="unidade" field="unidade" style={{ textAlign: "center", width: "4.5em" }} header="Unidade" />);		
		}		

		colums.push(<Column key="valorUnitario" style={{width: "12em"}} name="valorUnitario" field="valorUnitario" header="Valor Unitário" body={this.editorValorUnitario} />);
		if (!tzm) {
			colums.push(<Column key="valores" style={{width: "17em"}} header="Impostos" body={(item) => this.impostos(item)}/>);
		}

		if (tzm) {
			colums.push(<Column key="descontoCash" style={{width: "10em"}} header="Desconto" body={this.editoresDesconto} />);
			colums.push(<Column key="totalItem" style={{width: "16em"}}  body={ (pi) => this.valorTotal(pi)} />);
		}
		colums.push(
		<Column key="totalFinalItem" style={{width: "14em"}} header="Valor Final" body={ (pi) =>
			<div style={{textAlign : "center"}}>
				{toCurrency(pi.valorTotalItem)}
			</div>} />		
		);	
		colums.push(							
		<Column style={{width: "6em", textAlign: "center"}} key="acoes" header="Ações" body={(rowData, c) => {
			return (
				<div>											
					<span className="fake-link" onClick={() => this.editarItemPedido(rowData)}><TZMIcon title="CFOP/Operação" className="ui-action-icon ui-action-icon-edit" name="edit" /></span>
					{this.state.editavel &&  (this.state.pedido.status !== PedidoUtils.statusConfirmado() && this.state.pedido.status !== PedidoUtils.statusErro())
						? <span className="fake-link" onClick={(event) => this.deleteItemPedido(event, rowData)}><TZMIcon title="Excluir Item do Pedido" className="ui-action-icon ui-action-icon-delete" name="trash-alt" /></span> : null}
				</div>
			);
		}} />);
		return colums;
	}

	listarVendedores = (event) => {
		this.vwVendedorService.autoCompletar(event.query).then(vendedores => this.setState({vendedores}));
	}

	handleClear = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = null;
		this.setState({pedido});
	}

	handleSelect = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.value;
		this.setState({pedido});
	}

	handleUnselect = (event) => {
		let pedido = this.state.pedido;
		if (!pedido[event.name]) {
			pedido[event.name] = [];
		}
		pedido[event.name] = pedido[event.name].filter((e) => e.id !== event.value.id);
		this.setState({pedido});
	}

	render() {		
		let tzm  = this.state.pedido.sistema === TZMUserData.TZM();
		let exportacao = this.state.pedido.cliente.exportacao ? null : "none";
		let adiantamento = Number(this.state.pedido.adiantamento) > 0 ? null : "none";
		let display = TZMUserData.isRepresentante()  ? "none" : null;
		let desativado = this.state.pedido.itens.length > 0;
		let mostrarItens = ((this.state.pedido.sistema === TZMUserData.SAP()) || (Validator.isEntidade(this.state.pedido.cliente) 
							&& Validator.isEntidade(this.state.pedido.operacao)
							&& Validator.isEntidade(this.state.pedido.vendedor)
							&& this.state.pedido.frete));
		let pedidoId = tzm ? this.state?.pedido?.numeroPedido?.id : this.state?.pedido?.numeroPedido;
		return (
			<TZMDialog style={{ width: "1480px" }} visible={this.state.visible} modal header={
				<span className="fake-link">Pedido {pedidoId ? pedidoId : ""}
					<span style={{ position: "absolute", right: "40px", top: "8px" }}>
						<i title={this.criadoPor()} style={{ marginRight: "8px" }} className={this.columnStatus(this.state.pedido)} />{this.state.pedido.statusExtenso}
					</span>
				</span>
			} {...this.props}  >
				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<div className="ui-g">							
							<div className="ui-g-2"><TZMTextField  disabled={true} label="Pedido" value={pedidoId} /></div>
							<div className="ui-g-2"><TZMCalendar appendTo={document.body} onChange={(event) => this.handleUpdate("dataPedido", event)} label="Data do Pedido" value={moment(this.state.pedido.dataPedido).format("YYYY-MM-DD")} /></div>							
							<div className="ui-g-2"><TZMCalendar appendTo={document.body} onChange={(event) => this.handleUpdate("dataEntrega", event)} label="Data Prevista para Entrega" value={this.state.pedido.dataEntrega} /></div>							
							<div className="ui-g-2"/>
							<div className="ui-g-2"/>
							<div className="ui-g-2"><TZMTextField maxLength={15} onChange={(event) => this.handleUpdate("pedidoCliente", event)} label="Pedido Cliente (xPed)" value={this.state.pedido.pedidoCliente} /></div>
							<div className="ui-g-1 ui-g-nopad">
								<div className="ui-g-9 ">
									<TZMTextField disabled forac="true" value={this.state.pedido.cliente.posicao} label="Situação" />
								</div>
								<div className="ui-g-3 cliente-tipo">
									{ClienteUtils.clienteTipoPedido(this.state.pedido.cliente.tipo)}
								</div>
							</div>
							<div className="ui-g-4" style={{ position: "relative" }}>
								{Validator.isEntidade(this.state.pedido.cliente) && this.state.pedido.cliente.pk.clienteId ? <div className="tzm-overlink" onClick={() => this.setState({ maisInfoCliente: !this.state.maisInfoCliente })}>{this.state.maisInfoCliente ? "Menos" : "Mais"} informações</div> : null}
								<TZMAutoComplete emptyMessage="Nenhum registro encontrado" placeholder="Razão social, fantasia ou CNPJ" itemTemplate={ClienteUtils.clienteTemplateOmega} onClear={this.clearAutoCompleteCliente}
												onSelect={this.selectCliente} onChange={this.queryAutoComplete} suggestions={this.state.listClientes} completeMethod={this.listClientes} label="Cliente"
												name="cliente" field="descricaoAmigavel" value={this.state.pedido.cliente} disabled={desativado}/>
							</div>
							<div className="ui-g-4"><TZMTextField disabled forac="true" label="Nome Fantasia" value={this.state.pedido.cliente.nomeFantasia} /></div>
							<div className="ui-g-3"><TZMTextField disabled forac="true" label="E-mail" value={this.state.pedido.cliente.email} /></div>
							{this.state.maisInfoCliente ? (
								<div>
									<div className="ui-g-3"><TZMTextField disabled forac="true" label="CPF/CNPJ" value={this.state.pedido.cliente.cpfCnpj} /></div>
									<div className="ui-g-3"><TZMTextField disabled forac="true" label="Inscrição Estadual" value={this.state.pedido.cliente.inscricaoEstadual} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 1" value={this.state.pedido.cliente.telefone1} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 2" value={this.state.pedido.cliente.telefone2} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 3" value={this.state.pedido.cliente.telefone3} /></div>
									<div className="ui-g-12">
										<TZMTable value={this.state.pedido.cliente.contatos} emptyMessage="Nenhum contato encontrado">
											<Column key="nome" style={{ textAlign: "left", width: "15em" }} field="nome" header="Contato" />
											<Column key="telefone1" style={{ textAlign: "center", width: "10em" }} field="telefone1" header="Telefone 1" />
											<Column key="telefone2" style={{ textAlign: "center", width: "10em" }} field="telefone2" header="Telefone 2" />
											<Column key="email" style={{ width: "*" }} field="email" header="E-mail" body={(rowData) => rowData != null ? asEmail(rowData.email) : null} />
											<Column key="observacao" style={{ width: "6em" }} field="observacao" header="Observação" body={(rowData) => {
												let id = rowData.nome + "_" + Math.round(Math.random() * 1000000) + "_id";
												return (
													<div id={id} style={{ textAlign: "center" }}>
														<span className="fa fa-info" />
														<Tooltip for={`#${id}`} title={rowData.observacao} tooltipPosition="top" />
													</div>
												);
											}} />
										</TZMTable>
									</div>
								</div>
							) : null}
							<div className="ui-g-6" style={{position: "relative"}}>
								<TZMCombobox label="Endereço de Cobrança" value={JSON.stringify(this.state.pedido.cliente.enderecoCobranca)} onChange={(event) => this.handleCliente("enderecoCobranca", event.target.value)}>
									{[<option key="vazioaEndCobranca" value={null}>Nenhum endereço selecionado</option>, ...this.checkNullArray(this.state.pedido.cliente.enderecos).map((endereco) => <option key={Math.random()} value={JSON.stringify(endereco)}>{this.enderecoLiteral(endereco)}</option>)]}
								</TZMCombobox>
								{this.state.pedido.cliente.enderecoCobranca ? this.state.pedido.cliente.enderecoCobranca.pk ? <div className="tzm-tag-combobox">{this.state.pedido.cliente.enderecoCobranca.pk.tipo}</div> : null : null}
								
							</div>
							<div className="ui-g-6" style={{ position: "relative" }}>
								<TZMCombobox label="Endereço de Entrega" value={JSON.stringify(this.state.pedido.cliente.enderecoEntrega)} onChange={(event) => this.handleCliente("enderecoEntrega", event.target.value)}>
									{[<option key="vazioEndEntrega" value={null}>Nenhum endereço selecionado</option>, ...this.checkNullArray(this.state.pedido.cliente.enderecos).map((endereco) => <option key={Math.random()} value={JSON.stringify(endereco)}>{this.enderecoLiteral(endereco)}</option>)]}
								</TZMCombobox>
								{this.state.pedido.cliente.enderecoEntrega ? this.state.pedido.cliente.enderecoEntrega.pk ? <div className="tzm-tag-combobox">{this.state.pedido.cliente.enderecoEntrega.pk.tipo}</div> : null : null}
							</div>
							<div className="ui-g-4">
								<TZMAutoComplete onChange={this.queryAutoComplete} disabled onSelect={this.selectAutoComplete} field="descricao"  
												onClear={this.clearAutoComplete} suggestions={this.state.formasPagamento} name="formaPagamento"  
												completeMethod={this.listFormasPagamento} label="Forma de Pagamento" value={this.state.pedido.formaPagamento} />
							</div>
							<div className="ui-g-4">
								<TZMAutoComplete onChange={this.queryAutoComplete} disabled itemTemplate={this.condicaoPagamentoTemplate} onSelect={this.selectAutoComplete} field="descricaoAmigavel"  
												onClear={this.clearAutoComplete} suggestions={this.state.condicoesPagamento} name="condicaoPagamento" 
												completeMethod={this.listCondicoesPagamento} label="Condição de Pagamento" value={this.state.pedido.condicaoPagamento} />
							</div>
							{this.renderOperacao()}
							<div className="ui-g-4"><TZMAutoComplete itemTemplate={this.transportadoraTemplate} onClear={this.clearAutoComplete} onSelect={this.selectAutoComplete} onChange={this.queryAutoComplete} placeholder="Razão social, fantasia ou CNPJ"
													suggestions={this.state.listTransportadoras} completeMethod={this.listTransportadoras} name="transportadora" label="Transportadora" value={this.state.pedido.transportadora} field="descricaoAmigavel" /></div>
							<div className="ui-g-6"><TZMTextField disabled forac="true" label="Nome Fantasia" value={this.state.pedido.transportadora ? this.state.pedido.transportadora.nomeFantasia : null} /></div>
							<div className="ui-g-2"><TZMTextField disabled forac="true" label="CPF/CNPJ" value={this.state.pedido.transportadora ? this.state.pedido.transportadora.cpfCnpj : null} /></div>
							<div className="ui-g-2">
								<TZMCombobox  disabled={desativado} name="frete" label="Pagamento do Frete" onChange={this.handleCombobox} value={this.state.pedido.frete}>{this.pagamentosFrete}</TZMCombobox>
							</div>
							<div className="ui-g-2">
								<TZMCurrency disabled={desativado} label="Valor do Frete" className="ui-textfield-group ui-textfield" name="valorFrete" prefix="R$" value={this.state.pedido.valorFrete} onChange={this.handleCChange} />
							</div>
							<div className="ui-g-6"/>
							<div className="ui-g-2">
								<TZMCurrency label="Valor do Adiantamento" disabled={!tzm} className="ui-textfield-group ui-textfield" name="adiantamento" prefix="R$" value={this.state.pedido.adiantamento} onChange={this.handleCChange} />
							</div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Itens do Pedido</span>}>
						{ mostrarItens ?
							<div className="ui-g">
								<div className="ui-g-12">
									<TZMTable selection={this.state.selectedItemPedido} onPage={this.paginacaoItens} first={this.state.firstItem} value={this.state.pedido.itens} paginator rows={8}
									paginatorLeft={
										<div style={{ textAlign: "left" }}>
											<Button icon="fas fa-plus" disabled={!tzm} title="Adicionar novo item" className="tzm-paginator-button" onClick={this.insertItem} />
										</div> 									
									} emptyMessage="Nenhum item adicionado" footer={<div>Valor total: {toCurrency(this.state.pedido.itens.map(pi => pi.valorTotalItem).reduce((a, b) => a + b, 0))}</div>}>
										{this.renderColumnsItens()}
									</TZMTable>
								</div>
							</div>
						:
							<div className="ui-g">
								<div className="ui-selecione-cliente">
									<i className="fas fa-exclamation ui-selecione-cliente" style={{color: "#F44336"}}/>
									Para incluir itens ao pedido é necessário infomar os seguintes campos:
									<div className="ui-g">
										<span className="ui-selecione-cliente-itens">{!Validator.isEntidade(this.state.pedido.cliente) ? ' - Cliente' : null }</span>
										<span className="ui-selecione-cliente-itens">{!Validator.isEntidade(this.state.pedido.operacao) ? ' - Operação' : null }</span>
										<span className="ui-selecione-cliente-itens">{!this.state.pedido.frete ? '- Pagamento do Frete' : null }</span>
										<span className="ui-selecione-cliente-itens">{!Validator.isEntidade(this.state.pedido.vendedor) ? '- Vendedor' : null }</span>
									</div>
								</div>

							</div>
						}
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Textos</span>}>
						<div className="ui-g">
							<div className="ui-g-6">
								<TZMTextArea name="observacao" field="observacao" onChange={(event) => {
								let pedido = this.state.pedido;								
								pedido.observacao = event.target.value.replace(/(^\s+|\s+$)/g, ' ');
								this.setState({ pedido });
							}} maxLength="254" style={{ height: "110px" }} label="Observação (Uso Interno)" value={this.state.pedido.observacao} /></div>
							<div className="ui-g-6"><TZMTextArea name="observacaoFiscal" field="observacaoFiscal" onChange={(event) => {
								let pedido = this.state.pedido;								
								pedido.observacaoFiscal = event.target.value.replace(/(^\s+|\s+$)/g, ' ');
								this.setState({ pedido });
							}} maxLength="4000" style={{ height: "110px" }} label="Observação da Nota Fiscal" value={this.state.pedido.observacaoFiscal} /></div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel headerStyle={{ display }} header={<span><span className="ui-tab-number" children="4" />Equipe de Vendas</span>}>
						<div className="ui-g">
							<div className="ui-g-1"><TZMTextField disabled forac="true" value={this.state.pedido.vendedor?.posicao} label="Situação" /></div>
							<div className="ui-g-5">
								<span className="ui-fluid ui-g-nopad">
									<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmegaPK} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
									                 suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel"
									                 label="Vendedor" placeholder="Código, E-mail ou Nome" onUnselect={this.handleUnselect} 
													 disabled={desativado} value={this.state.pedido.vendedor}/>
								</span>
							</div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel headerStyle={{ display: exportacao }} header={<span><span className="ui-tab-number" children="5" />Exportação</span>}>
						<div className="ui-g">
							<div className="ui-g-4"><TZMTextField maxLength={35} onChange={(event) => this.handleUpdate("localidadeEmbarque", event)} label="Local de Embarque" value={this.state.pedido.localidadeEmbarque} /></div>
							<div className="ui-g-1"><TZMCombobox maxLength={2} onChange={this.handleCombobox} children={UF.list} name="ufEmbarque" label="UF de Embarque" value={this.state.pedido.ufEmbarque} /></div>
							<div className="ui-g-3"><TZMTextField maxLength={35} onChange={(event) => this.handleUpdate("ordemVenda", event)} label="Ordem de Venda" value={this.state.pedido.ordemVenda} /></div>
							{/* <div className="ui-g-4"><TZMCombobox label="Índice da Moeda de Exportação" onChange={this.handleEntityCombobox} children={this.state.indices} name="indice" value={JSON.stringify(this.state.pedido.indice)} /></div> */}
						</div>
					</TZMTabPanel>
					<TZMTabPanel headerStyle={{ display: adiantamento }} header={<span><span className="ui-tab-number" children="6" />Comprovante de Adiantamento</span>}>
						<div className="ui-g">
							<div className="ui-g-6" style={{ position: "relative" }}>
								<label>Imagem do Comprovante</label>
								<img className="ui-image-load" src={this.state.pedido.arquivo?.conteudo || `${basePath}/public/arquivos/${this.state.pedido.arquivo?.nome}`} alt="" />
								<input className="ui-file-load" ref={this.fileInputRef} type="file" onChange={this.loadImage} />
							</div>
							<div className="ui-g-6">
								<div className="ui-g">
									<div className="ui-g-8 ui-g-nopad"><TZMTextField label="Email do Contato" onChange={(event) => this.handleUpdate("emailAdiantamento", event)} value={this.state.pedido.emailAdiantamento} /></div>
									<div className="ui-g-4 ui-g-nopad">{this.statusEnvioEmail()}</div>
									<div className="ui-g-12 ui-g-nopad" style={{ marginTop: "7px" }}><TZMTextArea onChange={(event) => this.handleUpdate("textoAdiantamento", event)} value={this.state.pedido.textoAdiantamento} style={{ height: "360px" }} label="Observações do Adiantamento" /></div>
								</div>
							</div>
						</div>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>
					{this.state.editavel ? <TZMButton className="ui-button-success" style={{ float: "left" }} label="Confirmar" icon="fas fa-check-circle" onClick={this.confirmarPedido} /> : null}
					{this.state.editavel ? <TZMButton className="ui-button-primary" label="Salvar" icon="fas fa-save" onClick={this.salvarPedido} /> : null}
					{/* {!this.state.editavel && (this.state.pedido.motivosBloqueio != null && this.state.pedido.motivosBloqueio.length > 0) ? <TZMButton className="ui-button-warning" icon="fas fa-shield-alt" style={{ float: "left" }} label="Bloqueios" onClick={ ()  => this.motivoBloqueio(this.state.pedido)} /> : null} */}
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fecharPedido} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible}
					onCtrlS={() => !this.state.editavel ? this.salvarPedido() : null}
					onEscape={this.fecharPedido}
					onInsert={this.insertItem}
					onAlt1={() => this.selectTabIndex(0)}
					onAlt2={() => this.selectTabIndex(1)}
					onAlt3={() => this.selectTabIndex(2)}
					onAlt4={() => this.selectTabIndex(3)}
					onAlt5={() => this.selectTabIndex(4)}
					onAlt6={() => this.selectTabIndex(5)}
				/>
			</TZMDialog>
		);
	}

}
