import { httpGet, httpGetAsync , httpDelete, httpDeleteAll} from "../../utilities/TZMRequest";
import { Util } from "../../utilities/Util";

export class VwPedidoService {

	completar(pk) {
		return httpGet(`/vwpedidos/${JSON.stringify(pk)}`);
	} 

	listarSimples(params) {			
		switch (params.status) {
			case "ERRO":
			case "CANCELADO":
			case "RASCUNHO":
			case "NAO_PROCESSADO":
				return Util.emptyPromise([]);		
			default:
				return httpGet("/vwpedidos/listarSimples/", { ...params, sort: "numeroPedido desc" });
		}		
	}

	listarPedidosAtrasados(params) {			
		return httpGet("/vwpedidos/listarPedidosAtrasados/", { ...params, sort: "numeroPedido desc" });
	}

	detalhar(pk) {
		return httpGet(`/vwpedidos/detalhe/${JSON.stringify(pk)}`);
	}
	
	quantidadeNfs = async (pedidoId) => {
        return httpGetAsync("/vw-notas-fiscais/quantidade-nfs", {pedidoId}, false);
    }

	cancelar(pk) {
		return httpDelete(`/vwpedidos/cancelar`, pk);
	}
	cancelarEmLote(pks) {
		return httpDeleteAll(`/vwpedidos/cancelar`, pks);
	}
}
