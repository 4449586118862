import React from "react";
import moment from "moment";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { Column } from 'primereact/components/column/Column';
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";
import { ProdutoService } from "../../servicos/ProdutoService";
import { FamiliaService } from "../../servicos/FamiliaService";
import { EditarProduto } from "./EditarProduto";

const title = "Produtos";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Produto extends React.Component {
	
	constructor(props) {		
		super();        
		moment.locale('pt-BR');
		this.state = {
			desativados: false,
			produto: {
				descricao: "",
				listSegmentoItens: null
			},
			segmentoItens: []
		}
	}

	produtoService = new ProdutoService();
	familiaService = new FamiliaService();

	listarProdutos = () => {		
		let params = Object.assign({}, this.state.produto);		
		params.sort = "descricao";
		if (params.familia) {
			params.familia = params.familia.id;
		}
		this.produtoService.listarSimples(params).then((produtos) => this.setState({produtos}));
	}

	handleRefresh = () => {
		this.produtoService.atualizar();
	}

	editarProduto = (event) => {
		this.setState({selectedProduto: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.produtoService.completar(event.data).then((produto) => {
					ModalRealm.showDialog(<EditarProduto key={TZMUserData.getIdGenerator()} produto={produto}  onModalClose={this.listarProdutos}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarProduto key={TZMUserData.getIdGenerator()} produto={event.data} onModalClose={this.listarProdutos}  />);
			}
		}
	}

	handleUpdate = (event) => {
		let produto = this.state.produto;
		produto[event.name] = event.target.value;
		this.setState({produto});
	}

	criarProduto = () => {
		this.setState({selectedProduto: null});
		this.editarProduto({data: {descricao: ""}});
	}

	excluirProduto = (produto) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.produtoService.excluir(produto).then(this.listarProdutos);
		}} question="Tem certeza de que deseja excluir este produto?" />);
	}

	listarFamilia = async (event) => {		
		await this.familiaService.autoCompletarFamiliaDummy({descricao: event.query, sort:'descricao'}).then((listFamilias) => this.setState({listFamilias}));
	}
    
	handleClear = (event) => {
		let produto = this.state.produto;
		produto[event.name] = null;
		this.setState({produto});
	}

	handleSelect = (event) => {		
		let produto = this.state.produto;
		produto[event.name] = event.value;
		this.setState({produto});
	}

	handleUnselect = (event) => {
		let produto = this.state.produto;
		if (!produto[event.name]) {
			produto[event.name] = [];
		}
		produto[event.name] = produto[event.name].filter((e) => e.id !== event.value.id);
		this.setState({produto});
	}

	integradoEm = (rowData) => { return (<div>{moment(rowData.registro).format("DD/MM/YYYY HH:mm:ss")}</div>);}
	
	columns = [
		<Column header="Integrado Em" sortable key="registro" field="registro" name="registro" style={{width: "15em", textAlign: "center"}} body={this.integradoEm} />,		
		<Column header="SKU" key="produtoId" field="vwProduto.pk.produtoId" style={{width: "15em"}} />,
		<Column header="Família" key="familia" field="familiaDescricao"  style={{width: "17em", textAlign: "center"}} />,
		<Column sortable header="Descrição" key="descricao" field="vwProduto.descricao" style={{width: "*"}} />,
		<Column header="Fator Unitário" key="fatorUnitario" field="vwProduto.fatorUnitario" style={{width: "10em", textAlign: "right"}} />,
		<Column sortable header="Unidade" key="unidade" field="vwProduto.unidade" style={{width: "10em", textAlign: "center"}} />,
		<Column  header="Saldo Disponível" key="saldo" field="vwProduto.saldoDisponivel" style={{width: "10em", textAlign: "center"}} />,
		<Column  header="Saldo Estoque" key="saldo" field="vwProduto.saldoEstoque" style={{width: "10em", textAlign: "center"}} />,
		<Column  header="Saldo Pedido" key="saldo" field="vwProduto.saldoPedido" style={{width: "10em", textAlign: "center"}} />,
		<Column sortable header="Grupo" key="grupoId" field="vwProduto.grupoId" style={{width: "10em", textAlign: "center"}} />,		
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(produto) => { 
		return (
				<div>
					{TZMUserData.hasRole("____PROD_E") ? <span title="Editar Produto" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarProduto({data: produto})} /> : null}
					{TZMUserData.hasRole("____PROD_D") ? <span title="Remover Produto" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirProduto(produto)} /> : null}
				</div>
			);
		}} />
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-6">
										<TZMTextField name="descricao" autoFocus onChange={this.handleUpdate} label="Descrição" placeholder="Descrição, SKU" value={this.state.produto.descricao} />
									</div>					
									<div className="ui-g-6 ui-fluid">									
										<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
											suggestions={this.state.listFamilias} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
											label="Família" placeholder="Descrição" onUnselect={this.handleUnselect} value={this.state.produto.familia} />
									</div>
								</div>
							</TZMForm>
							<TZMPanelFooter >
								<TZMButton title={this.state.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.alternarOcultos} secondary icon={this.state.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.isAdministrador() ? 
								<TZMButton icon="fas fa-sync" label="Atualizar" className="ui-button-secondary"  onClick={this.handleRefresh}  />
								: null}								
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarProdutos} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.produtos} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
	
}
