import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {ProdutoService} from "../../servicos/ProdutoService";
import {VwProdutoService} from "../../servicos/VwProdutoService";
import {FamiliaService} from "../../servicos/FamiliaService";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import {LazyImage} from "../../../components/common/LazyImage";

export class EditarProduto extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
            produto: props.produto,
            params: {
                produto: { pk: {}, descricao: "" }
            }
		};
        this.produtoService = new ProdutoService();		
		this.vwProdutoService = new VwProdutoService();
		this.familiaService = new FamiliaService();
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		let produto = this.state.produto;
		produto[event.name] = event.target.value;
		this.setState({produto});
	}

	salvarProduto = () => {
		let produto = Object.assign({}, this.state.produto);
		this.produtoService.salvar(produto).then((produto) => {
			if (this.props.onModalClose) this.props.onModalClose(produto);
			this.onHide();
		});
	}
	
    produtoTemplate = (value) => {
		return (
			<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>
		);
    }

    visualizarImagem = (src) => {		
		const image = new Image();
		image.style.height = "100%";
		image.src = src;
		const newTab = window.open("");
		newTab.document.write(image.outerHTML);
	}

    render() {
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Produto" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<TZMTabView>
						<TZMTabPanel header="Dados Principais">
							<div className="ui-g">
								<TZMTextField col={6} label="SKU" disabled name="produtoId" value={this.state.produto.vwProduto.pk.produtoId}  />
								<TZMTextField col={6} label="Descrição" disabled name="descricao" value={this.state.produto.vwProduto.descricao}  />
								<TZMTextField col={2} label="Fator Unitário" disabled name="fatorUnitario" value={this.state.produto.vwProduto.fatorUnitario} />
								<TZMTextField col={2} label="Unidade" disabled name="unidade" value={this.state.produto.vwProduto.unidade}  />
								<TZMTextField col={2} label="Grupo" disabled name="grupoId" value={this.state.produto.vwProduto.grupoId}  />
								<TZMTextField col={6} label="Família" disabled name="familia" value={this.state.produto.vwProduto.familia?.descricao}  />
								<TZMTextField col={2} label="Saldo Disponível" disabled name="saldoDisponivel" value={this.state.produto.vwProduto.saldoDisponivel}  />
								<TZMTextField col={2} label="Saldo Pedido" disabled name="saldoPedido" value={this.state.produto.vwProduto.saldoPedido}  />
								<TZMTextField col={2} label="Saldo Estoque" disabled name="saldoEstoque" value={this.state.produto.vwProduto.saldoEstoque}  />
							</div>
						</TZMTabPanel>
						<TZMTabPanel header="Ficha Técnica">
							<div className="ui-g">
								<div className="ui-g-6">
									<LazyImage onClick={this.visualizarImagem} style={{cursor: "pointer", width: "100%", height: "594px"}} 
												key={this.state.produto.vwProduto.imagem} src={this.state.produto.vwProduto.imagem}/>
								</div>
								<div className="ui-g-6">
									<div className="ui-g">
										<TZMTextField col={12} label="Altura" value={this.state.produto.vwProduto.alturaEtiqueta} disabled/>
										<TZMTextField col={12} label="Largura" value={this.state.produto.vwProduto.larguraEtiqueta} disabled/>
										<TZMTextField col={12} label="Comprimento" value={this.state.produto.vwProduto.comprimentoRolo} disabled/>
										<TZMTextField col={12} label="Sentido Rebobinagem" value={this.state.produto.vwProduto.sentidoRebobinagem} disabled/>
										<TZMTextField col={12} label="Diâmetro Tubete" value={this.state.produto.vwProduto.diametroTubete} disabled/>
										<TZMTextField col={3}  label="Código Material" value={this.state.produto.vwProduto?.materiaPrima?.id} disabled/>
										<TZMTextField col={9}  label="Material" value={this.state.produto.vwProduto?.materiaPrima?.descricao} disabled/>
										<TZMTextField col={12} label="Nome do arquivo" value={this.state.produto.vwProduto.imagem} disabled/>
									</div>
								</div>
							</div>
						</TZMTabPanel>
					</TZMTabView>
				</TZMPanel>
				<TZMPanelFooter>
					{/* <TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarProduto} /> */}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarProduto} onEscape={this.onHide}/>
			</TZMDialog>
		);
	}

}
