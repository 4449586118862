import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {ComboboxOperacao} from "../../../components/special/ComboboxOperacao";
import TZMUserData from "../../../utilities/TZMUserData";
import { toCurrency, toDecimal } from "../../../components/common/TZMFormatter";
import TZMTable from "../../../components/table/TZMTable";
import { Column } from "primereact/components/column/Column";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMTextField from "../../../components/common/TZMTextField";

export class EditarItemPedido extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
			itemPedido: props.itemPedido,
			sistema: props.sistema,
			operacao: props.operacao
		};
		
	}
	onHide = () => this.setState({ visible: false });

	mountEnumOptions = (enumList) => {
		if (enumList != null) {
			let enums = [<option key={0} value={null}></option>];
			enums.push(...enumList.map((value) => <option key={value.value} value={value.value}>{value.descriptor}</option>));
			return enums;
		}
		return null;
	}

	cfopTemplate(cfop) {
		return `${cfop.pk.cfopId} - ${cfop.descricao}`;
	}

	onSalvar = (event) => {
		let itemPedido = this.state.itemPedido;
		if (this.validarItemPedido(itemPedido)) {
			if (this.props.onSalvar) this.props.onSalvar(itemPedido);
			this.onHide();
		}
	}

	handleCombobox = (event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[event.name] = event.target.value;
		this.setState({ itemPedido, alterado: true });
	}

	selectAutoComplete = (event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[event.name] = event.value;
		this.setState({ itemPedido, alterado: true });
	}

	clearAutoComplete = (event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[event.name] = { pk: {}, [event.field]: " " };
		this.setState({ itemPedido, alterado: true });
	}

	queryAutoComplete = (event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[event.name] = event.value;
		this.setState({ itemPedido, alterado: true });
	}

	validarItemPedido = (itemPedido) => {
		let messages = [];
		// if (!Validator.isEntidade(itemPedido.operacao)) {
		// 	messages.push("A operação é obrigatória");
		// }
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	revenda = () => {		
		if (this.state.itemPedido.produto) {
			let descricao = this.state.itemPedido.produto.pk.produtoId.toUpperCase();
			let usage = this.state.operacao.usage.toUpperCase()
			if (usage.includes('REVENDA') &&
				(descricao.startsWith('S') || descricao.startsWith('R'))) {
					return true;
				}
		}
		return false;
	}



	render() {
		const enable = this.state.sistema === TZMUserData.TZM() ? true : false;
		let revenda = this.revenda();
		return (
			<TZMDialog style={{ width: "900px" }} visible={this.state.visible} modal header="Detalhes de Item de Pedido" onHide={this.onHide} {...this.props}>
				<div className="ui-g">
				{enable &&  this.state.itemPedido.logSimulacao ? 
						<TZMPanel header="Preços">
							<div className="ui-g">
								<TZMTable value={this.state.itemPedido?.logSimulacao.precos} >
									<Column key="unidade" style={{ textAlign: "center", width: "5em" }} field="unidade" header="Unidade" />
									<Column key="valorUnitario" style={{ textAlign: "right", width: "8em" }} field="valorUnitario" header="Valor Portal"  body={(rowData) => toCurrency(rowData.valorUnitario)}/>
									<Column key="valorUnitarioSap" style={{ textAlign: "right", width: "8em" }} field="valorUnitarioSap" header="Valor SAP"  body={(rowData) => toCurrency(rowData.valorUnitarioSap)}/>
									<Column key="fatorConversaoSap" style={{ textAlign: "right", width: "5em" }} field="fatorConversaoSap" header="Fator Conversão SAP"  body={(rowData) => toDecimal(rowData.fatorConversaoSap)}/>
									<Column key="fatorConversao" style={{ textAlign: "right", width: "5em" }} field="fatorConversao" header="Fator Conversão Portal" body={(rowData) => toDecimal(rowData.fatorConversao)}/>
									<Column key="descricao" style={{ textAlign: "left", width: "*" }} field="descricao" header="Conversão" />
								</TZMTable>
							</div>
						</TZMPanel> : null}
					
					<div className="ui-g-12">
						<TZMPanel header="Operação">
							<div className="ui-g">
								<div className="ui-g-12">
									<ComboboxOperacao operacoes={this.props.operacoes} disabled={revenda} label="Operação" name="operacao" value={this.state.itemPedido.operacao} onChange={this.handleCombobox}/>
								</div>
									{!enable ?
										<div className="ui-g-12">
											<TZMAutoComplete minLength={1} onClear={this.clearAutoComplete} disabled onSelect={this.selectAutoComplete} itemTemplate={this.cfopTemplate}
												onChange={this.queryAutoComplete} suggestions={this.state.listCfops} field="descricaoAmigavel" name="cfop" completeMethod={this.listCfops}
												label="CFOP" value={this.state.itemPedido.cfop} />
									</div> : null}
								<div className="ui-g-6">
									<TZMTextField label="Ordem Compra (xPed)"  maxLength="20" name="ordemCompra" value={this.state.itemPedido.ordemCompra} onChange={this.selectAutoComplete} />
								</div>
								<div className="ui-g-6">
									<TZMTextField label="Item Ordem Compra (xNPed)"  maxLength="6" name="itemOrdemCompra" value={this.state.itemPedido.itemOrdemCompra} onChange={this.selectAutoComplete} />
								</div>								

							</div>
						</TZMPanel>
					 </div> 
				</div>
				<TZMPanelFooter>				
				{this.state.sistema === TZMUserData.TZM() ? <TZMButton className="ui-button-success" label="Salvar" icon="fas fa-save" onClick={this.onSalvar} /> : null}
				<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.onSalvar} onEscape={this.onHide} />
			</TZMDialog>
		);
	}

}
