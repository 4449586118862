import React from "react";
import { SimuladorService } from "../servicos/SimuladorService";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import { Column } from "primereact/components/column/Column";
import TZMUserData from "../../../utilities/TZMUserData";
import { VwMateriaPrimaService } from "../../servicos/VwMateriaPrimaService";
import { Validator } from "../../../utilities/TZMValidator";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import { Button } from "primereact/components/button/Button";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMPanel from "../../../components/panel/TZMPanel";
import { TZMSimulador } from "../../../components/common/TZMSimulador";
import {  toPercent } from "../../../components/common/TZMFormatter";
import { DataTable } from "primereact/components/datatable/DataTable";
import { UsuarioService } from "../../servicos/UsuarioService";
import UsuarioTemplate from "../../comum/usuario/UsuarioTemplate";
import { FamiliaService } from "../../servicos/FamiliaService";

const title = "Parâmetros Simulador";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Simulador extends React.Component {

	constructor() {
		super();
		this.state = {
			simulador:{
				email:{
					assunto: "",
					destinatarios:[]					
				}
				
			},
			simulacao:{
				espacamentoLateral:"0",
				ggf:"0",
				perdas:"0",
				embalagem:"0",
				comissao:"0",
				frete:"0",
				largura:"0",
				comprimento:"0",
				custoMedio:"0",
				mc:"0",
				pisCofins:"0",
				icms:"0",
				familia:null,
				pagamentoFrete: 'C',
				premissaProduto: null
			}		
		}
		this.handleList();
	}
	
	simuladorService = new SimuladorService();
	materiaPrimaService = new VwMateriaPrimaService();
	usuarioService = new  UsuarioService();
	familiaService = new FamiliaService();
    
    handleList = () => {
		this.simuladorService.carregarSimulador().then((simulador) => {
			let simulacao = this.state.simulacao;
			simulacao.espacamentoLateral = simulador.espacamentoLateral;
			simulacao.embalagem = simulador.embalagem;
			simulacao.pisCofins = simulador.pisCofins;
			this.setState({simulador,simulacao})
		});
	}

	salvar = () => {
		if (this.validar()) {
			let simulador = Object.assign({}, this.state.simulador);
			this.simuladorService.salvar(simulador).then(() => {
				this.simuladorService.carregarSimulador().then((response) => {this.setState({simulador:response})} );
			});
		}
	}
	
	simular = () => {
		let simulacao = Object.assign({}, this.state.simulacao);
		let premissasProduto = this.state.simulador.premissasProduto;			
		let premissaProduto =  premissasProduto.filter(premissa => premissa.produto.pk.produtoId === simulacao.premissaProduto)[0];		
		simulacao.mc = premissaProduto.mc;
		simulacao.custoMedio = premissaProduto.custoMedio;
		this.simuladorService.simular(simulacao).then((response) => {
			this.setState({simulacao: response})
		});
	}
	
	listarFamilia = async (event) => {
		let remover = this.state.simulador.familias;	
		await this.familiaService.autoCompletarFamilia({descricao: event.query, sort:'descricao', notin: remover.map((familia) => familia.id)})
												.then((listFamilias) => this.setState({listFamilias}));
	}
    
    validar = () => {
		let simulador = this.state.simulador;
		let messages = [];

		if (simulador.premissasProduto == null || simulador.premissasProduto.length === 0) {
			messages.push("Nenhuma matéria prima.");
		} else {
			simulador.premissasProduto.forEach((premissa, index) => {
				if (!Validator.isEntidade(premissa.materiaPrima)) {
					messages.push("Existem matérias primas sem produto preenchido");
				}
				if (!premissa.custoMedio || premissa.custoMedio === 0) {
					messages.push("Custo médio da premissa deve ser maior que zero.");
				}
				if (!premissa.mc || premissa.mc === 0) {
					messages.push("MC da premissa deve ser maior que zero.");
				}
				if (Validator.isEntidade(premissa.materiaPrima) && simulador.premissasProduto.findIndex( 
							i => i.materiaPrima.id === premissa.materiaPrima.id ) !== index) {
					messages.push(`Premissa com a matéria prima ${premissa.materiaPrima.descricao} está duplicada.`);
				}
			});
		}
		if (simulador.email.destinatarios == null || simulador.email.destinatarios.length === 0) {
			messages.push("Nenhum destinatário informado para envio de e-mail.");
		}
		if (!simulador.email.assunto || !simulador.email.assunto.length) {
			messages.push("O assunto do e-mail é obrigatório.");
		}
		simulador.estados.forEach((estado) => {
			if (!estado.frete || estado.frete === 0) {
				messages.push(`O % do frete para o estado ${estado.nome} deve ser maior que zero.`);
			}
		});
		
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}
	
	handleUpdateSimulacao = (key, event) => {
		let simulacao = this.state.simulacao;
		simulacao[key] = event.target.value;
		this.setState({ simulacao });
	}
    
    handleUpdate = (key, event) => {
		let simulador = this.state.simulador;
		simulador[key] = event.target.value;
		this.setState({ simulador, alterado: true });
	}	

	handleUpdateAssunto = (event) => {
		let simulador = this.state.simulador;
		simulador.email.assunto = event.value;
		this.setState({simulador, alterado: true});
	}

	handleCChange = (event) => {
		let simulador = this.state.simulador;
		simulador[event.name] = event.value;
		this.setState({ simulador, alterado: true });
	}

	handleClear = (event) => {
		let simulador = this.state.simulador;
		simulador[event.name] = null;
		this.setState({simulador});
	}

	produtoTemplate(materiaPrima) {
		return `${materiaPrima.id} - ${materiaPrima.descricao}`;
	}

	blurOnEnter = (event) => {
		if (event.keyCode === 9 || event.keyCode === 13) document.activeElement.blur();
	}

	listarMateriasPrima = (event) => {
		this.materiaPrimaService.listar(event.query).then((materiasPrimas) => { this.setState({ materiasPrimas });});
	}

	handleUpdateItem = (key, index, value) => {		
		let simulador = this.state.simulador;
		simulador.premissasProduto[index][key] = value;
		this.setState({ simulador, alterado: true });
	}

	handleUpdateEstado = (key, index, value) => {		
		let simulador = this.state.simulador;
		simulador.estados[index][key] = value;
		this.setState({ simulador, alterado: true });
	}

	editorProduto = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMAutoComplete inputClassName="editorProduto" minLength={1} field="descricao" itemTemplate={this.produtoTemplate} onKeyDown={this.blurOnEnter} 
					value={this.state.simulador.premissasProduto[props.rowIndex].materiaPrima} onChange={(event) => {
					let simulador = this.state.simulador;
					simulador.premissasProduto[props.rowIndex].materiaPrima = event.value;
					this.setState({ simulador });
				}} suggestions={this.state.materiasPrimas} completeMethod={this.listarMateriasPrima} onSelect={(event) => {
					if (event.value && event.value.pk) {
						this.handleUpdateItem("materiaPrima", props.rowIndex, event.value);
					}
				}} />
			</div>
		);
	}
  
	paginacaoItens = (event) => {
		this.setState({ firstItem: event.first });
	}
	
	editorCustoMedio = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMCurrency className="ui-textfield-currency-group ui-textfield" name="custoMedio" prefix="R$" value={this.state.simulador.premissasProduto[props.rowIndex].custoMedio} onChange={(event) => this.handleUpdateItem("custoMedio", props.rowIndex, event.value)} />
			</div>
		);
	}

	editorMC = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMTextField min={0} style={{textAlign: "center"}} type="number"  value={this.state.simulador.premissasProduto[props.rowIndex].mc.toString()} 
				onChange={(event) => this.handleUpdateItem("mc", props.rowIndex, event.target.value)} />
			</div>
		);
	}

	editorFrete = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMTextField min={0} style={{textAlign: "center"}} type="number"  value={this.state.simulador.estados[props.rowIndex].frete.toString()} 
				onChange={(event) => this.handleUpdateEstado("frete", props.rowIndex, event.target.value)} />
			</div>
		);
	}

	editorIcms = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMTextField min={0} style={{textAlign: "center"}} type="number"  value={this.state.simulador.estados[props.rowIndex].percentualIcms.toString()} 
				onChange={(event) => this.handleUpdateEstado("percentualIcms", props.rowIndex, event.target.value)} />
			</div>
		);
	}
	
	sku = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				{this.state.simulador.premissasProduto[props.rowIndex].materiaPrima.id ? this.state.simulador.premissasProduto[props.rowIndex].materiaPrima.id : null}				
			</div>
		);
	}

	excluirPremissa = (premissaProduto) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let simulador = this.state.simulador;
		simulador.premissasProduto =  simulador.premissasProduto.filter(premissa => premissa !== premissaProduto);
		this.setState({simulador});
		}} question="Tem certeza de que deseja remover está matéria prima?" />);
	}

	adicionarPremissa = (material) => {
		let simulador = this.state.simulador;
		simulador.materiais =  simulador.materiasPrimas.filter(premissa => premissa !== material);
		simulador.premissasProduto.unshift({ materiaPrima: { id: material.id, descricao: material.descricao }, mc: 0, custoMedio: 0});						
		setTimeout(() => {
			document.getElementsByClassName("editorProduto")[0].focus();
		}, 200);	
		this.setState({simulador, selectedTab: 1});
	}

	columns = [
		<Column key="sku" field="sku" name="sku" body={(this.sku)} style={{ width: "10em", textAlign: "center" }} header="SKU" />,
		<Column key="descricao" body={this.editorProduto} style={{ width: "*" }} header="Matéria Prima" />,
		<Column key="custoMedio" style={{ width: "8em" }} name="custoMedio" field="custoMedio" header="Custo Médio EPPM" body={this.editorCustoMedio} />,
		<Column key="mc" style={{ width: "8em" }} name="mc" field="mc" header="% MC" body={this.editorMC} />,
		<Column key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(premissaProduto) => {
			return (
				<div>
					<span title="Remover Matéria Prima" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirPremissa(premissaProduto)} />
				</div>
			);
		}} />
	];

	columnsMateriais = [
		<Column key="sku" field="id"  style={{ width: "10em", textAlign: "center" }} header="SKU" />,
		<Column key="descricao" field="descricao"  style={{ width: "*" }} header="Produto" />,
		<Column key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(material) => {
			return (
				<div>
					<span title="Incluir nas premissas atuais" className="fa fas fa-plus ui-tzm-icon" onClick={() => this.adicionarPremissa(material)} />
				</div>
			);
		}} />
	];

	toPercent(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field])}</div>
	}
	
	columnsEstado = [		
		<Column key="nome" field="nome" header="Estado" name="nome" style={{ width: "*"}}/>,
		<Column key="uf" field="uf" header="UF" name="uf" style={{ width: "15em", textAlign: "center" }} />,
		<Column key="icms" field="percentualIcms" header="% ICMS" name="icms"  style={{ width: "15em", textAlign: "center" }} body={this.editorIcms}/>,
		<Column key="frete" style={{ width: "15em" }} name="frete" field="frete" header="% Frete" body={this.editorFrete} />
	];

	insertItem = () => {
		switch (this.state.selectedTab) {
			case 1:
				let simulador = this.state.simulador;
				if (!simulador.premissasProduto) {
					simulador.premissasProduto = [];
				}
				simulador.premissasProduto.unshift({  materiaPrima: { id: null, descricao: " "}, mc: 0, custoMedio: 0});				
				this.setState({ simulador, alterado: true });				
				setTimeout(() => {
					document.getElementsByClassName("editorProduto")[0].focus();
				}, 200);				
				break;
			default:
		}
	}	

    addDestinatario = (event) => {
		let simulador = this.state.simulador;
		simulador.email.destinatarios.unshift(event.value);
		simulador.destinatario = null;
		this.setState({simulador});
	}

    addFamilia = (event) => {
		let simulador = this.state.simulador;
		simulador.familias.unshift(event.value);
		simulador.familia = null;
		this.setState({simulador});
	}

	excluirFamilia = (familia) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let simulador = this.state.simulador;
		simulador.familias =  simulador.familias.filter(f => f !== familia);
		this.setState({simulador});
		}} question="Tem certeza de que deseja remover esta família?" />);
	}

	handleSelect = (event) => {
		let simulador = this.state.simulador;
		simulador[event.name] = event.value;
		this.setState({simulador});
	}
	
	handleUnselect = (event) => {
		let simulador = this.state.simulador;
		if (!simulador[event.name]) {
			simulador[event.name] = [];
		}
		simulador[event.name] = simulador[event.name].filter((e) => e.id !== event.value.id);
		this.setState({simulador});
	}

	listarUsuarios = async (event) => {
		let remover = this.state.simulador.email.destinatarios;
		await this.usuarioService.autoCompletar({usuario: event.query,notin:remover.map((usuario) => usuario.id), sort:'nome'}).then((destinatarios) => this.setState({destinatarios}));
	}

	columnsDestinarios = [				
		<Column key="nome" sortable header="Nome" field="nome" style={{width: "*"}} />,
		<Column key="email" sortable header="E-mail" field="email" style={{width: "*"}} />,
		<Column key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(usuario) => {
			return (
				<div>
					<span title="Remover Destinatário" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirDestinatario(usuario)} />
				</div>
			);
		}} />
	];

	columnsFamilias = [		
		<Column key="descricao" header="Descrição"  field="descricao" name="descricao"  style={{width: "10em", textAlign: "center"}} />,
		<Column key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(familia) => {
			return (
				<div>
					<span title="Remover Família" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirFamilia(familia)} />
				</div>
			);
		}} />		
	];	

	excluirDestinatario = (usuario) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let simulador = this.state.simulador;
		simulador.email.destinatarios =  simulador.email.destinatarios.filter(v => v !== usuario);
		this.setState({simulador});
		}} question="Tem certeza de que deseja remover este destinatário?" />);
	}
	
    render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-8">
					<TZMPanel header="Parâmetros Simulador">	
							<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
								<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
									<div className="ui-g">
										<div className="ui-g-3">
											<TZMTextField type="number" onChange={(event) => this.handleUpdate("espacamentoLateral", event)} label="Espaçamento Lateral (mm)" value={this.state.simulador.espacamentoLateral} />
										</div>
										<div className="ui-g-3">
											<TZMTextField type="number" onChange={(event) => this.handleUpdate("espacamentoVertical", event)} label="Espaçamento Vertical (mm)" value={this.state.simulador.espacamentoVertical} />
										</div>
										<div className="ui-g-3">
											<TZMCurrency label="Embalagem (R$/RL)" name="embalagem" prefix="R$" value={this.state.simulador.embalagem} onChange={this.handleCChange} />
										</div>
										<div className="ui-g-3">
											<TZMCurrency label="Preço clichê (R$/clichê)" name="precoCliche" prefix="R$" value={this.state.simulador.precoCliche} onChange={this.handleCChange} />
										</div>
									</div>
									<div className="ui-g"/>
									<TZMPanel header="Famílias">
										<div className="ui-g">											
											<div className="ui-g-12 ui-fluid">									
												<TZMAutoComplete  onClear={this.handleClear} onSelect={this.addFamilia} onChange={this.handleSelect}
													suggestions={this.state.listFamilias} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
													label="Família" placeholder="Descrição" onUnselect={this.handleUnselect} value={this.state.simulador.familia} />
											</div>											
										</div>
										<TZMTable ref={(el) => this.dt = el} value={this.state.simulador.familias} > 
												{this.columnsFamilias} 
											</TZMTable>
									</TZMPanel>
								</TZMTabPanel>
								<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Matéria Prima</span>}>
									<div className="ui-g">							
										<div className="ui-g-12">
											<TZMTable emptyMessage="Nenhum registro encontrado"  onPage={this.paginacaoItens}  value={this.state.simulador.premissasProduto}  paginator rows={20} 
											paginatorLeft={
													<div style={{ textAlign: "left" }}>
														<Button icon="fas fa-plus" title="Adicionar nova matéria prima" className="tzm-paginator-button" onClick={this.insertItem} />
													</div> } >
												{this.columns}
											</TZMTable>
										</div>
									</div>
								</TZMTabPanel>
								<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Estado ICMS/Frete) </span>}>
									<TZMTable  value={this.state.simulador.estados} >
										{this.columnsEstado}
									</TZMTable>
								</TZMTabPanel> 
								<TZMTabPanel header={<span><span className="ui-tab-number" children="4" />Premissas S/ Parâmetros </span>}>
									<DataTable value={this.state.simulador.materiasPrimas}  >
										{this.columnsMateriais}
									</DataTable>
								</TZMTabPanel>
								<TZMTabPanel header={<span><span className="ui-tab-number" children="5" />E-mail</span>}>									
									<div className="ui-g">
										<div className="ui-g-12">											
											<TZMTextField  name="assunto" value={this.state.simulador?.email?.assunto} label="Descrição" onChange={this.handleUpdateAssunto}/>
										</div>
										<TZMPanel header="Destinatários">
											<div className="ui-g-12">
												<TZMAutoComplete onClear={this.handleClear} itemTemplate={UsuarioTemplate.usuarioTemplate} onSelect={this.addDestinatario} onChange={this.handleSelect} 
																suggestions={this.state.destinatarios} completeMethod={this.listarUsuarios} placeholder="E-mail, Login ou Nome" 
																name="destinatario" field="nome" label="Adicionar Destinatário" value={this.state.simulador.destinatario} />

											</div>
											<div className="ui-g-12">
												<TZMTable value={this.state.simulador.email?.destinatarios} children={this.columnsDestinarios} />
											</div>
										</TZMPanel>
									</div>
								</TZMTabPanel>
							</TZMTabView>
							<TZMPanelFooter>
								<TZMButton className="ui-button-success" label="Salvar" icon="fas fa-save" onClick={this.salvar}/>
							</TZMPanelFooter>
						</TZMPanel>
					</div>
					<div className="ui-g-4">
						<TZMSimulador key={TZMUserData.getIdGenerator()} simulador={this.state.simulador} esconderMensagem={true}/>
					</div>									
				</div>
				{this.state.dialogs}
				<TZMShortcut active={TZMShortcut.modalCount === 0} onCtrlS={this.salvar}/>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
