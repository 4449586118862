import moment from 'moment';
import { Column } from 'primereact/components/column/Column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import { toCurrency, toDecimal } from '../../../components/common/TZMFormatter';
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';

export class PainelGerencialDetalhe extends React.Component {

    constructor(props) {
        super(props);
        
        moment.locale('pt-BR');
        this.state = {
            visible: true,
            listDetalhes: props.listDetalhes,            
            header: props.header
        };
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    mesFormat(rowData, column) {
        if (rowData[column.field]){
            return moment(rowData[column.field]).format("DD/MM/YYYY");
        } else {
            return null;
        }
    }

    currencyFormat(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toCurrency(rowData[column.field])}</div>;
    }

    toDecimal(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(Math.round(rowData[column.field]))}</div>
    }

    toDecimal1(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(rowData[column.field])}</div>
    }

    render() {
        return (
            <TZMDialog style={{ width: "95%" }} visible={this.state.visible} modal header={this.state.header} {...this.props}>
                <div className="ui-g">
                    <DataTable emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el} value={this.state.listDetalhes} paginator={true} rows={20} pageLinkSize={10} footerColumnGroup={
                        <ColumnGroup>
                            <Row>
                            <Column colSpan={8} />                                
                                <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} footer="TOTAL" />                                                                                            
                                <Column style={{ fontWeight: "bold" }} footer={toDecimal(Math.round(this.state.listDetalhes.map((item) => item.m2).reduce((a, b) => a + b, 0)))} />
                                <Column colSpan={3} />
                            </Row>                                       
                        </ColumnGroup> }>
                        <Column style={{ width: "8em", textAlign: "center",   fontSize: "14px" }} sortable field="clienteId" key="clienteId" header="Cliente" filter={true} />
                        <Column style={{ width: "18em", textAlign: "left",   fontSize: "12px" }} sortable field="razaoSocial" key="razaoSocial" header="Razão Social" filter={true} />
                        <Column style={{ width: "12em", textAlign: "left",   fontSize: "12px" }} sortable field="nomeFantasia" key="nomeFantasia" header="Nome Fantasia" filter={true} />
                        <Column style={{ width: "7em", textAlign: "center",   fontSize: "12px" }} sortable field="numeroPedido" key="numeroPedido" header="Pedido" filter={true} />
                        <Column style={{ width: "3em", textAlign: "center",   fontSize: "12px" }} field="itemId" key="itemId" header="Item" />
                        <Column style={{ width: "7.5em", textAlign: "center", fontSize: "16px" }} sortable field="usage" key="usage" header="Operação" filter={true} />    
                        <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="status" key="status" header="Status" sort />                        
                        <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="dataEntrega" key="dataEntrega" header="Data Entrega" sort body={this.mesFormat} />
                        <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="dataEmissao" key="dataEmissao" header="Data Emissão" sort body={this.mesFormat} /> 
                        <Column style={{ width: "5em", textAlign: "center",   fontSize: "12px" }} field="m2" key="m2" body={this.toDecimal} header="M2" />                                                
                        <Column style={{ width: "10em", textAlign: "center",   fontSize: "12px" }} sortable field="produtoId" key="produtoId" header="Cod. Produto" filter={true} />
                        <Column style={{ width: "22em", textAlign: "center",   fontSize: "12px" }} sortable field="produtoDescricao" key="produtoDescricao" header="Produto" filter={true} /> 
                        <Column  style={{ width: "12em", textAlign: "center",   fontSize: "12px" }} field="familiaDescricao" key="familiaDescricao" header="Família" filter={true}   />
                        
                    </DataTable>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary"  label="Fechar" icon="fas fa-close" onClick={this.fechar} />                    
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar} />
            </TZMDialog>
        );
    }
}