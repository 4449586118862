import { Tooltip } from 'primereact/components/tooltip/Tooltip';
import React from "react";
import { toDecimal, toDecimalFixed } from "../../../components/common/TZMFormatter";
import TZMTextFieldPainel from "../../../components/common/TZMTextFieldPainel";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { TZMMenubarInfo } from "../../../components/menubar/TZMMenubarInfo";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMColumnPainel from "../../../components/table/TZMColumnPainel";
import TZMTable from "../../../components/table/TZMTable";
import { PainelRREService } from "../../servicos/PainelRREService";
import TZMUserData from '../../../utilities/TZMUserData';
import { Fetch } from '../../../utilities/Fetch';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';
import { Column } from 'primereact/components/column/Column';

const empresa = process.env.REACT_APP_EMPRESA;
const empresaNome = process.env.REACT_APP_EMPRESA_NOME; 
const title = "Painel RR";

const _0 =  "_0";
const _0_5 = "_0_5";
const _5_10 = "_5_10";
const _10_20 = "_10_20";
const _20_30 = "_20_30";
const _30_40 = "_30_40";
const _40_100 = "_40_100";
const total = "total";


export default class Dashboard_it extends React.Component {

	constructor() {
		super();
		this.state = {
			countdown: 0,
			pedidoOperador: {},
			pedidoVendedor: {},
			monitorGrafico: {},
			descontos:[],
			monitorTotais: {
				totalHojeMes: 0,
				lancadoHojeMes:0,
				lancadoHojeNacional: 0,
				lancadoHojeExportacao: 0,
				lancadoHojeFuturo : 0,
				lancadoHojeMesNacional: 0,
				lancadoHojeMesExportacao: 0,
				lancadoMesMes: 0,
				lancadoMesMesNacional: 0,
				lancadoMesMesExportacao: 0,
				lancadoMesFuturo: 0,
				lancadoMesFuturoNacional: 0,
				lancadoMesFuturoExportacao: 0,
				cartBloq:0,
				cartBloqNacional: 0,
				cartBloqExportacao: 0,
				faturadoMes: 0,
				faturadoMesNacional: 0,
				faturadoMesExportacao: 0,
				totalGeral: 0,
				totalGeralNacional: 0,
				totalGeralExportacao: 0,
				totalNacional: 0,
				totalExportacao: 0,
				officeFaturado: 0,
				officeCarteira:0,
				officeTotal:0,
				filmeFaturado: 0,
				filmeCarteira:0,
				filmeTotalrolo:0,
				filmeTotal:0,
				bloqueadoNacional:0,
				bloqueadoNacionalPrecoMedio:0,
				carteiraNacional:0,
				carteiraNacionalPrecoMedio:0,
				bloqueadoExportacao:0,
				bloqueadoExportacaoPrecoMedio:0,
				carteiraExportacao:0,
				carteiraExportacaoPrecoMedio:0,
				meta:0,
				metaLocal:0,				
				metaAtingida:0
			},
			vt_0:  0,
			vt_0_5: 0,
			vt_5_10: 0,
			vt_10_20: 0,
			vt_20_30: 0,
			vt_30_40: 0,
			vt_40_100: 0,
			vt_total: 0				

		};
		this.painelRREService = new PainelRREService();
	}

	monthFormatter = new Intl.DateTimeFormat("pt-BR", {month: "long"});

	monthName = (monthIndex) => {
		let now = new Date();
		now.setMonth(now.getMonth() + monthIndex);
		return this.monthFormatter.format(now);
	}

	reloadInterval = null;

	componentDidMount() {
		if (empresa === "RRE") {
			if (TZMUserData.isAutenticado()) {
				Fetch.Get("/ping");
				this.realoadInterval = window.setInterval(this.reloadPreview, 1000);
			}
		} else {
			window.location.replace("/painel");
		}
	}

	componentWillUnmount() {
		clearInterval(this.reloadPreview);
	}

	reloadPreview = async () => {
		if (this.state.countdown > 1) {
			this.setState({countdown: this.state.countdown - 1});
		} else {
			this.setState({countdown: 60});
			await this.painelRREService.pedidoVendedor().then((response) => {
				this.setState({pedidoVendedor: response});
			});
			await this.painelRREService.pedidoOperador().then((response) => {
				this.setState({pedidoOperador: response});
			});			
			await this.painelRREService.monitorTotais().then((response) => {
				this.setState({monitorTotais: response});
			});
			await this.painelRREService.descontos().then((results) => {
				let descontos = [];
				results.forEach(result => {
					if (descontos.some(d => d.nome === result.nome)) {
						descontos.filter(d => d.nome === result.nome).forEach(d => {
							if (!d.valores[result.faixa]) {
								d.valores[result.faixa] = 0;
							}
							d.valores[result.faixa] += result.quantidade;
							d.valores.total += result.quantidade;
						});
					} else {
						descontos.push({nome: result.nome, valores: {[result.faixa]: result.quantidade, total: result.quantidade}});
					}
				});
				descontos = descontos.sort((a, b) => a.nome.localeCompare(b.nome));
				let vt_0 =  descontos.map((item) => item.valores[_0] || 0).reduce((a, b) => a + b, 0);
				let vt_0_5 = descontos.map((item) => item.valores[_0_5] || 0).reduce((a, b) => a + b, 0);
				let vt_5_10 = descontos.map((item) => item.valores[_5_10] || 0).reduce((a, b) => a + b, 0);
				let vt_10_20 = descontos.map((item) => item.valores[_10_20] || 0).reduce((a, b) => a + b, 0);
				let vt_20_30 = descontos.map((item) => item.valores[_20_30] || 0).reduce((a, b) => a + b, 0);
				let vt_30_40 = descontos.map((item) => item.valores[_30_40] || 0).reduce((a, b) => a + b, 0);
				let vt_40_100 = descontos.map((item) => item.valores[_40_100] || 0).reduce((a, b) => a + b, 0);
				let vt_total = descontos.map((item) => item.valores[total] || 0).reduce((a, b) => a + b, 0);
				this.setState({descontos,vt_0,vt_0_5,vt_5_10,vt_10_20,vt_20_30,vt_30_40,vt_40_100,vt_total});
				this.setState({descontos});
			});
		}
	}

	pedidosVendedor = [
		<TZMColumnPainel body={(rowData, column) => <div title={`Código do Vendedor: ` + rowData["vendedorId"]} style={{ fontSize: "25px", fontWeight: "bold" }}>{rowData["nome"]}</div>} field="nome" key="nome" header={<span style={{ fontSize: "20px" }}>Pedidos por Vendedor</span>} style={{ width: "*" }} />,
		<TZMColumnPainel body={(rowData, column) => <div style={{textAlign: "right", fontSize: "25px",fontWeight: "bold"}}>{rowData["quantidade"].toDecimal()}</div>} field="quantidade" key="volume" header={<span style={{fontSize: "20px"}}>Volume m2</span>} style={{width: "9em"}} />,
		<TZMColumnPainel body={(rowData, column) => <div style={{textAlign: "right", fontSize: "25px",fontWeight: "bold"}}>{rowData["precoMedio"].toCurrency()}</div>} field="precoMedio" key="precoMedio" header= { <div id="preco" children="Preço Médio" style={{fontSize: "20px"}} /> } style={{width: "*"}} />			
	];

	pedidosOperador = [
		<TZMColumnPainel body={(rowData, column) => <div style={{fontSize: "25px",fontWeight: "bold"}}>{rowData["usuario"]}</div>} field="usuario" key="usuario" style={{width: "*"}} header={<span style={{fontSize: "20px"}}>Pedidos por Operador</span>} />,
		<TZMColumnPainel body={(rowData, column) => <div style={{textAlign: "right", fontSize: "25px", fontWeight: "bold"}}>{rowData["quantidade"].toDecimal()}</div>} field="quantidade" key="volume" style={{width: "9em"}} header={<span style={{fontSize: "20px"}}>Volume m2</span>} />,
		<TZMColumnPainel body={(rowData, column) => <div style={{textAlign: "right", fontSize: "25px",fontWeight: "bold"}}>{rowData["precoMedio"].toCurrency()}</div>} field="precoMedio" key="precoMedio" header= { <div id="preco" children="Preço Médio" style={{fontSize: "20px"}} /> } style={{width: "*"}} />	
	];

	descontos = [
		<Column key={0} header="m²" body={(d) => <div style={{fontSize: "18px"}}>{d.nome}</div>} />,
		<Column style={{width: "10em"}} key={1} header="Sem Desconto" body={(d) => this.descontosBody(d, _0)} />,
		<Column style={{width: "9em"}} key={2} header="0~5%" body={(d) => this.descontosBody(d, _0_5)} />,
		<Column style={{width: "9em"}} key={3} header="5~10%" body={(d) => this.descontosBody(d, _5_10)} />,
		<Column style={{width: "9em"}} key={4} header="10~20%" body={(d) => this.descontosBody(d, _10_20)} />,
		<Column style={{width: "9em"}} key={5} header="20~30%" body={(d) => this.descontosBody(d, _20_30)} />,
		<Column style={{width: "9em"}} key={6} header="30~40%" body={(d) => this.descontosBody(d, _30_40)} />,
		<Column style={{width: "9em"}} key={7} header="40%+" body={(d) => this.descontosBody(d, _40_100)} />,
		<Column style={{width: "11em"}} key={8} header="Total" body={(d) => this.descontosBody(d, total)} />
	];

	descontos2 = [
		<Column key={0} header="%" body={(d) => <div style={{fontSize: "18px"}}>{d.nome}</div>} />,
		<Column style={{width: "10em"}} key={1} header="Sem Desconto" body={(d) => this.descontosBody2(d, _0)} />,
		<Column style={{width: "9em"}} key={2} header="0~5%" body={(d) => this.descontosBody2(d, _0_5)} />,
		<Column style={{width: "9em"}} key={3} header="5~10%" body={(d) => this.descontosBody2(d, _5_10)} />,
		<Column style={{width: "9em"}} key={4} header="10~20%" body={(d) => this.descontosBody2(d, _10_20)} />,
		<Column style={{width: "9em"}} key={5} header="20~30%" body={(d) => this.descontosBody2(d, _20_30)} />,	
		<Column style={{width: "9em"}} key={6} header="30~40%" body={(d) => this.descontosBody2(d, _30_40)} />,
		<Column style={{width: "9em"}} key={7} header="40%+" body={(d) => this.descontosBody2(d, _40_100)} />,
		<Column style={{width: "11em"}} key={8} />
	];	

	descontosBody = (d, field) => <div style={{fontSize: "18px", textAlign: "right"}}>{this.formatNumber(Math.round(d.valores[field] || 0))}</div>;	
	descontosBody2 = (d, field) => <div style={{fontSize: "18px", textAlign: "right"}}>{this.arredondar(((d.valores[field] || 0) / d.valores.total * 100),1)}%</div>;
	totalPercentual = (valor) => <div style={{fontSize: "18px", textAlign: "right"}}>{this.arredondar(((valor) / this.state.vt_total * 100 ),1) || 0}%</div>;

	arredondar = (number, decimalPlaces) => {
		const factorOfTen = Math.pow(10, decimalPlaces)
		return Math.round(number * factorOfTen) / factorOfTen
	  }
	
	formatNumber = (number) =>{		
		return Intl.NumberFormat("pt-BR").format(number);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-8 ui-g-nopad">
						<div className="ui-g-12">
							<TZMTable header={<div style={{fontSize: "18px"}}>Descontos por Gerente</div>} value={this.state.descontos} children={this.descontos} 
									footerColumnGroup={
										<ColumnGroup>
											<Row>
											<Column key={0} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer="Total" />
												<Column key={1} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.formatNumber(Math.round(this.state.vt_0))} />
												<Column key={2} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.formatNumber(Math.round(this.state.vt_0_5))} />
												<Column key={3} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.formatNumber(Math.round(this.state.vt_5_10))} />
												<Column key={4} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.formatNumber(Math.round(this.state.vt_10_20))} />
												<Column key={5} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.formatNumber(Math.round(this.state.vt_20_30))} />
												<Column key={6} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.formatNumber(Math.round(this.state.vt_30_40))} />
												<Column key={7} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.formatNumber(Math.round(this.state.vt_40_100))} />
												<Column key={8} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.formatNumber(Math.round(this.state.vt_total))} />												
											</Row>
										</ColumnGroup>
									}/>
							<TZMTable value={this.state.descontos} children={this.descontos2} footerColumnGroup={
										<ColumnGroup>
											<Row>
											 	<Column key={0} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer="" />
												<Column key={1} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.totalPercentual(this.state.vt_0)} />
												<Column key={2} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.totalPercentual(this.state.vt_0_5)} />
												<Column key={3} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.totalPercentual(this.state.vt_5_10)} />
												<Column key={4} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.totalPercentual(this.state.vt_10_20)} />
												<Column key={5} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.totalPercentual(this.state.vt_20_30)} />
												<Column key={6} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.totalPercentual(this.state.vt_30_40)} />
												<Column key={7} style={{ fontSize: "18px", fontWeight: "bold", textAlign: "right" }} footer={this.totalPercentual(this.state.vt_40_100)} />
												<Column key={8} />
											</Row>
										</ColumnGroup>
									}/>
						</div>
						<div className="ui-g-6">
							<Tooltip for="#preco" title="Preço Médio = (quantidade * valor unitário) - (ICMS+PIS+COFINS) / valor m2 " tooltipPosition="right" />
							<TZMTable rows={15} paginator value={this.state.pedidoOperador}>
								{this.pedidosOperador}
							</TZMTable>
						</div>
						<div className="ui-g-6">
							<TZMTable rows={15} paginator value={this.state.pedidoVendedor}>
								{this.pedidosVendedor}
							</TZMTable>
						</div>						
						<div className="ui-g-12">
							<TZMPanel>
								<div className="ui-g" style={{textAlign: "center"}}>
									<div className="ui-g-12">
										<div className="ui-g" style={{margin:0, padding:0, textAlign: "center"}}>
											<div className="ui-g-4">
												{/* <div className="ui-g-7">
													<TZMTextFieldPainel style={{textAlign: "center", height: "35px", fontSize: "32px", padding:"0"}} label="Bloqueado Local" value={this.state.monitorTotais.bloqueadoNacional != null ? this.state.monitorTotais.bloqueadoNacional.toInteger() : 0} />
												</div>
												<div className="ui-g-5">
													<TZMTextFieldPainel style={{textAlign: "center", height: "35px", fontSize: "32px", padding:"0"}} label="Preço Médio" value={this.state.monitorTotais.bloqueadoNacionalPrecoMedio.toDecimal()} />
												</div> */}
												<div className="ui-g-7">
													<TZMTextFieldPainel style={{textAlign: "center", height: "35px", fontSize: "32px", padding:"0"}} label="Carteira Local" value={this.state.monitorTotais.carteiraNacional != null ? this.state.monitorTotais.carteiraNacional.toInteger() : 0} />
												</div>
												<div className="ui-g-5">
													<TZMTextFieldPainel style={{textAlign: "center", height: "35px", fontSize: "32px", padding:"0"}} label="Preço Médio" value={this.state.monitorTotais.carteiraNacionalPrecoMedio.toDecimal()} />
												</div>
											</div>
											<div className="ui-g-4">
												{/* <div className="ui-g-7">
													<TZMTextFieldPainel style={{textAlign: "center", height: "35px", fontSize: "32px", padding:"0"}} label="Bloqueado Expo" value={this.state.monitorTotais.bloqueadoExportacao != null ? this.state.monitorTotais.bloqueadoExportacao.toInteger() : 0} />
												</div>
												<div className="ui-g-5">
													<TZMTextFieldPainel style={{textAlign: "center", height: "35px", fontSize: "32px", padding:"0"}} label="Preço Médio" value={this.state.monitorTotais.bloqueadoExportacaoPrecoMedio.toDecimal()} />
												</div> */}
												<div className="ui-g-7">
													<TZMTextFieldPainel style={{textAlign: "center", height: "35px", fontSize: "32px", padding:"0"}} label="Carteria Expo" value={this.state.monitorTotais.carteiraExportacao != null ? this.state.monitorTotais.carteiraExportacao.toInteger() : 0} />
												</div>
												<div className="ui-g-5">
													<TZMTextFieldPainel style={{textAlign: "center", height: "35px", fontSize: "32px", padding:"0"}} label="Preço Médio" value={this.state.monitorTotais.carteiraExportacaoPrecoMedio.toDecimal()} />
												</div>
											</div>
											
											<div className="ui-g-4">
												{/* <div className="ui-g-12">
													<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", padding:"0", fontWeight: "bold", textAlign: "center"}} label="Total Bloqueado" value={toDecimal(Math.round(this.state.monitorTotais.bloqueadoNacional +  this.state.monitorTotais.bloqueadoExportacao))}/>
												</div> */}
												<div className="ui-g-12">
													<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", padding:"0", fontWeight: "bold", textAlign: "center"}} label="Total Carteira" value={toDecimal(Math.round(this.state.monitorTotais.carteiraNacional +  this.state.monitorTotais.carteiraExportacao))}/>
												</div>	
											</div>
										</div>
									</div>
								</div>					
							</TZMPanel>		
						</div>
					</div>
					<div className="ui-g-4">
						<TZMPanel>
							<div className="ui-g" style={{textAlign: "center"}}>
								<div className="ui-g-12">
									<TZMTextFieldPainel style={{fontSize: "40px", height: "45px", color: "#F44336", fontWeight: "bold", textAlign: "center"}} label="Lançado hoje para o Mês" value={this.state.monitorTotais.lancadoHojeMes != null ? this.state.monitorTotais.lancadoHojeMes.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Local" value={this.state.monitorTotais.lancadoHojeMesNacional != null ? this.state.monitorTotais.lancadoHojeMesNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Expo" value={this.state.monitorTotais.lancadoHojeMesExportacao != null ? this.state.monitorTotais.lancadoHojeMesExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center", padding:"0"}} label="Lançado Hoje Mês"  value={this.state.monitorTotais.lancadoHojeMes != null ? this.state.monitorTotais.lancadoHojeMes.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center", padding:"0"}} label="Lançado Hoje Fut"  value={this.state.monitorTotais.lancadoHojeFuturo != null ? this.state.monitorTotais.lancadoHojeFuturo.toInteger() : 0} />
								</div>
								<div className="ui-g-12">
									<TZMTextFieldPainel style={{fontSize: "40px", height: "45px", fontWeight: "bold", textAlign: "center"}} label="Lançado mês para o mês"  value={this.state.monitorTotais.lancadoMesMes != null ? this.state.monitorTotais.lancadoMesMes.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Local"  value={this.state.monitorTotais.lancadoMesMesNacional != null ? this.state.monitorTotais.lancadoMesMesNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Expo" value={this.state.monitorTotais.lancadoMesMesExportacao != null ? this.state.monitorTotais.lancadoMesMesExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-12">
									<TZMTextFieldPainel style={{textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold"}}  label="Carteira Futura"  value={this.state.monitorTotais.lancadoMesFuturo != null ? this.state.monitorTotais.lancadoMesFuturo.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Local"  value={this.state.monitorTotais.lancadoMesFuturoNacional != null ? this.state.monitorTotais.lancadoMesFuturoNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Expo" value={this.state.monitorTotais.lancadoMesFuturoExportacao != null ? this.state.monitorTotais.lancadoMesFuturoExportacao.toInteger() : 0 }/>
								</div>
								<div className="ui-g-12">
									{/* <TZMTextFieldPainel style={{textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold"}} label="CART + BLOQ" value={this.state.monitorTotais.cartBloq != null ? this.state.monitorTotais.cartBloq.toInteger() : 0} /> */}
									<TZMTextFieldPainel style={{textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold"}} label="Carteira" value={this.state.monitorTotais.cartBloq != null ? this.state.monitorTotais.cartBloq.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Local"  value={this.state.monitorTotais.cartBloqNacional != null ? this.state.monitorTotais.cartBloqNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Expo" value={this.state.monitorTotais.cartBloqExportacao != null ? this.state.monitorTotais.cartBloqExportacao.toInteger() : 0} />
								</div>
							</div>
						</TZMPanel>
						<div style={{height:"7px"}} />
						<TZMPanel>
							<div className="ui-g" style={{padding:0, textAlign: "center"}}>
								<div className="ui-g-12">
									<TZMTextFieldPainel style={{textAlign: "center", fontSize: "40px", height: "45px", color: "#4286f4", fontWeight: "bold"}} title="(Faturado - Devolução) para o mês"  label="Faturado no mês"  value={this.state.monitorTotais.faturadoMes != null ? this.state.monitorTotais.faturadoMes.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Local"  title="(Faturado - Devolução) para o mês" value={this.state.monitorTotais.faturadoMesNacional != null ? this.state.monitorTotais.faturadoMesNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Expo" title="(Faturado - Devolução) para o mês"  value={this.state.monitorTotais.faturadoMesExportacao != null ? this.state.monitorTotais.faturadoMesExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-12">
									{/* <TZMTextFieldPainel style={{textAlign: "center", fontSize: "40px", height: "45px", color: "#4286f4", fontWeight: "bold"}} label="Total Geral m2 (Cart + Bloq + Fat)"  value={this.state.monitorTotais.totalGeral != null ? this.state.monitorTotais.totalGeral.toInteger() : 0} /> */}
									<TZMTextFieldPainel style={{textAlign: "center", fontSize: "40px", height: "45px", color: "#4286f4", fontWeight: "bold"}} label="Total Geral m2 (Carteira + Faturado)"  value={this.state.monitorTotais.totalGeral != null ? this.state.monitorTotais.totalGeral.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Local"  value={this.state.monitorTotais.totalGeralNacional != null ? this.state.monitorTotais.totalGeralNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Expo" value={this.state.monitorTotais.totalGeralExportacao != null ? this.state.monitorTotais.totalGeralExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-12">
									<TZMTextFieldPainel style={{textAlign: "center", fontSize: "40px", height: "45px", fontWeight: "bold"}} label="Meta"  value={this.state.monitorTotais.meta != null ? this.state.monitorTotais.meta.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel style={{fontSize: "32px", height: "35px", textAlign: "center"}} label="Meta Local/Expo"  value={this.state.monitorTotais.metaLocal != null ? this.state.monitorTotais.metaLocal.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
								    <TZMTextFieldPainel style={{textAlign: "center", fontSize: "32px", height: "35px"}} label="% Local/Exp" value={toDecimalFixed(((Number(this.state.monitorTotais.totalGeralNacional) + Number(this.state.monitorTotais.totalGeralExportacao)) / Number(this.state.monitorTotais.metaLocal === 0 ? 1 : this.state.monitorTotais.metaLocal) * 100), 1)} />
								</div>						
								<div className="ui-g-4">
									<TZMTextFieldPainel style={{textAlign: "center", fontSize: "32px", height: "35px"}} label=" Meta Geral" value={this.state.monitorTotais.metaAtingida != null ? toDecimalFixed(this.state.monitorTotais.metaAtingida, 1) : 0} />
								</div>
							</div>
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>				
				<TZMMenubarInfo countdown={this.state.countdown} />
				<ModalRealm />
			</TZMMainContent>
		);
	}
}
