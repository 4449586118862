import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";
import { Util } from "../../utilities/Util";

const basePath = process.env.REACT_APP_API_HOST;
export class InadimplenciaService {

    inadimplentes(params) {
        return httpGet("/inadimplencias/inadimplentes", params);
    }

    complemento = async (sapId) => {
        return httpGetAsync(`/inadimplencias/complemento/${sapId}`);        
    }

    grafico(params){
        return  httpGet("/inadimplencias/inadimplentes", params, false);
    }

    atualizar  = async () => {
        return httpGetAsync("/inadimplencias/atualizar");
    }

    relatorioInadimplencia(params) {
        let append = "";
        if (params != null) {
            let values = [];
            Object.keys(params).forEach((key) => {
                if (params[key] != null) {
                    if (Array.isArray(params[key])) {
                        let temp = params[key].filter(element => element);
                        if (temp.length > 0) {
                            values.push(`${key}=${encodeURIComponent(params[key].join(","))}`);
                        }
                    } else if (typeof params[key] === "object") { 
                        values.push(`${key}=${encodeURIComponent(JSON.stringify(params[key]))}`);
                    } else {
                        if (params[key].length > 0) {
                            values.push(`${key}=${encodeURIComponent(Util.trim(params[key]))}`);
                        }
                    }
                }
            });
            if (values.length > 0) {
                append = "?" + values.join("&");
            }
        }        
        return window.open(basePath + "/inadimplencias/impressao" + append, "_blank");
    }

    static descricaoGrupo = (grupoDiasVencido) => {
		switch (grupoDiasVencido) {
			case 30:
				return "Até 30 ddl";
			case 60:
				return "31 a 60";
			case 90:
				return "61 a 90";
			case 180:
				return "91 a 180";
			case 365:
				return "181 a 365";
			case 366:
				return "+ 365 ddl";
			default:
				return " ";
		}
    }
}
