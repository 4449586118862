import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { ClienteService } from "../../servicos/ClienteService";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import { asEmail } from "../../../utilities/TZMUtil";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import { Tooltip } from "primereact/components/tooltip/Tooltip";
import TZMForm from "../../../components/common/TZMForm";
import TZMUserData from "../../../utilities/TZMUserData";
import { ListaPrecoService } from "../../servicos/ListaPrecoService";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import { toPercent } from "../../../components/common/TZMFormatter";
import {VwClienteService} from "../../servicos/VwClienteService";
import { ClienteUtils } from "./ClienteUtils";

export class EditarCliente extends React.Component {

	constructor(props) {
		super();		
		this.state = {
			visible: true,			
			cliente: props.cliente,
			tabelasPreco: [],
			matrizes:[]
		};		
		this.clienteService = new ClienteService();
		this.vwClienteService = new VwClienteService();
		this.listaPrecoService = new ListaPrecoService();		
	}

	onHide = () => this.setState({visible: false});

	handleClear = (event) => {
		let cliente = this.state.cliente;
		cliente[event.name] = null;
		this.setState({cliente});
	}

	handleSelect = (event) => {
		let cliente = this.state.cliente;
		cliente[event.name] = event.value;
		this.setState({cliente});
	}
	
	listarTabelasPreco = async (event) => {
		await this.listaPrecoService.listarSimples({descricao: event.query,sort:'descricao'}).then((tabelasPreco) => this.setState({tabelasPreco}));
	}

	listarMatrizes = async (event) => {
        await this.vwClienteService.autoComplete({cliente: event.query,posicao: 'ATIVO', sort: 'razaoSocial'}).then((matrizes) => this.setState({matrizes}));
    }

	salvarCliente = () => {
		this.clienteService.salvar(this.state.cliente).then((cliente) => {
			if (this.props.onModalClose) this.props.onModalClose(cliente);
			this.onHide();
		});
	}

    render() {
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Cliente" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
								<div className="ui-g">
									<div className="ui-g-2 ui-g-nopad">
										<div className="ui-g-9 ">
											<TZMTextField disabled forac="true" value={this.state.cliente.vwCliente.posicao} label="Situação" />
										</div>
										<div className="ui-g-3 cliente-tipo">
											{ClienteUtils.clienteTipoPedido(this.state.cliente.vwCliente.tipo)}
										</div>										
									</div>								
									<div className="ui-g-2">
										<TZMTextField label="Cliente" disabled name="clienteId" value={this.state.cliente.vwCliente.pk.clienteId}  />
									</div>								
									<div className="ui-g-6">
										<TZMTextField label="Razão Social" disabled name="razaoSocial" value={this.state.cliente.vwCliente.razaoSocial}  />
									</div>
									<div className="ui-g-5">
										<TZMTextField label="Nome Fantasia" disabled name="nomeFantasia" value={this.state.cliente.vwCliente.nomeFantasia}  />
									</div>
									<div className="ui-g-2">
										<TZMTextField label="CNPJ" disabled name="cpfCnpjFormatado" value={this.state.cliente.vwCliente.cpfCnpjFormatado}  />
									</div>
									<div className="ui-g-3">
										<TZMTextField label="Inscrição Estadual" disabled name="inscricaoEstadual" value={this.state.cliente.vwCliente.inscricaoEstadual}  />
									</div>
									<div className="ui-g-2">
									</div>
									<div className="ui-g-4">
										<TZMTextField label="Logradouro" disabled name="logradouro" value={this.state.cliente.vwCliente.logradouro}  />									
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Complemento" disabled name="complemento" value={this.state.cliente.vwCliente.complemento}  />									
									</div>
									<div className="ui-g-2">
										<TZMTextField label="CEP" disabled name="cep" value={this.state.cliente.vwCliente.cep}  />									
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Bairro" disabled name="bairro" value={this.state.cliente.vwCliente.bairro}  />									
									</div>
									<div className="ui-g-4">
										<TZMTextField label="Cidade" disabled name="cidade" value={this.state.cliente.vwCliente.cidade}  />									
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Estado" disabled name="estado" value={this.state.cliente.vwCliente.estado}  />									
									</div>
									<div className="ui-g-2" >
										<TZMTextField style={{textAlign: 'left'}} label="ICMS" disabled name="percentualICMS" value={toPercent(this.state.cliente.vwCliente.percentualIcms)}/>
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Forma Pagamento" disabled name="formaPagamento" value={this.state.cliente.vwCliente?.formaPagamento?.descricao}  />									
									</div>	
									<div className="ui-g-2"/>
									<div className="ui-g-2">
										<TZMTextField label="Contato" disabled name="contato" value={this.state.cliente.vwCliente.contato}  />									
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Telefone 1" disabled name="telefone1" value={this.state.cliente.vwCliente.telefone1}  />									
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Telefone 2" disabled name="telefone2" value={this.state.cliente.vwCliente.telefone2}  />									
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Telefone 3" disabled name="telefone3" value={this.state.cliente.vwCliente.telefone3}  />									
									</div>
				
									<div className="ui-g-10">
										<TZMTextField label="Tipo" disabled name="telefone5" value={this.state.cliente.vwCliente.tipo}  />									
									</div>
									<div className="ui-g-6">
										<TZMAutoComplete emptyMessage="Nenhum registro encontrado" placeholder="Razão social, fantasia ou CNPJ" itemTemplate={ClienteUtils.clienteTemplateOmega} 
												onClear={this.clearAutoCompleteCliente} onSelect={this.handleSelect} onChange={this.handleSelect} suggestions={this.state.matrizes} 
												completeMethod={this.listarMatrizes} label="Matriz" name="matriz" field="descricaoAmigavel" value={this.state.cliente.matriz} />

									</div>
									<div className="ui-g-6">
										<TZMAutoComplete  onClear={this.handleClear}  minLength={1} onSelect={this.handleSelect} onChange={this.handleSelect}
										suggestions={this.state.tabelasPreco}  completeMethod={this.listarTabelasPreco} name="listaPreco" field="descricao" 
										label="Lista de Preço"  placeholder="Descrição" value={this.state.cliente.listaPreco} />
									</div>
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Contatos</span>}>							
								<TZMTable value={this.state.cliente.vwCliente.contatos} emptyMessage="Nenhum contato encontrado">
									<TZMColumn key="nome" style={{ textAlign: "left", width: "15em" }} field="nome" header="Contato" />
									<TZMColumn key="telefone1" style={{ textAlign: "center", width: "10em" }} field="telefone1" header="Telefone 1" />
									<TZMColumn key="telefone2" style={{ textAlign: "center", width: "10em" }} field="telefone2" header="Telefone 2" />
									<TZMColumn key="email" style={{ width: "*" }} field="email" header="E-mail" body={(rowData) => rowData != null ? asEmail(rowData.email) : null} />
									<TZMColumn key="observacao" style={{ width: "6em" }} field="observacao" header="Observação" body={(rowData) => {
										let id = rowData.nome + "_" + TZMUserData.getIdGenerator() + "_id";
										return (
											<div id={id} style={{textAlign: "center" }}>
												<span className="fa fa-info" />
												<Tooltip for={`#${id}`} title={rowData.observacao} tooltipPosition="top" />
											</div>
										);
									}} />
								</TZMTable>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Endereços</span>}>							
								<TZMTable value={this.state.cliente.vwCliente.enderecos} emptyMessage="Nenhum endereço encontrado">
									<TZMColumn key="logradouro" style={{ textAlign: "left", width: "15em" }} field="logradouro" header="Logradouro" />
									<TZMColumn key="cep" style={{ textAlign: "center", width: "10em" }} field="cep" header="CEP" />
									<TZMColumn key="bairro" style={{ textAlign: "center", width: "10em" }} field="bairro" header="Bairro" />
									<TZMColumn key="cidade" style={{ textAlign: "center", width: "10em" }} field="cidade" header="Cidade" />
									<TZMColumn key="estado" style={{ textAlign: "center", width: "10em" }} field="estado" header="Estado" />
								</TZMTable>
							</TZMTabPanel>
						</TZMTabView>
					</TZMForm>	
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarCliente} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarCliente} onEscape={this.onHide}/>
			</TZMDialog>
		);
	}

}
